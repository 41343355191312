import React, { useState, useEffect, useRef } from 'react'
import "./Style.css"

/*--------------------------- MUI components ------------------------*/
import {
  Paper, Box, Button, Modal, Divider, Autocomplete, TextField, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow,
  Card,
  Grid,
  Tooltip,
  Typography,
  CardHeader,
  Stack,
  CircularProgress
} from '@mui/material'
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

/*----------------------------------- MUI Icons ----------------------------*/
import CloseIcon from '@mui/icons-material/Close';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

/*-------------------------------External Componnets -------------------*/
import ExcelUplaod from '../Excel/ExcelUpload'

/*------------------------------- APi calls -------------------------*/
import { fetchSiteDetails, fetchStandardParams, fetchEquipmentIdDetails, PostdataConfig, GetFileParamCoff, dataloggerNamevalue } from '../../Api/ExcelUploadAPi'
import CustomSnackbar from '../util/components/CustomSnackbar';
import CustomBreadcrumbs from '../util/components/CustomBread';
import { tr } from 'date-fns/locale';
import { FadeLoader } from 'react-spinners';
import { dataloggerfilePath } from '../../Api/DataLoggerAPi';
import * as XLSX from "xlsx";
import { downloadExcelTemplate } from '../../Template/ExcelTemplates/ExcelTemplate';

const ExcelUploadUi = () => {

  const [excelParams, setExcelParams] = useState([])
  const [loggerName, setLoggerName] = React.useState("")
  const [fileName, setfileName] = React.useState("")
  const [site, setSite] = React.useState("")
  const [tableData, setTableData] = useState([]);
  const [siteIdName, setSiteId] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [stdParamId, setStandardParamsId] = React.useState("")
  const [standardParams, setStandardParams] = useState([]);
  const [rows, setRows] = useState([]);
  const [coefficientValue, setCoefficientValue] = useState([]);
  const [stdValue, setStdValue] = useState('')
  const [activeInputIndex, setActiveInputIndex] = useState(null);
  const [stdParams, setStdParams] = useState(null);
  const [loggerMaster, setLoggerMaster] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [fileNames, setFileNames] = useState([]);
  // const [filePath, setFilePath] = useState([]);
  const [Fileget, setFileget] = useState();
  const [excelOpen, setExcelOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [modalvalue, setModalvalue] = useState(true);
  const initialSnack = { open: false, severity: "", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const [errorVal, setErrorVal] = useState(null);
  const [indexVal, setIndexVal] = useState(null);
  const initialError = { open: false, severity: null, message: 'Not Matching parameter consider as Dummy', index: [] };
  const [errorIndex, setErrorIndex] = useState(initialError);
  const [selectedFileData, setSelectedFileData] = useState(null);
  const [toBackend, setToBackend] = useState(false);
  const inputRefs = useRef([]);


  // const StyledTableCell = styled(TableCell)(({ theme }) => ({
  //   [`&.${tableCellClasses.head}`]: {
  //     backgroundColor: 'cornflowerblue',
  //     color: theme.palette.common.white,
  //   },
  //   [`&.${tableCellClasses.body}`]: {
  //     fontSize: 14,
  //   },
  // }));

  // const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  //   // hide last border
  //   '&:last-child td, &:last-child th': {
  //     border: 0,
  //   },
  // }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: 'cornflowerblue',
      color: theme.palette.common.white,
      width: '-10px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      // width: '-10px',
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      // backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  function handleExcelFields(index, value, key) {
    if (key === "Standard Parameter") {
      const trimmedVal = value.split(',')[0].trim();
      const idMatchVal = stdParams.forEach(data => {
        if (data.stdParameterName === trimmedVal) {
          setErrorIndex((prev) => {
            const removedIndex = errorIndex.index?.filter(data => data !== index);
            return { ...prev, index: removedIndex }
          })
        }
      });
      // if ([trimmedVal].includes(stdParams.stdParameterName)) {
      console.log(idMatchVal, 'trimmedVal');
      // }
    }
    console.log('dropdown works');

    setSelectedFileData((prev) => {
      const updatedData = [...prev];
      updatedData[index] = {
        ...updatedData[index],
        [key]: value
      }
      return updatedData
    })
  }

  function handleCoeffChange(index, value) {
    setSelectedFileData({
      ...selectedFileData,
      "Standard Parameter": value
    })
  }
  const MemoizedTableRow = React.memo(({ row, index }) => (
    row['Parameter Name'] && (
      <StyledTableRow className="divider" key={index}>
        <StyledTableCell align="center" className="cell-with-border">
          {index + +1}
        </StyledTableCell>
        <StyledTableCell align="center" className="cell-with-border" sx={{ width: 300 }}>
          {row["Parameter Name"]}
        </StyledTableCell>
        <StyledTableCell align="center" className="cell-with-border" sx={{ width: 350 }}>
          <Tooltip title={row["Standard Parameter"]}>
            <Autocomplete
              disablePortal
              id={`combo-box-demo-${index}`}
              value={row["Standard Parameter"]}
              options={stdParams.map(
                (data) =>
                  `${data.stdParameterName}${data.stdUom ? `, (${data.stdUom})` : ""
                  }`
              )}
              defaultValue={row["Standard Parameter"]}
              onChange={(event, value) => handleExcelFields(index, value, "Standard Parameter")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Standard Params"
                  sx={{ align: "center" }}
                  error={errorIndex.index.includes(index)}
                  helperText={errorIndex.index.includes(index) && errorIndex.message}
                />
              )}
            />
          </Tooltip>
        </StyledTableCell>
        <StyledTableCell align="center" className="cell-with-border">
          <TextField
            id={`coefficient-input-${index}`}
            placeholder="Co-Efficient"
            variant="standard"
            value={coefficientValue[index]} // Assuming this is managed elsewhere in your state
            autoFocus={activeInputIndex === index}
            inputRef={(ref) => {
              inputRefs.current[index] = ref;
            }}
            defaultValue={row["Coefficient"]}
            onChange={(e) => handleExcelFields(index, e.target.value, "Coefficient")}
          />
        </StyledTableCell>
      </StyledTableRow>
    )
  )
  );

  useEffect(() => {
    // Initialize the inputRefs array with null values
    inputRefs.current = excelParams.map(() => null);
  }, [excelParams]);

  useEffect(() => {
    getSiteCall();
    getStdParams();
  }, [])

  const handleExcelparams = (data) => {
    setExcelParams(data)
  }

  const handleDownload = async () => {
    downloadExcelTemplate('Datalogger', true, stdParams);
  }
  /*-------------------------- Site Id Call --------------------------*/
  const getSiteCall = async () => {
    try {
      let data = await fetchSiteDetails();
      setSiteId(data)
    } catch (e) {
      console.log(e);
    }
  }

  const getStdParams = async () => {
    try {
      let data = await GetFileParamCoff();
      setStdParams(data);
    } catch (error) {
      console.error(error);
    }
  }

  const getLoggerMaster = async (id) => {
    try {
      let data = await dataloggerNamevalue(id);
      setLoggerMaster(data);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  const getFilePath = async (id) => {
    try {
      let data = await dataloggerfilePath(id);
      // Process each file path to get the last part
      const fileNames = data.map((path) => {
        const parts = path.split("/");
        return parts[parts.length - 1];
      });

      // setFilePath(data);
      // setFileNames(fileNames);
    } catch (e) {
      console.log(e);
    }
  };
  /*--------------------------------Equipment Id call ----------------------------*/
  const fetchEquipmentDetails = async (event) => {
    let siteIdFilter = siteIdName?.filter((data) => { return (data.siteName === event) }) // want to change in global 
    console.log(siteIdFilter);
    try {
      let data = await fetchEquipmentIdDetails(siteIdFilter[0]?.siteId);
      setEquipmentData(data)
    } catch (e) {
      console.log(e);
    }
  }

  /*------------------------- standard params --------------------------*/
  const getstandardParams = async () => {
    try {
      let data = await fetchStandardParams();
      setStandardParams(data)
    } catch (e) {
      console.log(e);
    }
  }
  const requiredHeader = ["Parameter Name", "Standard Parameter", "Coefficient"];
  const fileInputRef = useRef(null);
  /*---------------------- excel upload --------------------------*/
  const handleSelectExcel = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const headers = [];
        const range = XLSX.utils.decode_range(sheet["!ref"]);
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellAddress = XLSX.utils.encode_cell({ r: range.s.r, c: C });
          const cell = sheet[cellAddress];
          if (cell) {
            headers.push(cell.v);
          }
        }
        const headersPresented = requiredHeader
          .map((header) => header.toLowerCase().replace(/ /g, ""))
          .every((header) =>
            headers
              .map((head) => head.toLowerCase().replace(/ /g, ""))
              .includes(header)
          );
        console.log(headersPresented, "headerss");
        // const extraHeaders = headers.filter(
        //   (header) => !requiredHeader.includes(header)
        // );
        // const hasExtraHeaders = extraHeaders.length > 0;
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        // const jsonDataTable = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        // const updatedJsonData = jsonData.map((row) => {
        //   requiredHeader.forEach((header) => {
        //     if (!row.hasOwnProperty(header)) {
        //       row[header] = ""; // Add missing header with empty string value
        //     }
        //   });
        //   return row;
        // });
        if (!headersPresented || jsonData.length === 0) {
          console.log("headersPresented", !headersPresented);
          setSnack({
            ...snack,
            open: true,
            severity: "error",
            message: !headersPresented
              ? "Enter the correct format"
              : jsonData.length === 0
                ? "Excel couldn't be empty"
                : null,
          });
          setExcelOpen(false);
          handleClose();
        } else {
          setExcelOpen(true);
          // setSelectedFileData(updatedJsonData);
          const missingIndices = []; // Array to store indices with missing or invalid parameters

          jsonData.forEach((row, index) => {
            const DummyParam = "Dummy, (N/A)";
            const modifiedUserParam = row['Standard Parameter']
              ? row['Standard Parameter']?.split(',')[0]?.trim()
              : DummyParam.split(',')[0].trim();

            const selectedStdParam = stdParams.find((data) => {
              return data.stdParameterName === modifiedUserParam;
            });

            if (!selectedStdParam) {
              // If no matching parameter is found
              console.log(`No match for 'Standard Parameter' at index: ${index}`);
              missingIndices.push(index); // Collect the index
            }
          });

          if (missingIndices.length > 0) {
            setErrorIndex((prev) => {
              return { ...prev, index: missingIndices, open: true }
            })
          }

          setSelectedFileData(jsonData);
          // }
          // }
          // jsonData.forEach((row, rowIndex) => {
          //   let header = Object.keys(row);
          //   let cellValue = Object.values(row);
          //   cellValue.forEach((value, cellIndex) => {
          //     let columnName = header[cellIndex];
          //     if (value === undefined || value === null || value === "") {
          //       errors.push({ rowIndex, columnName });
          //     }
          //   });
          // });
          // if (!headersPresented) {
          // setSnack({
          //     ...snack,
          //     open: true,
          //     severity: "error",
          //     message: "Enter the correct format",
          //   });
          //   setExcelOpen(false);
          //   handleClose();
          // }
          // console.log(errors, "errorss");

          // setErrorIndex(errors);
        }
      };
      reader.readAsBinaryString(file);
      fileInputRef.current.value = null;
    }

    // const file = event.target.files[0];
    // setfileName(file.name)
    // // const fileData = await ExcelUplaod(event)
    // // handleExcelparams(fileData?.data[0])
    // getstandardParams()
    // event.target.value = null;
    // console.log(file, 'eventhandleSelectExcel');
  }


  const handleClose = () => {
    setExcelOpen(false);
    setSelectedFile(null);
    setSelectedFileData(null);
  }

  const handleEmptyLogger = loggerMaster.filter(data => data.loggerFlag !== 1);
  console.log(handleEmptyLogger, 'handleEmptyLogger')

  const handleDataLogger = (data, event) => {
    if (event === null) {
      setLoggerName("")
    }
    else {
      setLoggerName(event)
    }


  }
  const handleSite = async (data, event) => {
    if (event === null) {
      setSite("")
    }
    else {
      setSite(event)
      // fetchEquipmentDetails(event)
      const siteIdFilter = siteIdName?.filter(data => data.siteName === event);
      setLoggerMaster([]);
      const instantLogger = await getLoggerMaster(siteIdFilter[0].siteId);
      let hardDomain = siteIdFilter[0]?.domain == 'Enerparc' || siteIdFilter[0]?.domain == 'Ice' ? './' : siteIdFilter[0]?.domain;
      getFilePath({
        ftpFolder: siteIdFilter[0]?.ftpFolder,
        domain: hardDomain,
      });
      setLoggerName("");
      const filteredInstantLogger = instantLogger?.filter(data => data.loggerFlag !== 1);
      if (filteredInstantLogger.length === 0) setSnack({ ...snack, open: true, severity: 'error', message: 'No logger available' });
    }
  }
  const handleEquipmentUnits = (index, col, value, row) => {

    let stdvalue = standardParams?.filter((data) => data.standardparametername === value.split("-")[0].trim());
    console.log(stdvalue);
    setStdValue(stdvalue[0]?.standardparameteruom)
    setStandardParamsId(stdvalue[0]?.standardid)

  }
  /*------------------------------------ Changing value  for tabel set value ----------------------------*/

  const handleChange = (index, col, value, row) => {
    const updatedValues = [...rows];
    const updatedValue = [...coefficientValue];

    if (col === "std_par") {

      console.log(value);
      updatedValues[index] = value;
      setRows(updatedValues);
      handleEquipmentUnits(index, col, value, row)


    } else {
      let validValue = value.replace(/[^0-9.]/g, '')
      const decimalIndex = validValue.indexOf('.');
      if (decimalIndex !== -1) {
        const decimalPart = validValue.substr(decimalIndex + 1, 5); // Extract up to 5 characters
        validValue = validValue.substr(0, decimalIndex + 1) + decimalPart; // Combine integer and decimal part
      }
      updatedValue[index] = validValue;
      setCoefficientValue(updatedValue);
      setActiveInputIndex(index);
      inputRefs.current[index] = inputRefs.current[index] || React.createRef();

    }
    inputRefs.current[index].focus();


    setTableData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        Parms: excelParams[index],
        [col]: value,

      };
      updatedData[index].units = stdValue
      updatedData[index].stdparamId = stdParamId
      return updatedData;
    });
  };

  console.log(tableData, "col");



  /*------------------------------------------ handle Submit ------------------------------*/

  const handleSubmitExcel = async () => {
    let siteIdFilter = siteIdName?.filter((data) => data.siteName === site)

    // let equipFilter = equipmentData?.filter((data) => { return (data.customerNaming === equipment) })

    const payLoad = selectedFileData?.map((row, i) => {
      // 'Digital_Input01' 'Digital_Input01'
      const dataloggerId = loggerMaster?.find(
        (data) => data?.dataLoggerName === loggerName
      )?.dataLoggerId;
      let filePath = selectedFile?.name;

      let DummyParam = "Dummy, (N/A)";
      const modifiedUserParam = row["Standard Parameter"] ? row["Standard Parameter"].split(",")[0].trim() : DummyParam.split(",")[0].trim();
      const selectedStdParam = stdParams.find((data) => {
        return [modifiedUserParam].includes(data.stdParameterName);
      });
      const handleDummy = stdParams.find(data => {
        return selectedStdParam === undefined ?
          data.stdParameterName === DummyParam?.split(",")[0].trim() :
          null
      });

      console.log(handleDummy, 'Digital_Input01');
      const stdUom = selectedStdParam ? selectedStdParam.stdUom : handleDummy ? handleDummy.stdUom : null;
      const stdId = selectedStdParam ? selectedStdParam.stdId : handleDummy ? handleDummy.stdId : null;
      let mainObj = {
        parameterName: row["Parameter Name"] || null,
        stdParamsId: stdId,
        uom: stdUom,
        sequence: i + 1,
        status: 1,
        filePath: filePath,
        dataLoggerId: dataloggerId,
        createdBy: +sessionStorage.getItem("id"),
        siteId: siteIdFilter[0].siteId,
        coEfficient: +row["Coefficient"] || 1
      }
      return mainObj;
    })

    try {
      setToBackend(true);
      let data = await PostdataConfig(payLoad);
      console.log(data);
      setSnack({
        open: true,
        severity: "success",
        message: "Datalogger Mapped successfully",
      });
      setSite(null);
      setLoggerName(null);
      setToBackend(false);
      handleClose();
    } catch (e) {
      console.log(e);
    }
    console.log(payLoad, 'loggerMaster');
    // console.log(stdParams, 'loggerMaster');
    // console.log(errorIndex, 'loggerMaster');
  }
  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Data Logger Excel", path: "datalogger" },
  ];
  return (
    <div>
      {
        loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20%",
            }}
          >
            <FadeLoader color="#000440" loading={true} />
          </div>
        ) : (
          <div>
            {
              excelOpen &&
              <div>
                <Modal
                  sx={{ display: 'flex', alignItems: 'center' }}
                  aria-labelledby="spring-modal-title"
                  aria-describedby="spring-modal-description"
                  open={excelOpen}
                  onClose={handleClose}
                  closeAfterTransition
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        m: 1,
                        width: "75vw",
                        marginLeft: "15vw",
                        marginTop: "23vh",
                      },
                    }}
                  >
                    <Card
                      elevation={2}
                      style={{ marginTop: "5%", borderRadius: "20px" }}
                    >
                      <CardHeader
                        sx={{
                          padding: "15px 20px 15px 20px",
                          // background: CheckForLight()
                          //   ? "rgb(246, 248, 252)"
                          //   : "#4f4f4f",
                        }}
                        action={
                          <Stack spacing={2} direction="row">
                            <Button
                              startIcon={<CancelIcon />}
                              color="primary"
                              variant="contained"
                              style={{
                                marginLeft: "10px",
                                textTransform: "capitalize",
                                borderRadius: "19px",
                              }}
                              onClick={() => {
                                handleClose();
                              }}
                            >
                              Close
                            </Button>
                          </Stack>
                        }
                        title="Data Logger"
                      />
                      <Divider style={{ borderColor: "#888" }} />
                      <TableContainer
                        sx={{
                          width: "75vw",
                          height: "58vh",
                          marginTop: "2vh",
                          // marginLeft: '5vw',
                        }}
                        component={Paper}
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead style={{ marginBottom: "9vh", userSelect: "none" }}>
                            <StyledTableRow>
                              <StyledTableCell
                                align="center"
                                style={{ color: "black" }}
                              >
                                {" "}
                                <Typography className="modal-typo">S.No</Typography>
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ color: "black" }}
                              >
                                {" "}
                                <Typography className="modal-typo">
                                  Parameter Name
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ color: "black" }}
                              >
                                {" "}
                                <Typography className="modal-typo">
                                  Standard Parameter
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ color: "black" }}
                              >
                                {" "}
                                <Typography className="modal-typo">
                                  Coefficient
                                </Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableHead>

                          <TableBody className="table-body">
                            {selectedFileData?.map((row, index) => (
                              <MemoizedTableRow
                                key={index}
                                row={row}
                                index={index}
                              />
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Divider style={{ borderColor: "#888" }} />
                      <div className="submit" style={{}}>
                        <Button
                          variant="contained"
                          style={{
                            width: "8vw",
                            marginLeft: "25vw",
                            marginTop: "-1%",
                            marginBottom: "1.5%",
                            borderRadius: "18px",
                            textTransform: "capitalize",
                          }}
                          disabled={toBackend}
                          startIcon={
                            toBackend ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : (
                              <SaveIcon />
                            )
                          }
                          onClick={handleSubmitExcel}
                        >
                          {!toBackend ? "Save" : "Saving..."}
                        </Button>
                      </div>
                    </Card>
                  </Box>
                </Modal>
              </div>
            }
            <>
              <div>
                <CustomSnackbar open={snack.open} onClose={() => setSnack(initialSnack)} autoHideDuration={5000} severity={snack.severity} message={snack.message} />
              </div>

              <div>
                <Box
                  sx={{
                    margin: "0 0.5% 0 0.5%",
                    display: "flex",
                    flexWrap: "wrap",
                    "& > :not(style)": {
                      mr: 0,
                      mb: 1,
                      // mt: 2,
                      borderRadius: 0,
                      width: "100%",
                      height: "7.5vh",
                    },
                  }}
                >
                  <Card
                    elevation={0}
                    style={{
                      display: "flex",
                      width: "101%",
                      height: "6.5vh",
                      borderRadius: "2px 2px 0px 0px",
                      userSelect: "none",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CustomBreadcrumbs
                        paths={paths || []}
                        separatorSize="18px"
                        fontSize="14px"
                      />
                    </div>
                  </Card>
                </Box>
              </div>
              <div>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    "& > :not(style)": {
                      m: 1,
                      width: "101%",
                      height: "10vh",
                    },
                  }}
                >
                  <Card
                    elevation={0}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", width: "100%" }}>
                      <Grid
                        container
                        spacing={1}
                        columns={20}
                        style={{ marginLeft: "1%" }}
                      >
                        <Grid item xs={5}>
                          <Typography className="modal-typo" sx={{ width: "14vw" }}>Site Name</Typography>
                          <div style={{ marginTop: "1%" }}>
                            <Tooltip title={site}>
                              <Autocomplete
                                disableClearable
                                disablePortal
                                id="combo-box-demo"
                                options={siteIdName.map((option) => option.siteName)}
                                onChange={(data, event) => handleSite(data, event)}
                                value={site || ""}
                                sx={{ width: "16vw" }}
                                ListboxProps={{
                                  style: {
                                    maxHeight: "200px", // Set your desired height here
                                  },
                                }}
                                size="small"
                                renderInput={(params) => <TextField {...params} placeholder="Select  Site ..." />}
                              />
                            </Tooltip>
                          </div>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography className="modal-typo" sx={{ width: "14vw" }}>
                            Data Logger Name
                          </Typography>
                          <div style={{ marginTop: "1%" }}>
                            <Tooltip title={loggerName}>
                              <Autocomplete
                                disableClearable
                                disablePortal
                                disabled={site === "" ? true : handleEmptyLogger.length === 0 ? true : false}
                                id="combo-box-demo"
                                options={handleEmptyLogger.map((option) => option.dataLoggerName)}
                                onChange={(data, event) => handleDataLogger(data, event)}
                                sx={{ width: "16vw" }}
                                ListboxProps={{
                                  style: {
                                    maxHeight: "200px", // Set your desired height here
                                  },
                                }}
                                value={loggerName || ""}
                                size="small"
                                renderInput={(params) => <TextField {...params} placeholder="Select Data Logger ..." />}
                              />
                            </Tooltip>
                          </div>
                        </Grid>
                      </Grid>
                      <div style={{ display: "flex", justifyContent: "flex-end", paddingRight: '1%' }}>
                        <div style={{}}>
                          <Button
                            variant="contained"
                            component="label"
                            sx={{
                              width: "10vw",
                              marginLeft: "10px",
                              textTransform: "capitalize",
                              borderRadius: "19px",
                              marginTop: "18px",
                            }}
                            ListboxProps={{
                              style: {
                                maxHeight: "200px",
                              },
                            }}
                            startIcon={<FileDownloadOutlinedIcon />}
                            onClick={() => handleDownload()}
                          >
                            Template
                          </Button>
                        </div>
                        <div style={{}}>
                          <Button
                            variant="contained"
                            component="label"
                            disabled={loggerName === "" ? true : false}
                            sx={{
                              width: "10vw",
                              marginLeft: "10px",
                              textTransform: "capitalize",
                              borderRadius: "19px",
                              marginTop: "18px",
                            }}
                            ListboxProps={{
                              style: {
                                maxHeight: "200px",
                              },
                            }}
                            startIcon={<FileUploadOutlinedIcon />}
                          >
                            Select File
                            <input
                              hidden
                              type="file"
                              ref={fileInputRef}
                              required
                              onChange={handleSelectExcel}
                              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Box>
              </div>
            </>
          </div>
        )
      }
    </div>
  );
};

export default ExcelUploadUi;