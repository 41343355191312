import React, { useEffect, useMemo, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsBoost from "highcharts/modules/boost";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { CheckForLight } from "../util/common/CommanFunction";
import { IconButton } from "@mui/material";
import { Height } from "@mui/icons-material";

HighchartsBoost(Highcharts);
HighchartsHeatmap(Highcharts);

export const HeatmapChart = (props) => {
  const {
    tableData,
    chartName,
    fallBack,
    WidgetName,
    from,
    customView,
    isDashboard,
    customName,
  } = props;
  console.log( tableData);

  const [showTable, setShowTable] = React.useState(false);
  const [tableContent, setTableContent] = React.useState("");
  console.log(props, "props");
  const isLight = CheckForLight();
  useEffect(() => {
    console.log("Table Data:", tableData);
    console.log("Chart Name:", chartName);
  }, [tableData, chartName]);

  const minValue = useMemo(() => {
    if (chartName === "Specific Yield - Heatmap") {
      return tableData?.reduce(
        (min, obj) => (obj.specificYield < min ? obj.specificYield : min),
        tableData[0]?.specificYield
      );
    }
    if (chartName === "String Current - Heatmap"||chartName ==="String Current - Average") {
      const dataOnly = tableData.map(item => item.datas);
      console.log(dataOnly);
      
      const allInputCurrents = dataOnly
        ?.map((obj) =>
          Object.keys(obj)
            .filter((key) => key.startsWith("inputCurrent_"))
            .map((key) => obj[key])
        )
        .flat();
      return Math.min(...allInputCurrents);
    }
  }, [chartName, tableData]);
  const maxValue = useMemo(() => {
    if (chartName === "Specific Yield - Heatmap") {
      return tableData?.reduce(
        (max, obj) => (obj.specificYield > max ? obj.specificYield : max),
        tableData[0]?.specificYield
      );
    }
    if (chartName === "String Current - Heatmap"||chartName ==="String Current - Average") {
      const dataOnly = tableData.map(item => item.datas);
console.log(dataOnly);

      const allInputCurrents = dataOnly
        ?.map((obj) =>
          Object.keys(obj)
            .filter((key) => key.startsWith("inputCurrent_"))
            .map((key) => obj[key])
        )
        .flat();
      return Math.max(...allInputCurrents);
    }
  }, [chartName, tableData]);

  console.log(minValue, Math.ceil(maxValue));

  if (!tableData || tableData.length === 0) {
    console.error("No data available for the heatmap");
    return fallBack;
  }

  function convertApiDataToHeatmap(apiData) {
    if (!apiData || !Array.isArray(apiData)) {
      console.error("Invalid data format:", apiData);
      return [];
    }

    // Remove sensor value
    const filteredData = apiData.filter(
      (entry) => entry.equipmentName !== "Radiation sensor"
    );

    // Sort the data by timeStamp
    const sortedData = [...filteredData].sort(
      (a, b) => new Date(a.timeStamp) - new Date(b.timeStamp)
    );

    // Create a mapping of equipment names to numerical indices
    const equipmentNameMap = {};
    sortedData.forEach((entry, index) => {
      const name = entry.equipmentName || `Unknown-${index}`;
      if (!(name in equipmentNameMap)) {
        equipmentNameMap[name] = Object.keys(equipmentNameMap).length;
      }
    });

    // Create heatmap data
    const heatmapData = sortedData.map((entry, index) => ({
      x: new Date(entry.timeStamp).getTime(),
      y: equipmentNameMap[entry.equipmentName || `Unknown-${index}`],
      value: entry.specificYield,
      de: entry.timeStamp,
      name: entry.equipmentName,
      custom: {
        equipmentId: entry.equipmentId === null ? 0 : entry.equipmentId,
        specificYield: entry.specificYield,
        timeStamp: entry.timeStamp,
        equipmentName: entry.equipmentName || `Unknown-${index}`,
      },
    }));
    console.log("Heatmap Data:", heatmapData);
    return heatmapData;
  }

  const heatmapData = convertApiDataToHeatmap(tableData);
  if (heatmapData.length === 0) {
    console.error("Heatmap data is empty after conversion");
    return <div>Unable to generate heatmap from the provided data.</div>;
  }

  const equipmentNames = [
    ...new Set(heatmapData.map((item) => item.custom.equipmentName)),
  ];

  const nameForFile = isDashboard ? customName : WidgetName;

  const getXAxisOptions = () => {
    if (chartName === "Specific Yield - Heatmap") {
      return {
        type: "datetime",
        labels: {
          format: "{value:%b %d, %Y}",
          style: {
            color: isLight ? "black" : "white",
          },
        },
        title: {
          text: "Date",
          style: {
            color: isLight ? "black" : "white",
          },
        },
      };
    } 
    else if (chartName === "String Current - Heatmap" || chartName === "String Current - Average") {
      const dataOnly = tableData.map(item => item.datas);
      console.log(dataOnly);
      console.log(tableData);
      
      // Sort data based on inputCurrent_01, treating null or undefined as 0
      const sortedTableData = dataOnly.sort((a, b) => {
        const aValue = a.inputCurrent_01 != null ? a.inputCurrent_01 : 0;
        const bValue = b.inputCurrent_01 != null ? b.inputCurrent_01 : 0;
        return aValue - bValue; // Ascending order
      });
      console.log(sortedTableData);
    
      // Get all keys starting with 'inputCurrent_'
      const inputCurrentKeys = sortedTableData.length
        ? Object.keys(dataOnly[0]).filter((key) => key.startsWith("inputCurrent_"))
        : [];
    
      // Format the numeric values as '01', '02', '03', etc.
      const numericValues = inputCurrentKeys.map((key) => {
        const num = key.replace("inputCurrent_", "");
        return num.padStart(2, "0"); // Add leading zeros for single-digit numbers
      });
    
      console.log(numericValues);
    
      return {
        categories: numericValues, // This will be the X-axis values
        title: {
          text: "Input Current",
          style: {
            color: isLight ? "black" : "white",
          },
        },
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      };
    }
    
     else {
      return {
        title: {
          text: "Equipment Names",
          style: {
            color: isLight ? "black" : "white",
          },
        },
      };
    }
  };

  const getYAxisOptions = () => {
    if (
      chartName === "Specific Yield - Heatmap" ||
    chartName ==="String Current - Average"||    chartName ==="String Current - Heatmap"
    ) {
      return {
        categories: tableData.map((item) => item.equipmentName),
        title: {
          text: "Equipment Names",
          style: {
            color: isLight ? "black" : "white",
          },
        },
        // height: chartName === "String Current - Heatmap" ? "100px" : undefined,
      };
    }
    {
      return {
        // categories: equipmentNames,
        title: {
          text: "Equipment Names",
          style: {
            color: isLight ? "black" : "white",
          },
        },
      };
    }
  };
  console.log(heatmapData);

  const getSeriesOptions = () => {
    if (chartName === "Specific Yield - Heatmap") {
      console.log(heatmapData);
      return [
        {
          boostThreshold: 5000,
          borderWidth: 0,
          nullColor: "#3060cf",
          colsize: 3600 * 1000 * 24, // 1 day in milliseconds
          data: heatmapData.map((entry) => ({
  
           
            
            x: entry.x, // x-axis: timestamp in milliseconds
            y: entry.y, // y-axis: equipment index
            value: entry.value, // Heatmap intensity
            de: entry.de, // Date
            name: entry.name, // Equipment Name
          })),

          nullColor: "gray",
          dataLabels: {
            enabled: true,
            style: {
          
              textOutline: "none",
            },
          },
          tooltip: {
            headerFormat: "",
            pointFormat:
              "Date: {point.de}<br/>" +
              "{point.name}<br/>" +
              "Specific Yield: {point.value}",
          },
        },
      ];
    } else if (chartName === "String Current - Heatmap"||chartName ==="String Current - Average") {
      const dataOnly = tableData.map(item => item.datas);
console.log(dataOnly);
      const inputCurrentKeys = dataOnly.length
        ? Object.keys(dataOnly[0]).filter((key) =>
            key.startsWith("inputCurrent_")
          )
        : [];

      const heatmapData = [];
console.log(dataOnly);

dataOnly.forEach((item, rowIndex) => {
        inputCurrentKeys.forEach((key, colIndex) => {
          const value = item[key];
          const equipmentName = item.equipmentName;

          // Store the data for the heatmap: [column, row, value, inputCurrent name, equipmentName]
          heatmapData.push({
            x: colIndex,
            y: rowIndex,
            value,
            inputCurrent: key,
            equipmentName,
          });
        });
      });

      console.log(heatmapData);

      return [
        {
          data: heatmapData.map((dataPoint) => [
            dataPoint.x,
            dataPoint.y,
            dataPoint.value,
          ]),
          dataLabels: {
            enabled: true,
            style: {
          
              textOutline: "none",
            },
          },
          color: "#f7a35c",
          tooltip: {
            headerFormat: "",
            pointFormatter() {
              const point = heatmapData.find(
                (dataPoint) => dataPoint.x === this.x && dataPoint.y === this.y
              );

              // Check if the point or point.value is null/undefined
              const valueText =
                point.value !== null && point.value !== undefined
                  ? `${point.value}`
                  : "No data available";

              return `
                Equipment : ${point.equipmentName || "Unknown"}<br/>
                ${
                  point.inputCurrent || "Unknown Input Current"
                } : ${valueText}<br/>
              `;
            },
          },
        },
      ];
    } else {
      return [
        {
          name: "Equipment Performance",
          data: tableData.map((item) => [
            item.equipmentId, // x-axis: equipment ID
            item.specificYield, // y-axis: specific yield value
            item.specificYield, // Heatmap intensity
          ]),
          color: "#f7a35c",
          tooltip: {
            pointFormat:
              "Equipment ID: {point.x}<br/>" + "Specific Yield: {point.value}",
          },
        },
      ];
    }
  };

  const chartOptions = {
    chart: {
      type: "heatmap",
      backgroundColor: isLight ? "white" : "#121212",
      // height:"500px",
    },
    boost: {
      useGPUTranslations: true,
    },
    title: {
      text: WidgetName,
      align: "left",
      x: 40,
  
      style: {
        textTransform: "captalize",
        color: isLight ? "black" : "white",
        fontWeight: "normal",
      },
    },
    xAxis: getXAxisOptions(),
    yAxis: getYAxisOptions(),
    series: getSeriesOptions(),
    credits: {
      enabled: false,
    },
    colorAxis: {
      // stops: [
      //   [0, "#e88279"], // Red
      //   [0.25, "#e8b179"], // Coral
      //   [0.5, "#fffbbc"], // Yellow
      //   [0.75, "#87cefa"], // Light Sky Blue
      //   [1, "#7c9eeb"], 
      //   [2.5,"#db8b5c"],
      //   [3,"#c1f7cf"],
      //   [5, "#FFFFFF"],
      //   [10, "#668ab0 "],
      // ],
      stops: [
        [0, "#F76971"], // Red
        [0.25, "#ff7f50"], // Coral
        [0.5, "#fffbbc"], // Yellow
        [0.75, "#CCD289"], // Light Sky Blue
        [1, "#79C383"], // Blue
        [5, "#78C377"],
       
      ],
    
      min: minValue,
      max: maxValue,
      startOnTick: false,
      endOnTick: false,
      labels: {
        format: "{value}",
      },
    },
    exporting: {
      filename: nameForFile + "-" + new Date().toLocaleDateString("en-GB"),
      csv: {
        dateFormat: "%d/%m/%Y",
        columnHeaderFormatter: function (item) {
          if (item instanceof Highcharts.Axis && item.isXAxis) {
            return "Date";
          }
          return ["Equipment Name", "Specific Yield"];
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadCSV",
            "downloadXLS",
            from === "Vanalytics"
              ? {
                  text: "View Table",
                  onclick: function () {
                    const chart = this;
                    let dataHtml = chart.getTable();

                    // Replace empty cells with 0
                    dataHtml = dataHtml.replace(
                      /<td class="highcharts-empty">\s*<\/td>/g,
                      '<td class="highcharts-number">0</td>'
                    );

                    // Format timestamps in the table
                    dataHtml = dataHtml.replace(
                      /<th class="highcharts-text" scope="row">(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})<\/th>/g,
                      function (match, p1) {
                        const date = new Date(p1);
                        return `<th class="highcharts-text" scope="row">${date.toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "numeric",
                            year: "numeric",
                          }
                        )}</th>`;
                      }
                    );
                    setTableContent(dataHtml);
                    setShowTable(true);
                  },
                }
              : "",
          ],
        },
      },
      enabled: true,
    },
    plotOptions: {
      series: {
        events: {
          legendItemClick: function () {
            const series = this.chart.series;
            const currentSeries = this;

            if (series.length === 2) {
              const [series1, series2] = series;

              if (currentSeries === series1) {
                series1.update({ visible: true }, false);
                series2.update({ visible: !series1.visible }, false);
              } else if (currentSeries === series2) {
                series2.update({ visible: true }, false);
                series1.update({ visible: !series2.visible }, false);
              }
            } else {
              const visibleSeries = series.filter((s) => s.visible);
              const onlyOneVisible = visibleSeries.length === 1;

              series.forEach((s) => {
                if (s === currentSeries) {
                  s.update({ visible: !s.visible }, false);
                } else {
                  s.update({ visible: !onlyOneVisible }, false);
                }
              });
            }

            this.chart.redraw();
            return false;
          },
        },
      },
    },
  };

  return (
    <div   style={{
      overflowX: "auto",
      width: "100%",
      height: isDashboard
        ? "300px"
        : from === "Vanalytics"
        ? "500px"
        : "fit-content",
    }}>
      <div style={{ position: "relative" }}>
        {showTable && (
          <div
            className="tableData"
            dangerouslySetInnerHTML={{ __html: tableContent }}
          />
        )}
        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            // Height: "500px",
            backgroundColor: "#ffffff",
            "&:hover": { backgroundColor: "#ffffff" },
          }}
          onClick={() => setShowTable(!showTable)}
        >
          <ArrowBackOutlinedIcon />
        </IconButton>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    </div>
  );
};
