import { useEffect } from "react";

const useSessionSync = () => {
  const channel = new BroadcastChannel("sessionChannel");

  // Function to broadcast all sessionStorage data
  const broadcastSessionData = () => {
    const sessionData = { ...sessionStorage }; // Get all sessionStorage values
    console.log("Broadcasting session data:", sessionData);
    channel.postMessage({ type: "session-update", data: sessionData });
  };

  // Function to request session data
  const requestSessionData = () => {
    console.log("Requesting session data from other tabs...");
    channel.postMessage({ type: "session-request" });
  };

  // Listen for session data updates
  useEffect(() => {
    console.log("useSessionSync: Initializing effect.");

    const handleStorageChange = () => {
      console.log("Storage event detected. Broadcasting session data.");
      broadcastSessionData();
    };

    // Listener for BroadcastChannel messages
    const handleChannelMessage = (event) => {
      const message = event.data;
      console.log("Received message on channel:", message);

      switch (message.type) {
        case "session-update":
          if (Object.keys(sessionStorage).length === 0) {
            console.log(
              "No session data present. Updating from received data:",
              message.data
            );
            Promise.all(
              Object.entries(message.data).map(([key, value]) =>
                sessionStorage.setItem(key, value)
              )
            ).then(() => {
              window.location.reload();
            });
          } else {
            console.log("Session data already exists. Ignoring update.");
          }
          break;

        case "session-request":
          if (Object.keys(sessionStorage).length > 0) {
            console.log("Session request received. Broadcasting session data.");
            broadcastSessionData();
          } else {
            console.log("Session request received, but no data to broadcast.");
          }
          break;

        default:
          console.warn("Unknown message type:", message.type);
      }
    };

    // Add event listeners
    window.addEventListener("storage", handleStorageChange);
    channel.addEventListener("message", handleChannelMessage);

    // On mount, request session data if sessionStorage is empty
    if (Object.keys(sessionStorage).length === 0) {
      console.log(
        "Session data is empty on load. Requesting data from other tabs."
      );
      requestSessionData();
    } else {
      console.log("Session data exists on load:", { ...sessionStorage });
    }

    // Cleanup on unmount
    return () => {
      console.log("useSessionSync: Cleaning up effect.");
      window.removeEventListener("storage", handleStorageChange);
      channel.removeEventListener("message", handleChannelMessage);
      channel.close();
    };
  }, []);

  console.log("useSessionSync hook initialized.");
};

export default useSessionSync;
