import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Box,
  TextField,
  Modal,
  Fade,
  Stack,
  Autocomplete,
  IconButton,
  Backdrop,
  Divider,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  InputAdornment,
  MenuItem,
  Select,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { downloadExcelTemplate } from "../../Template/ExcelTemplates/ExcelTemplate";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { FadeLoader } from "react-spinners";
// ========Modal================
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
/*------------api call--------------*/
import {
  fetchCurrencyData,
  postCurrencydataSave,
  putCurrencyUpdate,
  deleteCurrencyDelete
} from "../../Api/CurrencyApi";
import { fetchSiteDetails } from "../../Api/ReportApi";
import TableTemplate from "../../Template/TableTemplate";
import Download from "../../Template/Excel/Download";
import CustomSnackbar from "../util/components/CustomSnackbar";
import { Form, Formik } from "formik";
import { ErrorConfigSchema } from "../util/ValidateSchema";
import { Statusdropdown } from "../../Api/CommonApi";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import errorMsg from "../util/errorMessage.json";
import { CheckForLight } from "../util/common/CommanFunction";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import dayjs from "dayjs";
import { date } from "yup";
export default function Currency({ errorConfiguration }) {
  /*------------state-----------------*/
  const [expectedata, setExpectData] = useState([]);
  console.log(expectedata);
  const [existingMonths, setExistingMonths] = useState([]);
  const [activeFlag, setactiveFlag] = useState("");
  const [statusDrop, setStatusDrop] = useState([]);
  const [editcall, seteditcall] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [excelOpen, setExcelOpen] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [errorIndex, setErrorIndex] = useState(null);
  const [edit, setEdit] = useState(false);
  const [loading, setloading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [categoryvalue, setCategoryvalue] = useState([]);
  const [sitestatus, setSitestatus] = useState("");
  const [siteExpect, setsiteExpect] = useState("");
  const [replace, setReplace] = useState(null);
  const [siteName, setSiteNameList] = useState([]);
  const [category, setCategory] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [modalvalue, setModalvalue] = useState(true);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [toBackend, setToBackend] = useState(false);
  const [selectedFileData, setSelectedFileData] = useState(null);
  const [indexVal, setIndexVal] = useState(null);
  const [errorVal, setErrorVal] = useState(null);
  const [siteTab, setSiteTab] = React.useState("");
  const [rowdata, setRowdata] = useState([]);
  const [rowdatas, setRowdatas] = useState([]);
  const initalState = {
    siteName: "",
    id: "",
    gridCost: "",
    dgCost: "",
    energyGeneration: "",
    pvCost: "",
    effectiveFromDate: "",
    effectiveToDate: "",
    currency: "",
    currencyUnits: "",
    status: "",
    lastUpdatedBy: "",
    customerName: [],
  };
  const [expectFields, setExpectFields] = useState([
    {
      id: "",
      gridCost: "",
      dgCost: "",
      energyGeneration: "",
      pvCost: "",
      effectiveFromDate: "",
      effectiveToDate: "",
      currency: "",
      currencyUnits: "",
    },
  ]);
  console.log(expectFields);

  const [stdField, setstdField] = useState(initalState);
  console.log(stdField?.siteName);
  const [disabledSiteName, setDisabledSiteName] = useState(false);
  const [SiteTypeIdFilter, setSiteTypeIdFilter] = useState([]);
  const [expectedGeneration, setExpectedGeneration] = useState([]);
  const [expectedMonth, setExpectedMonth] = useState([]);
  const [editFilteredData, setEditFilteredData] = useState([]);
  const initialSnack = { open: false, severity: "", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  console.log(expectedata);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };

  const handleDateChange = (newValue, fieldIndex, backendKey) => {
    const updatedFields = [...expectFields];
    updatedFields[fieldIndex][backendKey] = newValue
      ? newValue.format("YYYY-MM-DD")
      : "";
    setExpectFields(updatedFields);
  };

  const handleChangeData = (event, index) => {
    const { name, value } = event.target;
    const numericFields = [
      "gridCost",
      "energyGeneration",
      "dgCost",
      "pvCost",
      "effectiveFromDate",
      "effectiveToDate",
      "eGrid",
      "currency",
    ];

    if (numericFields.includes(name)) {
      const regex = /^-?\d*\.?\d*$/;
      if (regex.test(value) || value === "") {
        setExpectFields((prevFields) => {
          const newFields = [...prevFields];
          newFields[index] = { ...newFields[index], [name]: value };
          return newFields;
        });
      }
    } else {
      setExpectFields((prevFields) => {
        const newFields = [...prevFields];
        newFields[index] = { ...newFields[index], [name]: value };
        return newFields;
      });
    }
  };

  const handleCurrencyAmountChange = (event, fieldIndex) => {
    const updatedFields = [...expectFields];
    if (!updatedFields[fieldIndex]) {
      updatedFields[fieldIndex] = {}; // Initialize if undefined
    }
    updatedFields[fieldIndex].currency = event.target.value; // Set amount (currency value)
    setExpectFields(updatedFields); // Update the state with the new value
  };

  const handleCurrencyUnitChange = (event, fieldIndex) => {
    const updatedFields = [...expectFields];
    if (!updatedFields[fieldIndex]) {
      updatedFields[fieldIndex] = {}; // Initialize if undefined
    }
    updatedFields[fieldIndex].currencyUnits = event.target.value; // Set selected currency unit
    setExpectFields(updatedFields); // Update the state with the new value
  };

  const handleCurrencyUpdate = (event, fieldIndex, key) => {
    const updatedFields = [...expectFields];

    if (!updatedFields[fieldIndex]) {
      updatedFields[fieldIndex] = {}; // Initialize if undefined
    }

    updatedFields[fieldIndex][key] = event.target.value; // Update either currency or currencyunits based on the key
    setExpectFields(updatedFields); // Update state with the new value
  };

  const currencyOptions = ["USD", "INR", "EUR", "CNY"];
  const typoStyle = { fontSize: "14px", marginBottom: "4px" };
  const fieldStyle = { width: "100%", minWidth: "120px" };
  const requiredHeader = [
    "Site Name",
    "Year",
    "Cost of grid",
    "Cost of DG",
    // "Per units price (kW)",
    "Cost of PV",
    "Effective from ",
    "Effective to",
    "CurrencyUnits",
  ];
  const isOEM = sessionStorage.getItem("userTypeId") == 1;
  /*----------------------------Fields-------------------------------*/
  const [clickCount, setClickCount] = useState(1);
  const maxFields = 12;
  const handleAddButtonClick = () => {
    setExpectFields((prevFields) => {
      if (prevFields.length < 12) {
        return [
          ...prevFields,
          {
            gridCost: "",
            energyGeneration: "",
            dgCost: "",
            pvCost: "",
            effectiveFromDate: "",
            effectiveToDate: "",
            currency: "",
            currencyUnits: "",
          },
        ];
      }
      return prevFields;
    });
  };
  const handleRemoveButtonClick = (index) => {
    setExpectFields((prevFields) => {
      if (prevFields.length > 1) {
        const removedMonth = prevFields[index].month;
        if (removedMonth) {
          setSelectedMonths((prevSelectedMonths) =>
            prevSelectedMonths
              .filter
              // (month) => month !== removedMonth.toUpperCase()
              ()
          );
        }

        return prevFields.filter((_, i) => i !== index);
      }
      return prevFields;
    });
  };

  const handleYearChange = (value, fieldIndex) => {
    setSelectedYear(value);
    setExpectFields((prevFields) =>
      prevFields.map((field) => ({
        ...field,
        currentYear: value,
      }))
    );
  };

  const headerMapping = {
    "Cost of grid": "gridCost",
    "Cost of DG": "dgCost",
    "Cost of PV": "pvCost",
    "Effective from ": "effectiveFromDate",
    "Effective to": "effectiveToDate",
    Currency: "currencyUnits",
  };
  const findMatchingRowIndices = (existingData, errorData) => {
    // Ensure errorData is an array, even if it's not
    const rowdatas = Array.isArray(errorData) 
      ? errorData 
      : typeof errorData === 'string' 
        ? errorData.split(/],\s*\[/).map(range => range.replace(/[\[\]]/g, '').trim())
        : [];
  
    // Find indices for each date range
    const matchedIndices = rowdatas.map(targetDateRange => {
      // Split the date range into start and end dates
      const [startDate, endDate] = targetDateRange.split(' - ').map(date => new Date(date));
  
      // Find indices that have any overlap with the target date range
      const indices = existingData.reduce((acc, item, index) => {
        if (item.effectiveFromDate && item.effectiveToDate) {
          const itemStartDate = new Date(item.effectiveFromDate);
          const itemEndDate = new Date(item.effectiveToDate);
  
          // Check for date range overlap
          const isOverlapping = 
            (startDate <= itemEndDate && endDate >= itemStartDate) ||
            (itemStartDate <= endDate && itemEndDate >= startDate);
  
          if (isOverlapping) {
            acc.push(index);
          }
        }
        return acc;
      }, []);
      console.log(indices)
      return indices;
   
      
    });
  
  setRowdata([...new Set(matchedIndices.flat())])
    
    return [...new Set(matchedIndices.flat())];
  };
  /*------------------------- Api --------------------------*/
  useEffect(() => {
    fetchStatus();
    fetchSitenameList();
    setSitestatus();
    setSiteNameList();
    setExpectedMonth();
    setExpectedGeneration();
  }, []);
  useEffect(() => {
    if (siteExpect !== "") {
      let SiteTypeIdsFilter = siteName?.filter((data) => {
        return data.siteName === siteExpect;
      });
      let siteExpectId = SiteTypeIdsFilter[0]?.siteId;
      getExpectData(siteExpectId, 2024);
    }
  }, [siteExpect, siteName]);
  console.log(siteExpect);

  /*-------------------------Table get call -------------------------------*/
  const getExpectData = async (Id, year) => {
    setloading(true);
    try {
      let id = Id;
      if (Array.isArray(Id)) {
        id = Id[0]?.siteId;
      }
      console.log(siteName);

      let siteIdFilter = siteName?.filter((data) => {
        console.log(data);
        return siteName.siteId === id;
      });
      console.log(siteIdFilter);
      const data = await fetchCurrencyData(id, year);
      console.log(siteIdFilter);
      if (Array.isArray(data)) {
        console.log(data);
        setExpectData(data);
        const months = data.map((item) => item.month);
        setExistingMonths(months);
      }
      setloading(false);
    } catch (e) {
      console.error(e);
      setloading(false);
    }
  };

  /*----------------------- handle save --------------------*/
  const handleSave = async () => {
    setRowdata([])
    setRowdatas([])
    let siteIdFilter = siteName?.filter((data) => {
      return data.siteName === stdField.siteName;
    });

    const dataToSave = expectFields.map((field) => ({
      siteId: siteIdFilter[0]?.siteId,
      gridCost: field.gridCost || "",
      dgCost: field.dgCost || "",
      pvCost: field.pvCost || "",
      effectiveFromDate: field.effectiveFromDate || "",
      effectiveToDate: field.effectiveToDate || "",
      currencyUnits: field.currencyUnits || "",
    }));
    console.log("Data to save:", dataToSave);
    let errorDetails = [];
    // Validate dates and check for overlaps
    dataToSave.forEach((data, index) => {
      const rowStartDate = new Date(data.effectiveFromDate);
      const rowEndDate = new Date(data.effectiveToDate);
  
      // Validate that effectiveFromDate is earlier than or equal to effectiveToDate
      if (rowStartDate > rowEndDate) {
        errorDetails.push({
          rowIndex: index + 1,
          range: `${data.effectiveFromDate} - ${data.effectiveToDate}`,
          error: "Invalid date range (start date after end date)",
        });
        return; // Skip further checks for this row
      }
  
      // Check for overlaps with other rows
      dataToSave.forEach((compareData, compareIndex) => {
        if (compareIndex !== index) {
          const compareStartDate = new Date(compareData.effectiveFromDate);
          const compareEndDate = new Date(compareData.effectiveToDate);
  
          const isOverlapping =
            rowStartDate <= compareEndDate && rowEndDate >= compareStartDate;
  
          if (
            isOverlapping &&
            (data.gridCost || data.dgCost || data.pvCost) &&
            (compareData.gridCost || compareData.dgCost || compareData.pvCost)
          ) {
            errorDetails.push({
              rowIndex: index + 1, // Index of the current row
              range: `${data.effectiveFromDate} - ${data.effectiveToDate}`,
              overlappingRowIndex: compareIndex + 1, // Index of the overlapping row
              overlappingRange: `${compareData.effectiveFromDate} - ${compareData.effectiveToDate}`,
              error: "Overlapping date range with cost values",
            });
          }
        }
      });
    });
  
    if (errorDetails.length > 0) {
      // Update row data based on errors
      errorDetails.forEach((error) => {
        setRowdata((prevData) => [...prevData, error.range]);
      });
  
      // Prepare error message log including the index
      const errorMessage = errorDetails
        .map(
          (error) =>
            `${error.rowIndex-1}`
        )
        .join(", ");
      setRowdata(errorMessage);
  
      setSnack({
        open: true,
        severity: "error",
        message: `Duplicate effective rows are provided`,
      });
  
      return 0; // Exit function if validation fails
    }
    try {
      setToBackend(true);
      const responseData = await postCurrencydataSave(dataToSave);
      getExpectData(siteIdFilter[0]?.siteId, selectedYear);
      setSnack({
        open: true,
        severity: "success",
        message: responseData.statusDescription,
      });
      setSnack(errorMsg.success);
      handleClose();
      setToBackend(false);
      setOpen(false);
      setExistingMonths((prevMonths) => [
        ...prevMonths,
        ...dataToSave.map((d) => d.month),
      ]);
      return 1;
    } catch (e) {
      setRowdatas(e.response.data.error);
   findMatchingRowIndices ( expectFields,e.response.data.error)

   if (e.response?.status === 500) {
       
      setSnack({
        open: true,
        severity: "error",
        message: `Duplicate effective rows are provided`,
      });
      console.error("Error:", e);
      setToBackend(false);
      return 0;
    }}
    setRowdata([])
    setRowdatas([])
  };

  /*----------------------- handle Update -------------------*/
  const handleUpdate = async (values) => {
    console.log(values);
  
    let siteIdFilter = siteName?.filter(
      (data) => data.siteName === stdField.siteName
    );
  
    const updatedData = expectFields.map((value) => ({
      createdBy: Number(sessionStorage.getItem("id")),
      id: value.id,
      siteId: siteIdFilter[0]?.siteId,
      status: 1,
      gridCost: value.gridCost || "",
      dgCost: value.dgCost || "",
      pvCost: value.pvCost || "",
      effectiveFromDate: value.effectiveFromDate || "",
      effectiveToDate: value.effectiveToDate || "",
      currencyUnits: value.currencyUnits || "",
    }));
  
    let errorDetails = []; // Array to store overlapping row details
  
    // Validate dates and check for overlaps
    updatedData.forEach((data, index) => {
      const rowStartDate = new Date(data.effectiveFromDate);
      const rowEndDate = new Date(data.effectiveToDate);
  
      // Validate that effectiveFromDate is earlier than or equal to effectiveToDate
      if (rowStartDate > rowEndDate) {
        errorDetails.push({
          rowIndex: index + 1,
          range: `${data.effectiveFromDate} - ${data.effectiveToDate}`,
          error: "Invalid date range (start date after end date)",
        });
        return; // Skip further checks for this row
      }
  
      // Check for overlaps with other rows
      updatedData.forEach((compareData, compareIndex) => {
        if (compareIndex !== index) {
          const compareStartDate = new Date(compareData.effectiveFromDate);
          const compareEndDate = new Date(compareData.effectiveToDate);
  
          const isOverlapping =
            rowStartDate <= compareEndDate && rowEndDate >= compareStartDate;
  
          if (
            isOverlapping &&
            (data.gridCost || data.dgCost || data.pvCost) &&
            (compareData.gridCost || compareData.dgCost || compareData.pvCost)
          ) {
            errorDetails.push({
              rowIndex: index + 1, // Index of the current row
              range: `${data.effectiveFromDate} - ${data.effectiveToDate}`,
              overlappingRowIndex: compareIndex + 1, // Index of the overlapping row
              overlappingRange: `${compareData.effectiveFromDate} - ${compareData.effectiveToDate}`,
              error: "Overlapping date range with cost values",
            });
          }
        }
      });
    });
  
    if (errorDetails.length > 0) {
      // Update row data based on errors
      errorDetails.forEach((error) => {
        setRowdata((prevData) => [...prevData, error.range]);
      });
  
      // Prepare error message log including the index
      const errorMessage = errorDetails
        .map(
          (error) =>
            `${error.rowIndex-1}`
        )
        .join(", ");
      setRowdata(errorMessage);
  
      setSnack({
        open: true,
        severity: "error",
        message: `Duplicate effective rows are provided`,
      });
  
      return 0; // Exit function if validation fails
    }
  
    try {
      setToBackend(true);
      const responseData = await Promise.all(
        updatedData.map((data) => putCurrencyUpdate(data, data.id))
      );
  
      setExpectData((prevData) =>
        prevData.map((item) => {
          const updatedItem = responseData.find(
            (updated) => updated.id === item.id
          );
          return updatedItem ? { ...item, ...updatedItem } : item;
        })
      );
  
      setSnack({
        open: true,
        severity: "success",
        message: "Data updated successfully",
      });
      handleClose();
      setToBackend(false);
      return 1;
      setRowdata([])
    } catch (e) {
      setSnack({
        open: true,
        severity: "error",
        message: "Failed to update data",
      });
      console.error("Error:", e);
      setToBackend(false);
      return 0;
    }
  
  };
  

   const handleDelete = async (val) => {
    console.log(val);
    
     console.log(val?.id);
     const dele = await deleteCurrencyDelete(val?.id)
       .then(() => {
        getExpectData(val?.siteId);
        //  window.location.reload();
         setSnack((prev) => {
           return {
             ...prev,
             open: true,
             severity: 'success',
             message: 'Data deleted successfully',
           };
         });
       })
       .catch((err) => {
         setSnack((prev) => {
           return {
             ...prev,
             open: true,
             severity: 'warning',
             message: 'Unable to delete try again later',
           };
         });
         console.log(err);
       });
   };

  const fetchSitenameList = async () => {
    const data = await fetchSiteDetails();
    if (Array.isArray(data)) {
      setSiteNameList(data);
    } else if (typeof data === "object" && data !== null) {
      setSiteNameList([data]);
    }
    getExpectData(data, 2024);
  };

  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === "object" && data !== null) {
      setStatusDrop([data]);
    }
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setModalvalue(false);
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const headers = [];
        const range = XLSX.utils.decode_range(sheet["!ref"]);
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellAddress = XLSX.utils.encode_cell({ r: range.s.r, c: C });
          const cell = sheet[cellAddress];
          if (cell) {
            headers.push(cell.v);
          }
        }
        const headersPresented = requiredHeader
          .map((header) => header.toLowerCase().replace(/ /g, ""))
          .every((header) =>
            headers
              .map((head) => head.toLowerCase().replace(/ /g, ""))
              .includes(header)
          );
        console.log(headers, "headers");
        const extraHeaders = headers.filter(
          (header) => !requiredHeader.includes(header)
        );
        const hasExtraHeaders = extraHeaders.length > 0;
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        const jsonDataTable = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const updatedJsonData = jsonData.map((row) => {
          requiredHeader.forEach((header) => {
            if (!row.hasOwnProperty(header)) {
              row[header] = "";
            }
          });
          return row;
        });
        if (!headersPresented || jsonData.length === 0) {
          console.log("headersPresented", !headersPresented);
          setSnack({
            ...snack,
            open: true,
            severity: "error",
            message: !headersPresented
              ? "Enter the correct format"
              : jsonData.length === 0
              ? "Excel couldn't be empty"
              : null,
          });
          setExcelOpen(false);
          handleClose();
        } else {
          setExcelOpen(true);
          setSelectedFileData(updatedJsonData);
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleExcelUpload = async () => {
    if (selectedFileData) {
      try {
        setToBackend(true);
        const processedData = selectedFileData.map((item) => {
          const siteObject = siteName?.find(
            (site) => site.siteName === item["Site Name"]
          );

          return {
            siteId: siteObject ? siteObject.siteId : null,
            siteName: item["Site Name"],
            // createdBy: Number(sessionStorage.getItem("id")),
            gridCost: parseFloat(item["Cost of grid"]),
            dgCost: parseFloat(item["Cost of DG"]),
            pvCost: parseFloat(item["Cost of PV"]),
            effectiveFromDate: parseFloat(item["Effective from "]),
            effectiveToDate: parseFloat(item["Effective to"]),
            status: 1,
          };
        });
        const validData = processedData.filter((item) => item.siteId !== null);
        if (validData.length === 0) {
          throw new Error(
            "No valid data to save. Please check the site names in your Excel file."
          );
        }
        const duplicateMonth = validData.some((item) => {
          return expectedata.some(
            (existing) =>
              existing.siteId === item.siteId && existing.month === item.month
          );
        });

        if (duplicateMonth) {
          throw new Error(
            "The month is already configured for one or more sites."
          );
        }

        const responseData = await postCurrencydataSave(validData);
        if (responseData.status === 200) {
          handleClose();
          const updatedData = [...expectedata, ...validData];
          setExpectData(updatedData);
          if (selectedSite) {
            const filteredData = updatedData.filter(
              (item) => item.siteId === selectedSite.siteId
            );
            setExpectData(filteredData);
          }
          setSnack({
            open: true,
            severity: "success",
            message: "Data uploaded successfully",
          });
        }
      } catch (e) {
        console.error("Error uploading data:", e);
        setSnack({
          open: true,
          severity: "error",
          message:
            e.message ||
            "Failed to upload data. Please check your Excel file and try again.",
        });
      } finally {
        setToBackend(false);
      }
    }
  };
  const handleSite = (newValue) => {
    console.log(newValue);

    if (edit) {
      setstdField((prevState) => ({
        ...prevState,
        siteName: newValue,
      }));
    }
  };


  const checkDateRanges = (rows) => {
    // The rows from the backend error
    console.log("Backend error rows:", rows);
    
    // The current expectFields state
    console.log("Current expectFields:", expectFields);
    
    // Function to filter rows based on a specific date range
    const findMatchingRows = (dateRange) => {
      console.log(dateRange); 
      
      return expectFields.filter(field => {
        const fromDate = field.effectiveFromDate;
        const toDate = field.effectiveToDate;
        
        // Check if the date range matches exactly
        return fromDate === dateRange.split(' - ')[0] && 
               toDate === dateRange.split(' - ')[1];
      });
    };
    
    // If rows is an array of date ranges
    if (Array.isArray(rows)) {
      rows.forEach(dateRange => {
        const matchingRows = findMatchingRows(dateRange);
        
        if (matchingRows.length > 0) {
          console.log(`Matching rows for ${dateRange}:`, matchingRows);
          // Do something with the matching rows
          // For example, you might want to set state or perform further processing
        }
      });
    }
  };


  /*---------------------------------- New Error Call -----------------------*/

  const handleCrate = () => {
    setOpen(true);
    setEdit(false);
    seteditcall("");
    setstdField((prevState) => ({
      ...prevState,
      siteName: selectedSite ? selectedSite.siteName : "",
    }));
  };
  const handleyear = async (val) => {
    setExpectData([]);
    console.log(site);
    let siteIdFilter = siteName?.filter((data) => {
      console.log(data);
      return data.siteName === site;
    });
    const idd = siteIdFilter[0]?.siteId;
    console.log(siteIdFilter);

    console.log(val);
    const datavalue = await fetchCurrencyData(idd, val);
    setExpectData(datavalue);
  };
  const handleExpect = (value, event) => {
    if (value === null) {
      setsiteExpect("");
    } else {
      setsiteExpect(value);
    }
  };
  console.log(siteExpect);

  const handleClose = () => {
    setSelectedMonths([]);
    setSelectedFileData(null);
    setstdField(initalState);
    seteditcall("");
    setCategory("");
    setactiveFlag("");
    setModalvalue(true);
    setEdit(false);
    setOpen(false);
    setReplace(null);
    setRowdata([])
    setClickCount(1);
    setExpectFields([initalState]);
  };
  // useEffect(() => {
  //   if (edit && editFilteredData.length > 0) {
  //     const months = editFilteredData.map((data) => data.month.toUpperCase());
  //     setSelectedMonths(months);
  //   }
  // }, [edit, editFilteredData]);

  const handleEditOpen = async (errorId) => {
    let filterederrorId = Object.keys(initalState).reduce((acc, key) => {
      if (errorId.hasOwnProperty(key)) {
        acc[key] = errorId[key];
      }
      return acc;
    }, {});
    seteditcall(errorId);
    const siteObject = siteName?.find((site) => site.siteId === errorId.siteId);
    setstdField({
      ...filterederrorId,
      siteName: siteObject ? siteObject.siteName : "",
    });
    // setSelectedYear(errorId.currentYear);
    const allSiteYearData = await fetchCurrencyData(
      errorId.siteId,
      errorId.currentYear
    );
    setEditFilteredData(allSiteYearData);
    setCategory(errorId.equipmentCategory);
    setEdit(true);
    setOpen(true);

    setExpectFields(
      allSiteYearData.map((data) => ({
        id: data.id,
        gridCost: data.gridCost,
        dgCost: data.dgCost,
        pvCost: data.pvCost,
        effectiveFromDate: data.effectiveFromDate,
        effectiveToDate: data.effectiveToDate,
        currencyUnits: data.currencyUnits,
      }))
    );

    console.log("Setting site name to:", siteObject ? siteObject.siteName : "");
    console.log("Fetched data for editing:", allSiteYearData);
  };
  const handleDownloadTable = async (val) => {
    const status = await Download(val, "Currency");
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };
  const headCells = [
    {
      label: "Cost of grid",
      id: "gridCost",
      view: true,
      default: true,
    },

    {
      label: "Cost of DG",
      id: "dgCost",
      view: true,
      default: true,
    },
    {
      // label: "Per units price  (kWh)",
      label: "Cost of PV",
      id: "pvCost",
      view: true,
      default: true,
    },
    {
      label: "Effective from ",
      id: "effectiveFromDate",
      view: true,
      default: true,
    },
    {
      label: "Effective to",
      id: "effectiveToDate",
      view: true,
      default: true,
    },
    {
      label: "Currency",
      id: "currencyUnits",
      view: true,
      default: true,
    },

    {
      label: "Action",
      id: "action",
      view: true,
      default: true,
    },
  ];
  const tableValues = expectedata.filter((row) => {
    if (siteTab !== "") {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case "all":
        setSiteTab("");
        break;
      case "Active":
        setSiteTab(1);
        break;
      case "Inactive":
        setSiteTab(0);
        break;
      default:
        setSiteTab("");
    }
  };
  useEffect(() => {
    const SiteTypeIdFilter = categoryvalue?.filter((data) => {
      return data.equipmentCategory == category;
    });
    setSiteTypeIdFilter(SiteTypeIdFilter);
  }, [category, categoryvalue, stdField.status]);
  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Currency", path: "expecteddata" },
  ];
  const [selectedSite, setSelectedSite] = useState(null);
  const [Valuedata, setValuedata] = useState();
  const [site, setsite] = useState("");
  console.log(site);
  const handleSiteFilterValue = (val) => {
    setsite(val);
    if (val) {
      const siteIdFilter = siteName?.find((data) => data.siteName === val);
      console.log("Filtered site:", siteIdFilter);
      if (siteIdFilter) {
        setValuedata(siteIdFilter.siteName);
        setSelectedSite(siteIdFilter);
        getExpectData(siteIdFilter.siteId, selectedYear);
      }
    } else {
      setValuedata(null);
      setSelectedSite(null);
      setSitestatus([]);
      setExpectData([]);
    }
  };

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [filteredData, setFilteredData] = useState(tableValues);
  const handleYearFilterValue = (year) => {
    setSelectedYear(year);
    const newFilteredData = tableValues.filter(
      (item) =>
        item.year === year &&
        item.siteId === (selectedSite ? selectedSite.siteId : null)
    );
    setFilteredData(newFilteredData);
  };
  const isButtonDisabled = expectFields.some(
    (row) =>
      (!row.dgCost && !row.pvCost && !row.gridCost) || // Check all three cost fields
      !row.effectiveToDate || // Check for date fields
      !row.effectiveFromDate ||
      !row.currencyUnits
  );


  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <Formik
            key={edit ? "edit" : "create"}
            enableReinitialize={true}
            initialValues={expectFields}
            validationSchema={ErrorConfigSchema}
            onSubmit={async (values, { resetForm }) => {
              console.log("Formik onSubmit triggered");
              let submitValue = values;
              if (edit) {
                const result = await handleUpdate(submitValue);
                if (result === 1) {
                  resetForm();
                  handleClose();
                }
              } else {
                const result = await handleSave(submitValue);
                if (result === 1) {
                  resetForm();
                }
              }
            }}
          >
            {({ values, handleChange, handleSubmit, handleReset }) => (
              <Form>
                <div>
                  {/* ---------   -----save andupdate---- popup----------- */}

                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >
                    <Fade in={open}>
                      <Box
                        className={"styleModalExpectedData"}
                        sx={{
                          border: "none",
                          outline: "none",
                        }}
                      >
                        <Card
                          sx={{
                            borderRadius: "20px",
                            height: "50vh",
                            width: "80%",
                            // height: "fit-content",
                          }}
                        >
                          <CardHeader
                            sx={{
                              // padding: "20px 20px 20px 20px",
                              background: CheckForLight()
                                ? "rgb(246, 248, 252)"
                                : "#4f4f4f",
                            }}
                            action={
                              <Stack spacing={2} direction="row">
                                {edit ? (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: "25px",
                                      textTransform: "capitalize",
                                    }}
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? "Updating..." : "Update"}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: "25px",
                                      textTransform: "capitalize",
                                    }}
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                    disabled={isButtonDisabled}
                                  >
                                    {toBackend ? "Saving..." : "Save"}
                                  </Button>
                                )}
                                <Button
                                  variant="contained"
                                  disabled={toBackend}
                                  style={{
                                    borderRadius: "25px",
                                    textTransform: "capitalize",
                                  }}
                                  onClick={() => {
                                    handleClose();
                                    handleReset();
                                  }}
                                  startIcon={<CancelIcon />}
                                >
                                  Cancel
                                </Button>
                              </Stack>
                            }
                            title={edit ? "Update Currency" : "Add Generation"}
                          />
                          <Divider style={{ borderColor: "#888" }} />
                          <div className="modelGrid">
                            <Card
                              sx={{
                                overflowY: "visible",
                                marginLeft: "-1vw",
                                marginTop: "-2%",
                                scrollBehavior: "smooth",
                                scrollbarGutter: "stable",
                                scrollbarWidth: "thin",
                                "&::-webkit-scrollbar": {
                                  width: "0.4em",
                                },
                                "&::-webkit-scrollbar-track": {
                                  background: "#f1f1f1",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: "#888",
                                  borderRadius: "20px",
                                },
                                "&::-webkit-scrollbar-thumb:hover": {
                                  background: "#555",
                                },
                              }}
                              elevation={0}
                            >
                              <CardContent sx={{ marginLeft: "1%" }}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <Grid container item xs={3} sm={5.2}>
                                      <Grid item xs={12} md={6}>
                                        <div>
                                          <Typography style={typoStyle}>
                                            Site Name
                                          </Typography>
                                          <Autocomplete
                                            size="small"
                                            disablePortal
                                            disableClearable
                                            id="combo-box-demo"
                                            disabled
                                            options={siteName?.map(
                                              (option) => option.siteName
                                            )}
                                            onChange={(event, newValue) =>
                                              handleSite(newValue)
                                            }
                                            value={stdField.siteName || null}
                                            sx={{ width: "12vw" }}
                                            ListboxProps={{
                                              style: { maxHeight: "200px" },
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="IFP"
                                                disabled
                                              />
                                            )}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      overflowY: "auto",
                                      height: "290px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        position: "relative",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                        }}
                                      >
         {Object.entries(headerMapping).map(([header, backendKey], headerIndex) => (
  <div
    key={headerIndex}
    style={{
      flex: "0 0 auto",
      width: "165px",
      marginRight: headerIndex === 6 ? "5vw" : "10px",
    }}
  >
    <Typography style={typoStyle}>{header}</Typography>

    {expectFields.map((field, fieldIndex) => {
      // Check if the index is in the rowdata array
      const isIndexInRowData = rowdata.includes(fieldIndex);
      
      // Check if the effectiveFromDate has been selected
      const isEffectiveFromDateSelected = field.effectiveFromDate;
      const effectiveFromDate = field.effectiveFromDate ? dayjs(field.effectiveFromDate, "YYYY-MM-DD") : null;
      const isEffectiveToDateDisabled = effectiveFromDate ? (dayjs() > effectiveFromDate) : false; // Disable if the date is before today's date

      return backendKey === "currencyUnits" ? (
        // Render Currency Select Dropdown
        <div
          key={fieldIndex}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Select
            value={field.currencyUnits || ""}
            onChange={(event) => handleCurrencyUnitChange(event, fieldIndex)}
            displayEmpty
            variant="outlined"
            size="small"
            sx={{
              width: "20vw",}}
          >
            {currencyOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </div>
      ) : backendKey === "effectiveFromDate" || backendKey === "effectiveToDate" ? (
        // Render DatePicker for "Effective from" and "Effective to"
        <LocalizationProvider dateAdapter={AdapterDayjs} key={fieldIndex}>
          <DatePicker
            value={field[backendKey] ? dayjs(field[backendKey], "YYYY-MM-DD") : null}
            onChange={(newValue) => handleDateChange(newValue, fieldIndex, backendKey)}
            disabled={backendKey === "effectiveToDate" && !isEffectiveFromDateSelected} // Disable effectiveToDate if effectiveFromDate is not selected
            minDate={backendKey === "effectiveToDate" && effectiveFromDate ? effectiveFromDate : null} // Disable dates before the effectiveFromDate
            slotProps={{
              textField: {
                size: "small",
                sx: {
                  marginBottom: "10px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: isIndexInRowData ? "red" : "none", // Change border color if index is in rowdata
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: isIndexInRowData ? "red" : "none", // Change border color on hover
                  },
                },
              },
            }}
            sx={{ marginBottom: "10px" }}
            format="DD/MM/YYYY"
          />
        </LocalizationProvider>
      ) : (
        <TextField
          key={fieldIndex}
          size="small"
          name={backendKey}
          value={field[backendKey] || ""}
          placeholder="E.g: 23.12"
          onChange={(event) => handleChangeData(event, fieldIndex)}
          style={{
            ...fieldStyle,
            marginBottom: "10px",
            // Change border color if index is in rowdata
          }}
        />
      );
    })}
  </div>
))}


                                      </div>
                                      <div
                                        style={{
                                          marginBottom: "30px",
                                        }}
                                      >
                                        {!edit && (
                                          <>
                                            <div>
                                              <IconButton
                                                onClick={handleAddButtonClick}
                                                disabled={
                                                  expectFields.length >= 12
                                                }
                                                style={{
                                                  marginBottom: "20px",
                                                  marginTop: "30%",
                                                }}
                                              >
                                                <AddIcon
                                                  style={{
                                                    marginTop: "1%",
                                                    color:
                                                      expectFields.length >= 12
                                                        ? "grey"
                                                        : CheckForLight()
                                                        ? "black"
                                                        : "white",
                                                  }}
                                                />
                                              </IconButton>
                                            </div>
                                            <div>
                                              {expectFields.length > 1 && (
                                                <IconButton
                                                  onClick={() =>
                                                    handleRemoveButtonClick(
                                                      expectFields.length - 1
                                                    )
                                                  }
                                                >
                                                  <CloseIcon
                                                    style={{
                                                      color: CheckForLight()
                                                        ? "black"
                                                        : "white",
                                                    }}
                                                  />
                                                </IconButton>
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    {/* </div> */}
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </div>
                        </Card>
                      </Box>
                    </Fade>
                  </Modal>
                </div>
              </Form>
            )}
          </Formik>
          {/* ---------------pass props value----------- */}
          <div style={{ marginTop: "-0.5%" }}>
            <CustomSnackbar
              open={snack.open}
              onClose={handleSnackClose}
              autoHideDuration={5000}
              severity={snack.severity}
              message={snack.message}
            />
            <TableTemplate
              PageName={"Currency"}
              // ExpectedYear={"ExpectedData Year"}
              // uploadButton={true}
              addButton={"Currency"}
              header={headCells}
              rowsValue={tableValues}
              siteExpect={siteExpect}
              userRole={errorConfiguration[0]}
              handleChange={handleChangeTab}
              handleSiteFilterChangeExData={handleSiteFilterValue}
              handleYearChange={handleYearFilterValue}
              handleDownloadTemplate={() =>
                downloadExcelTemplate("Expected Data", isOEM ? true : false)
              }
              handleyearPopupOpen={(e) => handleyear(e)}
              handleUploadProp={(e) => handleFileUpload(e)}
              handleAddPopupOpen={(val) => handleCrate(val)}
              handleEditPopupOpen={(val) => handleEditOpen(val)}
              handleDownloadExcel={(val) => {
                handleDownloadTable(val);
              }}
              handleDelete={(val) => {
                handleDelete(val);
              }}
              paths={paths}
              handleSiteChangeExpect={(val) => handleExpect(val)}
              sitevalueEx={Valuedata}
              siteNameListEx={siteName}
              fallbackText={"No expected data has been created yet"}
            />
          </div>
        </div>
      )}
    </div>
  );
}