import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Autocomplete,
  Menu,
  MenuItem,
  IconButton,
  InputBase,
  InputAdornment,
  Modal,
  Tooltip,
  Fade,
  Card,
  Divider,
  CardHeader,
  Stack,
  CircularProgress,
} from "@mui/material";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { dgreview } from "../util/TextField";
import FadeLoader from "react-spinners/FadeLoader";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { CheckForLight } from "../util/common/CommanFunction";
import dayjs from "dayjs";
import { ErrorMessage, Field, Form, Formik } from "formik";
// ========Modal================
import { styled } from "@mui/material/styles";
/*------------api call-----------------*/
import {
  postDgrSave,
  postDgrUpdate,
  fetchSiteandDateGet,
  fetchTodaySiteandDateGet,
  fetchSitestartTime,
  getcategory,
} from "../../Api/DgrApi";
import { Getdropdownvalue } from "../../Api/MapsiteApi";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TableTemplate from "../../Template/TableTemplate";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CustomBreadcrumbs from "../util/components/CustomBread";
import errorMsg from "../util/errorMessage.json";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { format, secondsInDay } from "date-fns";
import { EquipmentType } from "../util/TextField";
import {
  Statusdropdown,
  fetchInstalltionCapacity,
  Equipmentlistinverter,
} from "../../Api/CommonApi";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomSnackbar from "../util/components/CustomSnackbar";
import { set } from "date-fns";
import { Group } from "@mui/icons-material";
export default function Equipmenthome({ dailyGeneration }) {
  const [SiteEquipment, setSiteEquipment] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [menuOpenn, setOpennMenu] = useState(false);
  const [isAnyRemarkFilled, setIsAnyRemarkFilled] = useState(false);
  const [equipment, setEquipment] = useState("");
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [openfilter, setopenfilter] = useState(false);
  const [isApplyEnabled, setIsApplyEnabled] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [valuee, setValuee] = useState(true);
  const [edit, setEdit] = useState(false);
  const [EquipmetId, setEquipmentId] = useState([]);
  const [toBackend, setToBackend] = useState(false);
  const [todayApi, setTodayApi] = useState(true);
  const [yesterdayApi, setYesterdayApi] = useState(false);
  const [loading, setLoading] = useState(true);
  const [textt, setTextFieldd] = useState([]);
  const [startTime, setStartTime] = useState([]);
  const [datavalue, setDatavalue] = useState([]);
const [category, setCategory]=useState([])

console.log(category);

  const [gridData, setGridData] = useState([]);
  const [siteIdName, setSiteId] = useState([]);
  const [sitestatus, setSitestatus] = useState("");
  const [remarkdata, setRemarkdata] = useState();
  const [editcall, seteditcall] = useState();
  const [unitsIC, setUnitsIC] = useState([]);
  const [text, setTextField] = useState([]);
  const initialState = {
    dgrDate: "",
    downtime: "",
    startTime: "",
    endTime: "",
    downTimeType: "",
    siteName: "",
    downTimeMinutes: "",
  };
  const [stdField, setStdField] = useState(initialState);
  console.log(remarkdata);

  console.log(stdField[0]?.downtime);
  const [statusDrop, setStatusDrop] = useState([]);
  const initialSnack = { open: false, severity: "", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  console.log(siteIdName);
  const extractDate = (utcTime) => {
    if (!utcTime) return "";
    const datePart = utcTime.substring(0, 10);
    const [year, month, day] = datePart.split("-");
    return `${parseInt(day)}/${parseInt(month)}/${year}`;
  };

  /*----------------------------Fields-------------------------------*/
  const initialFieldSet = {
    equipmentName: "",
    equipmentId: "",
    EquipmentName: "",
    downTimeId: "",
    dgrDate: "",
    downtime: "",
    StartTime: "",
    EndTime: "",
    DownTimeType: "",
    DownTimeMinutes: "",
    invDownTimes: "",
    gridDownTimes: "",
    DownTimeDate: "",
    remarks: "",
    siteId: "",
    SiteName: "",
    Startsecond: "",
    Endsecond: "",
    downTimeCategoryName:"",
    downTimeCategoryId:"",
    equipmentDownTimeMinutes:"",
  };
  const [fieldSets, setFieldSets] = useState([initialFieldSet]);
  console.log(fieldSets);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [chartAnchor, setChartAnchor] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showResults, setShowResults] = useState(false);
  useEffect(() => {
    if (siteIdName?.length > 0) {
      setSelectedSite(siteIdName[0].siteName);
      fetchTodayData();
    }
  }, [siteIdName]);
  const handleSite = (event, value) => {
    setSelectedSite(value);
    setIsAnyRemarkFilled(false);
    setIsApplyEnabled(true);
  };
  const handleCloseeMenu = () => {
    setOpennMenu(false);
    setChartAnchor(null);
  };

  const headcells = [
    {
      label: "Equipment Name",
      id: "EquipmentName",
      view: true,
      default: true,
    },
    {
      label: "Downtime Type",
      id: "DownTimeType",
      view: true,
    },
    {
      label: "Downtime Category",
      id: "downTimeCategoryName",
      view: true,
    },
    {
      label: "Start Time",
      id: "StartTime",
      view: true,
    },
    {
      label: "End Time",
      id: "EndTime",
      view: true,
    },

    {
      label: "Downtime Minutes",
      id: "DownTimeMinutes",
      view: true,
    },
    {
      label: "Remarks",
      id: " remarks",
      view: true,
    },
  ];
  const handleDateChange = async (date) => {
    setSelectedDate(date);
    setShowResults(false);
    setIsApplyEnabled(true);
    setValuee(false);
    setIsAnyRemarkFilled(false);
  };
  console.log(fieldSets);

  const formatToUTC = (dateString, timeString) => {
    if (!dateString || !timeString) {
      console.error("Invalid input: dateString or timeString is undefined", {
        dateString,
        timeString,
      });
      return null;
    }

    let [time, period] = timeString.split(" ");
    let [hours, minutes, seconds] = time.split(":");

    if (!seconds) {
      seconds = "00";
    }

    hours = parseInt(hours, 10);
    if (period) {
      if (period.toLowerCase() === "pm" && hours !== 12) {
        hours += 12;
      }
      if (period.toLowerCase() === "am" && hours === 12) {
        hours = 0;
      }
    }

    const date = new Date(dateString);
    date.setHours(hours, minutes, seconds);

    return date.toISOString();
  };

  useEffect(() => {
    const filteredFieldSets = fieldSets.filter(
      (fieldSet) => fieldSet.flog === "Yes"
    );
    setGridData(filteredFieldSets);
  }, [fieldSets]);

  const generatePDF = () => {
    const doc = new jsPDF();
    console.log(uniqueData);
    const filteredFieldSets = uniqueData.map(
      (
        {
          EquipmentName,
          DownTimeType,
          StartTime,
          EndTime,
          DownTimeMinutes,
          remarks,
        },
        index
      ) => [
        index + 1,
        EquipmentName,
        DownTimeType,
        StartTime,
        EndTime,
        DownTimeMinutes,

        remarks,
      ]
    );
    const set = uniqueData
      .map((item) => item.DownTimeMinutes)
      .sort((a, b) => a - b);
    const totalSum = set.reduce((acc, value) => acc + value, 0);

    const siteName = fieldSets[0]?.SiteName || "Unknown Site";
    const endTimeValue =
      convertUTCtoIST(startTime?.plantEndtime) || "Unknown Start Time";
    const startTimeValue =
      convertUTCtoIST(startTime?.plantStarttime) || "Unknown End Time";
    const date =
      startTime?.plantEndtime?.length === 0
        ? startTime?.plantStarttime
        : startTime?.plantEndtime || "Unknown End Time";
    const datee = extractDate(date);

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const marginLeft = 14;
    const marginTop = 20;

    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text(` ${siteName} - DownTime Report `, pageWidth / 2, marginTop, {
      align: "center",
    });

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(`Report Date: ${datee}`, marginLeft, marginTop + 20);
    doc.text(`Plant Start Time: ${startTimeValue}`, marginLeft, marginTop + 30);
    doc.text(`Plant End Time: ${endTimeValue}`, marginLeft, marginTop + 40);
    doc.text(
      ` Total DownTime(in minutes): ${totalSum}`,
      marginLeft,
      marginTop + 50
    );

    const tableColumn = [
      "S.No",
      "Equipment Name",
      "DownTime Type",
      "Start Time",
      "End Time",
      "Down Time(in minutes)",
    ];
    const rowHeight = 10;
    const maxRowsFirstPage = 25;
    const maxRowsOtherPages = 30;
    const totalRows = filteredFieldSets.length;

    let currentPage = 1;
    let rowsToShow;
    let startY = marginTop + 60;

    for (let i = 0; i < totalRows; i += rowsToShow.length) {
      if (currentPage === 1) {
        rowsToShow = filteredFieldSets.slice(i, i + maxRowsFirstPage);
      } else {
        rowsToShow = filteredFieldSets.slice(i, i + maxRowsOtherPages);
      }

      if (currentPage > 1) {
        doc.addPage();
        startY = 10;
      }
      doc.autoTable({
        head: [tableColumn],
        body: rowsToShow,
        startY: startY,
        margin: { left: 14, right: 14 },
        theme: "grid",
        headStyles: {
          fillColor: ["#316ca6"],
          textColor: [255, 255, 255],
          fontSize: 12,
          fontStyle: "bold",
        },
        bodyStyles: {
          fillColor: [240, 240, 240],
          textColor: [0, 0, 0],
          fontSize: 10,
        },
        pageBreak: "auto",
      });

      currentPage++;
    }
    doc.save(`${siteName}DownTime.pdf`);
  };

  function getOptions(propName) {
    let option = [];
    switch (propName) {
      case "siteName":
        return (option = siteIdName?.map((option) => option.siteName));
      case "equipmentName":
        return (option = EquipmetId?.map((option) => option.displayName));

      default:
    }
    return option;
  }

  const dailychartType = [
    { label: "Excel", value: "excel" },
    { label: "Pdf", value: "pdf" },
  ];
  const handleInputChange = (value,row) => {
    console.log(row,value);
   
    setRemarkdata(value);
    const satisfiedRows = [];
    setFieldSets((prevFieldSets) =>
      prevFieldSets.map((item, itemIndex) => {
        console.log(item);
       
        if (
          row.StartTime === item.StartTime &&
          row.EndTime === item.EndTime &&
          row.DownTimeMinutes === item.DownTimeMinutes &&
          row.DownTimeType === item.DownTimeType &&
          row.equipmentId === item.equipmentId
        ) {
          satisfiedRows.push(item);

          return {
            ...item,
            remarks: value,
            modified: true,
          };
        }

        if (
       
          row.group===item.group&&
          item.flog === "Yes"
        ) {
          satisfiedRows.push(item);

          return {
            ...item,
            remarks: value,
            modified: true,
          };
        }
        return item;
      })
    );
    setIsAnyRemarkFilled(true);
  };

  const handlecategory = (row, value) => {
    const satisfiedRow = [];
   
    setFieldSets((prevFieldSets) =>
      prevFieldSets.map((item) => {
        // Safely filter category
        const categoryNameIdFilter = category?.filter(
          (data) => data.categoryName === value
        );
 
        // Safely access the first filtered categoryId
        const categoryId = categoryNameIdFilter?.[0]?.categoryId;
  console.log(categoryId);
 
        // First condition: Match specific row attributes
        if (
          row.StartTime === item.StartTime &&
          row.EndTime === item.EndTime &&
          row.DownTimeMinutes === item.DownTimeMinutes &&
          row.DownTimeType === item.DownTimeType &&
          row.equipmentId === item.equipmentId
        ) {
          satisfiedRow.push(item);
          return {
            ...item,
            downTimeCategoryId: categoryId,
            modified: true,
          };
        }
 
        // Second condition: Match group and flag
        if (row.group === item.group && item.flog === "Yes") {
          satisfiedRow.push(item);
          return {
            ...item,
            downTimeCategoryId: categoryId,
            modified: true,
          };
        }
 
        // Return unchanged item if no conditions match
        return item;
      })
    );
 
    setIsAnyRemarkFilled(true);
  };
 

  /*------------------------- Api --------------------------------------------*/
  useEffect(() => {
    fetchData();
    fetchStatus();
    fetchUnits();
    getsitelist();
    fetchCategoey()
  }, []);
  const fetchTodayData = async (id, idd) => {
    
    setLoading(true);
    console.log(id, idd);
    try {
      const today = new Date().toISOString().split("T")[0];
      const siteId = siteIdName[0]?.siteId;
      let value = siteId !== undefined ? siteId : id;
      console.log(value, today);
      const responsee = await fetchSitestartTime(value, today);
      setStartTime(responsee);
      const data = await fetchTodaySiteandDateGet(value, today);
      setDatavalue(data);
      const matchedSite = siteIdName.find((site) => site.siteId === value);
      const newFieldSets = data.map((item, index) => {
        const timePart = item.startTime.split("T")[1];
        const millisecondsPart = timePart.split(":")[2];
        const timePartt = item.endTime.split("T")[1];
        const millisecondsPartt = timePartt.split(":")[2];
        return {
          uniqueId: index,
          downTimeId: item.downTimeId,
          equipmentId: item.equipmentId,
          SiteName: idd,
          EquipmentName: item.displayName,
          DownTimeType: item.downTimeType,
          StartTime: convertUTCtoIST(item.startTime),
          EndTime: convertUTCtoIST(item.endTime),
          DownTimeMinutes: parseInt(item.downTimeMinutes),
          Startsecond: millisecondsPart,
          Endsecond: millisecondsPartt,
          flog: item.gridFailureFlag,
          DownTimeDate: item.downTimeDate,
          equipmentDownTimeMinutes:item.equipmentDownTimeMinutes,
          downTimeCategoryName:item.downTimeCategoryName,
          remarks: item.remarks || "",
            downTimeCategoryId:item. downTimeCategoryId
        };
      });

      console.log(newFieldSets);
      setFieldSets(newFieldSets);
      setShowResults(true);
      setSelectedDate(dayjs(today));
      setIsApplyEnabled(false);
      setValuee(true);
    } catch (error) {
      // handle error
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    let data = EquipmentType();
    setTextField(data);
  }, []);
  useEffect(() => {
    console.log("Field Sets updated:", fieldSets);
  }, [fieldSets]);
  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === "object" && data !== null) {
      setStatusDrop([data]);
    }
  };
  const fetchUnits = async () => {
    try {
      let data = await fetchInstalltionCapacity();
      console.log(data, "dataOutside");
      setUnitsIC(data);
    } catch (error) {
      console.error(error);
    }
  };
  const getsitelist = async () => {
    try {
      let data = await Getdropdownvalue();
      if (Array.isArray(data)) {
        setSiteId(data);
        setLoading(false);
      } else if (typeof data === "object" && data !== null) {
        setSiteId([data]);
      }
      setLoading(false);
      await fetchTodayData(data[0]?.siteId, data[0]?.siteName);
    } catch (e) {
      console.error(e, "errrrrror");
    }
  };
 
  const getEquipmentCall = async (Id) => {
    let siteIdFilter = siteIdName?.filter((data) => data.siteName === Id);
    const id = siteIdFilter[0]?.siteId;
    console.log(id);

    try {
      let data = await Equipmentlistinverter(id);
      setEquipmentId(data);
    } catch (e) {
      console.error(e);
    }
  };
  const handleApply = async () => {
    const formattedDate = selectedDate.format("YYYY-MM-DD");
    await fetchData(selectedSite, formattedDate);
    setIsApplyEnabled(false);
    setLoading(true);
    try {
      const siteId = siteIdName.find((s) => s.siteName === sitestatus)?.siteId;
      if (!siteId) {
        throw new Error("Site ID not found");
      }
      setValuee(false);
      setShowResults(true);
      setIsAnyRemarkFilled(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  console.log(fieldSets);

  /*------------------------- handleSave ---------------------------*/

  const handleUpdate = async () => {
    console.log(selectedDate.toISOString().split("T")[0]);
    if (!selectedDate) {
      setSnack({
        open: true,
        severity: "error",
        message: "Please select a date before updating.",
      });
      return;
    }
 
    let siteIdFilter = siteIdName?.filter(
      (data) => data.siteName === selectedSite
    );
 
    let datevalue = selectedDate.toISOString().split("T")[0];
    let data = fieldSets
      .filter((fieldSet) => fieldSet.modified)
      .map((fieldSet, index) => {
        const matchedSite = siteIdName.find(
          (site) => site.siteId === fieldSet.siteId
        );
 
        // Helper function to adjust time by -5:30 hours
        const adjustTime = (time, seconds) => {
          let date = new Date(`${datevalue}T${time}:${seconds}`);
          // Adjust time by -5:30
          date.setHours(date.getHours() - 5);
          date.setMinutes(date.getMinutes() - 30);
          return date.toISOString(); // Convert back to ISO string format
        };
 
        let startTimeFormatted = adjustTime(fieldSet.StartTime, fieldSet.Startsecond);
        let endTimeFormatted = adjustTime(fieldSet.EndTime, fieldSet.Endsecond);
 console.log(datevalue);
 
        let record = {
          uniqueId: index,
          siteId: siteIdFilter[0]?.siteId,
          equipmentId: fieldSet.equipmentId,
          siteName: matchedSite ? matchedSite.SiteName : "",
          displayName: fieldSet.displayName,
          downTimeId: fieldSet.downTimeId,
          downTimeType: fieldSet.DownTimeType,
          updatedDate: new Date().toISOString(),
          downTimeDate: datevalue,
          startTime: startTimeFormatted,
          endTime: endTimeFormatted,
          equipmentDownTimeMinutes:fieldSet.equipmentDownTimeMinutes,
          downTimeCategoryName:fieldSet.downTimeCategoryId,
          downTimeMinutes: parseFloat(fieldSet.DownTimeMinutes),
          remarks: fieldSet.remarks || "",
          downTimeCategoryId:fieldSet. downTimeCategoryId,
          updatedBy: Number(sessionStorage.getItem("id")),
        };

        Object.keys(record).forEach((key) => {
          if (record[key] === null || record[key] === undefined) {
            delete record[key];
          }
        });
 
        return record;
      });
 
    console.log(data);
 
    try {
      const updateData = data.filter(
        (item) => item.downTimeId || (item.startTime && item.equipmentId)
      );
      console.log(updateData);
 
       const response = await postDgrUpdate(updateData);
    
       
      if ( response.status === 200) {
        console.log(response.data);
      
        
     
        handleClose();
      }
 
      setIsAnyRemarkFilled(false);
      setSnack({
        open: true,
        severity: "success",
        message: "Update successful!",
      });
      const site =siteIdName?.filter((site)=>site.siteId===response.data[0]?.siteId)
      console.log(site[0]?.siteName);
      fetchData(site[0]?.siteName,response.data[0]?.downTimeDate);
    } catch (error) {
      console.error("Error updating data:", error);
      setSnack({
        open: true,
        severity: "error",
        message: "Error updating data. Please try again.",
      });
    }
  };
 
 
console.log(fieldSets);

  const filteredData = fieldSets
  console.log(filteredData);

  const uniqueData = filteredData.reduce((acc, current) => {
    // Check if the current entry has flog set to "yes"
    if (current.flog === "Yes") {
      // Check for existence of a similar entry in acc
      const exists = acc.some(
        (item) =>
     
          item.group === current.group &&
          item.flog === "Yes"
      );
      if (!exists) {
        acc.push({
          ...current,
          EquipmentName: "All Equipment",
        });
      }
    } else {
      acc.push(current);
    }

    return acc;
  }, []);
 

 


  const tableValues = uniqueData.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchInput.toLowerCase())
    )
  );

  /*-------------------------Table get call --------------------------------------------*/

  const fetchData = async (site, date) => {
    console.log(site, date);
    
    setLoading(true);
    setFieldSets([]);
    try {
      const siteId = siteIdName.find((s) => s.siteName === site)?.siteId;
      if (!siteId) {
        throw new Error("Site ID not found");
      }
      let data;

      data = await fetchSiteandDateGet(siteId, date);
      setDatavalue(data);
      const responsee = await fetchSitestartTime(siteId, date);
      setStartTime(responsee);
      setYesterdayApi(true);
      setTodayApi(false);

      const newFieldSets = processData(data);
      console.log(newFieldSets);

      setFieldSets(newFieldSets);
      setShowResults(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchCategoey = async () => {

    try {
     

     const  data = await getcategory();
     console.log(data);
     
     setCategory(data)
    } catch (error) {
      console.error("Error fetching data:", error);
      setCategory()
    } finally {
      setLoading(false);
    }
  };
  const handleDropdownChange = (event, value, name) => {
    console.log(value, name);
    if (name === "siteName") {
      getEquipmentCall(value);
      setEquipmentId([]);
    }
    setStdField({ ...stdField, [name]: value });
    setEquipmentId([]);
  };

  const processData = (data) => {
    if (Array.isArray(data)) {
      return data.map((item, index) => {
        const matchedSite = siteIdName.find(
          (site) => site.siteId === item.siteId
        );
        const timePart = item.startTime.split("T")[1];
        const millisecondsPart = timePart.split(":")[2];
        const timePartt = item.endTime.split("T")[1];
        const millisecondsPartt = timePartt.split(":")[2];
        return {
          uniqueId: index,
          downTimeId: item.downTimeId,
          equipmentId: item.equipmentId,
          SiteName: matchedSite ? matchedSite.siteName : "",
          EquipmentName: item.displayName,
          DownTimeType: item.downTimeType,
          StartTime: convertUTCtoIST(item.startTime),
          EndTime: convertUTCtoIST(item.endTime),
          DownTimeMinutes: parseInt(item.downTimeMinutes),
          DownTimeDate: item.downTimeDate,
          flog: item.gridFailureFlag,
          downTimeCategoryName:item.downTimeCategoryName,
          equipmentDownTimeMinutes:item.equipmentDownTimeMinutes,
          group:item.group,
          Startsecond: millisecondsPart,
          downTimeCategoryId:item. downTimeCategoryId,
          Endsecond: millisecondsPartt,
          remarks: item.remarks || "",
        };
      });
    } else {
      // Today's data
      const invFieldSets = data.invDownTimes.map((item) => ({
        equipmentId: item.equipmentId,
        EquipmentName: item.displayName,
        DownTimeDate: item.downTimeDate,
        downTimeCategoryName:item.downTimeCategoryName,
        InvDownTimes: item.downtimePeriods.map(
          (period) =>
            `Start: ${convertUTCtoIST(
              period.startTime
            )}, End: ${convertUTCtoIST(period.endTime)}, Minutes: ${
              period.downtimeMinutes
            }`
        ),
        group:item.group,
        equipmentDownTimeMinutes:item.equipmentDownTimeMinutes,
        DownTimeMinutes: item.equipmentDowntimeMinutes,
        remarks: "",
      }));
      const gridFieldSets = data.gridDownTimes.map((item) => ({
        equipmentId: item.equipmentId,
        EquipmentName: item.displayName,
        downTimeCategoryName:item.downTimeCategoryName,
        equipmentDownTimeMinutes:item.equipmentDownTimeMinutes,
        gridDownTimes: item.downtimePeriods.map(
          (period) =>
            `Start: ${convertUTCtoIST(period.startTime)}, End: ${
              period.endTime
            }, Minutes: ${convertUTCtoIST(period.downtimeMinutes)}`
        ),
        DownTimeMinutes: item.equipmentDowntimeMinutes,
        DownTimeDate: item.downTimeDate,
        downTimeCategoryId:item. downTimeCategoryId,
        group:item.group,
        remarks: "",
      }));
      return [...invFieldSets, ...gridFieldSets];
    }
  };
  /*---------------------------------- New Equipment Call -----------------------*/
  const handleClose = () => {
    setOpen(false);
    seteditcall([]);
    setEdit(false);
    setEquipment("");
  };
  const Item = styled(Paper)(({ theme }) => ({}));
  const Total = SiteEquipment.length;
  const Active = SiteEquipment.filter((site) => site.status == 1).length;
  const Inactive = SiteEquipment.filter((site) => site.status == 0).length;
  console.log(Active);
  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Down Time", path: "dgr" },
  ];
  console.log(yesterdayApi);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  const handleMenuItemClick = async (value, flag) => {
    console.log(value, flag);
    if (value === "excel") {
      handleDownload();
    } else if (value === "pdf") {
      generatePDF();
    }
    setOpennMenu(false);
  };
  const handleClickMenuItem = (event, flag) => {
    console.log(event, flag);

    setOpennMenu(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClickFilter = () => {
    setopenfilter(true);
  };

  const handleTxtChange = (event, name) => {
    console.log(name, "fghj");

    setStdField({ ...stdField, [name]: event.target.value });
  };
  const handlefilterClose = () => {
    setopenfilter(false);
  };
  useEffect(() => {
    let data = dgreview();
    setTextFieldd(data);
  }, []);
  const handleDownload = async () => {
    const filteredFieldSets = uniqueData.map(
      ({ uniqueId, downTimeId, equipmentId, flog,downTimeCategoryId,group,Startsecond,Endsecond, ...rest }) => rest
    );
    const worksheet = XLSX.utils.json_to_sheet(filteredFieldSets);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FieldSets Data");
    XLSX.writeFile(workbook, "downTime.xlsx");
  };
console.log(uniqueData);

  const set = uniqueData
  .map((item) => item.DownTimeMinutes)
  .sort((a, b) => a - b);
const totalSum = set.reduce((acc, value) => acc + value, 0);
const convertUTCtoIST = (utcTime) => {
  if (!utcTime) return "";
  const date = new Date(utcTime);
  if (isNaN(date.getTime())) {
    console.error("Invalid date:", utcTime);
    return "";
  }

  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZone: "Asia/Kolkata",
  });
};
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <CustomSnackbar
            open={snack.open}
            onClose={handleSnackClose}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />
          <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Paper
              elevation={0}
              style={{
                width: "100%",
                borderRadius: "2px 2px 0px 0px",
                userSelect: "none",
                height: "5vh",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid
                container
                width={"100%"}
                direction="row"
                justifyContent="space-between"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <CustomBreadcrumbs
                    paths={paths || []}
                    separatorSize="18px"
                    fontSize="14px"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>

          <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Paper
              elevation={0}
              style={{
                display: "flex",
                width: "100%",
                borderRadius: "2px 2px 0px 0px",
                userSelect: "none",
                marginTop: "1%",
                height: "5vh",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <InputBase
                    size="small"
                    className="cus-search"
                    placeholder={"Search downTime Here..."}
                    startAdornment={
                      <InputAdornment
                        position="start"
                        style={{ width: "40px" }}
                      >
                        <IconButton>
                          <SearchOutlinedIcon style={{ color: "#9EA4AE" }} />
                        </IconButton>
                      </InputAdornment>
                    }
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    style={{
                      width: "240px",
                      height: "30px",
                      backgroundColor: "#F7F8F9",
                      borderRadius: "8px",
                      fontSize: "small",
                      marginLeft: "1vw",
                      marginRight: "5vw",
                      marginTop: "-0.75%",
                      borderColor: "transparent",
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: "1%",
                }}
              >
                <div>
                  {/* <Tooltip title="Download Data" userSelect="none" followCursor>
                      <IconButton
                        disabled={fieldSets.length === 0}
                        onClick={generatePDF}
                        style={{
                          textTransform: 'capitalize',
                          color: 'white',
                          padding: '1',
                          borderRadius: '20px',
                        }}
                      >
                        <SaveAltIcon
                          style={{
                            color: fieldSets.length === 0 ? 'grey' : '#004AFF',
                            fontSize: '20px',
                          }}
                        />
                      </IconButton>
                    </Tooltip> */}

                  <IconButton>
                    <FilterAltOutlinedIcon
                      onClick={handleClickFilter}
                      style={{
                        color: "#004AFF",
                        fontSize: "20px",
                        marginLeft: "2%",
                      }}
                    />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={menuOpenn}
                    onClose={handleCloseeMenu}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    {dailychartType.map((option) => (
                      <MenuItem
                        key={option.value}
                        onClick={(event) => handleMenuItemClick(option.value)}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Menu>

                  <IconButton
                    disabled={fieldSets.length === 0}
                    onClick={(event) => handleClickMenuItem(event, "Time")}
                  >
                    <SaveAltIcon
                      disabled={fieldSets.length === 0}
                      style={{
                        color: fieldSets.length === 0 ? "grey" : "#004AFF",
                        fontSize: "20px",
                        marginLeft: "2%",
                      }}
                    />
                  </IconButton>
                </div>
              </div>
            </Paper>
          </Box>
          <div style={{ marginTop: "0.5%" }}>
            <Box
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Paper
                elevation={0}
                style={{
                  display: "flex",
                  width: "100%",
                  borderRadius: "2px 2px 0px 0px",
                  userSelect: "none",
                  height: "10vh",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "30px",
                  }}
                >
                  <div style={{ marginRight: "5%" }}>
                    <Typography className="modal-typo">Site Name</Typography>
                    <div style={{ marginTop: "5%" }}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={
                          siteIdName.map((option) => option.siteName) || []
                        }
                        value={selectedSite}
                        onChange={handleSite}
                        sx={{ width: "14vw" }}
                        ListboxProps={{
                          style: {
                            maxHeight: "200px",
                          },
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} placeholder="IFP" />
                        )}
                      />
                    </div>
                  </div>

                  <div style={{ marginRight: "5%" }}>
                    <Typography className="modal-typo" sx={{ width: "14vw" }}>
                      Date
                    </Typography>
                    <div style={{ marginTop: "5%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          size="small"
                          sx={{ width: "14vw" }}
                          onChange={handleDateChange}
                          value={selectedDate}
                          format="DD/MM/YYYY"
                          maxDate={dayjs()} // Prevent future dates from being selected
                          slotProps={{ textField: { size: "small" } }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ marginRight: "30px" }}>
                    <Button
                      variant="contained"
                      style={{
                        borderRadius: "25px",
                        textTransform: "capitalize",
                      }}
                      onClick={handleApply}
                      disabled={!isApplyEnabled || selectedSite === null}
                    >
                      Generate
                    </Button>
                  </div>
                  <div style={{ marginRight: "30px" }}>
                    <Button
                      variant="contained"
                      style={{
                        borderRadius: "25px",
                        textTransform: "capitalize",
                      }}
                      onClick={handleUpdate}
                      disabled={
                        !isAnyRemarkFilled
                     
                      }
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Paper>
            </Box>

            <Formik
              key={edit ? "edit" : "add"}
              enableReinitialize={true}
              // initialValues={ticketFields}
              // validationSchema={ticketSchema}
              // onSubmit={async (values, { resetForm }) => {
              //   let submitValue = values;
              //   if (edit) {
              //     const result = await handleUpdateForm(submitValue);

              //     if (result === 1) {
              //       resetForm();
              //     }
              //   } else {
              //     const result = await handleSave(submitValue);

              //     if (result === 1) {
              //       resetForm();
              //     }
              //   }
              // }}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                handleReset,
                resetForm,
              }) => (
                <Form>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openfilter}
                    closeAfterTransition
                  >
                    <Fade in={openfilter}>
                      <Box
                        className={"styleModalSmall"}
                        style={{ height: "40vh" }}
                      >
                        <Card
                          sx={{
                            borderRadius: "20px",

                            height: "fit-content",
                          }}
                        >
                          <CardHeader
                            sx={{
                              padding: "20px 20px 20px 20px",
                              background: CheckForLight()
                                ? "rgb(246, 248, 252)"
                                : "#4f4f4f",
                            }}
                            action={
                              <Stack spacing={2} direction="row">
                                <Button
                                  variant="contained"
                                  style={{
                                    borderRadius: "25px",
                                    textTransform: "capitalize",
                                  }}
                                  startIcon={
                                    toBackend ? (
                                      <CircularProgress
                                        size={20}
                                        color="inherit"
                                      />
                                    ) : (
                                      <SaveIcon />
                                    )
                                  }
                                >
                                  {toBackend ? "Viewing..." : "View"}
                                </Button>

                                <Button
                                  variant="contained"
                                  disabled={toBackend}
                                  style={{
                                    borderRadius: "25px",
                                    textTransform: "capitalize",
                                  }}
                                  onClick={() => {
                                    handlefilterClose();
                                  }}
                                  startIcon={<CancelIcon />}
                                >
                                  Cancel
                                </Button>
                              </Stack>
                            }
                            title={"Customize DownTime Filters"}
                          />
                          <Divider sx={{ borderColor: "#888" }} />
                          <div
                            style={{
                              height: "30vh",
                              padding: "20px",
                            }}
                          >
                            <Grid container spacing={2} columns={16}>
                              {textt?.length > 0
                                ? textt?.map((data, index) => (
                                    <Grid item xs={4}>
                                      <div key={index}>
                                        <></>

                                        {data.type === "textField" ? (
                                          <>
                                            <Typography
                                              variant="body1"
                                              className="modal-typo"
                                              gutterBottom
                                            >
                                              {data.label}
                                            </Typography>
                                            <Field
                                              as={TextField}
                                              disabled={toBackend}
                                              id={`outlined-basic-${index}`}
                                              size="small"
                                              variant="outlined"
                                              name={data.name}
                                              inputProps={{
                                                maxLength: data.length,
                                              }}
                                              placeholder={data.placeholder}
                                              onChange={(e) => {
                                                handleTxtChange(e);
                                              }}
                                              sx={{ width: "12vw" }}
                                            />
                                          </>
                                        ) : data.type === "multidropdown" ? (
                                          <>
                                            <Typography
                                              variant="body1"
                                              className="modal-typo"
                                              gutterBottom
                                            >
                                              {data.label}
                                            </Typography>
                                            <Autocomplete
                                              multiple
                                              id="checkboxes-tags-demo"
                                              options={getOptions(data.name)}
                                              onChange={(event, value) => {
                                                handleDropdownChange(
                                                  event,
                                                  value,
                                                  data.name
                                                );
                                                if (
                                                  value.includes("Select All")
                                                ) {
                                                  const allOptions = getOptions(
                                                    data.name
                                                  ); // No filters applied here
                                                  const newValue =
                                                    value.length ===
                                                    allOptions.length
                                                      ? []
                                                      : allOptions;
                                                  handleDropdownChange(
                                                    event,
                                                    newValue,
                                                    data.name
                                                  );
                                                }
                                              }}
                                              style={{
                                                display: "flex",
                                              }}
                                              disableCloseOnSelect
                                              size="small"
                                              sx={{
                                                width: "12vw",
                                                height: "40px",
                                                marginRight: 8,
                                              }}
                                              defaultValue={
                                                edit && data.name === "siteName"
                                                  ? editcall?.siteName
                                                    ? [editcall.siteName]
                                                    : []
                                                  : edit &&
                                                    data.name ===
                                                      "parameterName"
                                                  ? Array.isArray(
                                                      editcall?.parameterName
                                                    )
                                                    ? editcall.parameterName
                                                    : editcall?.parameterName
                                                    ? [editcall.parameterName]
                                                    : []
                                                  : []
                                              }
                                              renderOption={(
                                                props,
                                                option,
                                                { selected }
                                              ) => {
                                                const { key, ...optionProps } =
                                                  props;
                                                return (
                                                  <li
                                                    key={key}
                                                    {...optionProps}
                                                  >
                                                    <Checkbox
                                                      icon={icon}
                                                      checkedIcon={checkedIcon}
                                                      checked={selected}
                                                    />
                                                    {option}
                                                  </li>
                                                );
                                              }}
                                              renderInput={(params) => {
                                                console.log(params.inputProps);

                                                return (
                                                  <TextField
                                                    {...params}
                                                    size="small"
                                                    placeholder={
                                                      params.inputProps
                                                        .value === 0
                                                        ? data.name
                                                        : ""
                                                    }
                                                    InputProps={{
                                                      ...params.InputProps,
                                                      style: { height: "100%" },
                                                    }}
                                                    onChange={(event) => {
                                                      console.log(
                                                        "Input value on change:",
                                                        event.target.value
                                                      );
                                                      if (
                                                        params.InputProps
                                                          .onChange
                                                      ) {
                                                        params.InputProps.onChange(
                                                          event
                                                        );
                                                      }
                                                    }}
                                                  />
                                                );
                                              }}
                                              renderTags={(value) => {
                                                if (value.length === 0)
                                                  return null;
                                                const truncatedValue =
                                                  value[0].length > 15
                                                    ? value[0].substring(
                                                        0,
                                                        15
                                                      ) + "..."
                                                    : value[0];
                                                return (
                                                  <div
                                                    style={{
                                                      maxWidth: "12vw",
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      whiteSpace: "nowrap",
                                                      padding: "5px",
                                                      marginRight: 5,
                                                    }}
                                                  >
                                                    {truncatedValue}{" "}
                                                    {value.length > 1
                                                      ? `+${value.length - 1}`
                                                      : ""}
                                                  </div>
                                                );
                                              }}
                                              noOptionsText="No options"
                                            />

                                            <ErrorMessage
                                              name={data.name}
                                              component="div"
                                              className="error"
                                              style={{
                                                color: "red",
                                                marginTop: "1%",
                                                textAlign: "left",
                                                marginLeft: "0%",
                                              }}
                                            />
                                          </>
                                        ) : data.type === "dropdown" ? (
                                          <>
                                            <Typography
                                              variant="body1"
                                              className="modal-typo"
                                              gutterBottom
                                            >
                                              {data.label}
                                            </Typography>
                                            <Tooltip>
                                              <Tooltip>
                                                <Autocomplete
                                                  options={getOptions(
                                                    data.name
                                                  )}
                                                  size="small"
                                                  id={`combo-box-demo-${index}`}
                                                  onChange={(event, value) =>
                                                    handleDropdownChange(
                                                      event,
                                                      value,
                                                      data.name
                                                    )
                                                  }
                                                  sx={{ width: "12vw" }}
                                                  ListboxProps={{
                                                    style: {
                                                      maxHeight: "200px",
                                                    },
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      placeholder={
                                                        data.placeholder
                                                      }
                                                    />
                                                  )}
                                                />
                                              </Tooltip>
                                            </Tooltip>
                                          </>
                                        ) : data.type === "date" ? (
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <Typography
                                              variant="body1"
                                              className="modal-typo"
                                              gutterBottom
                                            >
                                              {data.label}
                                            </Typography>
                                            <DatePicker
                                              size="small"
                                              sx={{
                                                width: "12vw",
                                              }}
                                              name={data.name}
                                              // onChange={(e) => {
                                              //   handledatepicchange(
                                              //     e,
                                              //     data.name
                                              //   );
                                              // }}
                                              slotProps={{
                                                textField: {
                                                  size: "small",
                                                },
                                              }}
                                            />
                                          </LocalizationProvider>
                                        ) : data.type === "datee" ? (
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <Typography
                                              variant="body1"
                                              className="modal-typo"
                                              gutterBottom
                                            >
                                              {data.label}
                                            </Typography>

                                            <DatePicker
                                              size="small"
                                              sx={{
                                                width: "12vw",
                                              }}
                                              // shouldDisableDate={(date) =>
                                              //   dayjs(date).isBefore(
                                              //     dayjs(fromDate),
                                              //     'day'
                                              //   )
                                              // }
                                              name={data.name}
                                              // onChange={(e) => {
                                              //   handledatepic(e, data.name);
                                              // }}
                                              slotProps={{
                                                textField: {
                                                  size: "small",
                                                },
                                              }}
                                            />
                                          </LocalizationProvider>
                                        ) : null}
                                      </div>
                                    </Grid>
                                  ))
                                : null}
                            </Grid>
                          </div>
                        </Card>

                        <div>
                          <Grid container spacing={2} columns={32}></Grid>
                        </div>
                      </Box>
                    </Fade>
                  </Modal>
                </Form>
              )}
            </Formik>
            {fieldSets.length > 0 ? (
              <div style={{ marginTop: "0.5%" }}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Paper
                    elevation={0}
                    style={{
                      display: "flex",
                      width: "100%",
                      borderRadius: "2px 2px 0px 0px",
                      userSelect: "none",
                      height: "10vh",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      container
                      spacing={4}
                      columns={24}
                      style={{ padding: "20px 20px 20px 20px" }}
                    >
                      <Grid item xs={8}>
                        <Typography style={{ color: "gray" }}>
                          Plant StartTime
                        </Typography>
                        <Typography>
                          {convertUTCtoIST(startTime?.plantStarttime)}
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography style={{ color: "gray" }}>
                          Plant EndTime
                        </Typography>
                        <Typography>
                          {convertUTCtoIST(startTime?.plantEndtime)}
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography style={{ color: "gray" }}>
                          Total DownTime(in minutes)
                        </Typography>
                        <Typography>{totalSum}</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
              </div>
            ) : (
              <div></div>
            )}

            <div style={{ marginTop: "0.5%" }}>
              <TableTemplate
                PageName={"downTime"}
                header={headcells}
                handlechagevalue={( value,row) =>
                  handleInputChange( value,row)
                }
             downTimeCategory={category}
                handlecategorydownvalue={(row,value)=>{
                  handlecategory(row,value)
                }}
                rowsValue={tableValues}
                userRole={dailyGeneration[0]}
                paths={paths}
                fallbackText={` No down time data  available`}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}