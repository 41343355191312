import React, { useEffect, useRef, useState } from "react";
import "./AnalyticStyle.css";
/*-------------------------- MUi icons -------------------------*/
import DeleteIcon from "@mui/icons-material/Delete";
/*-----------------------------Mui components ---------------------------*/
import {
  Typography,
  Stack,
  Button,
  Grid,
  Paper,
  Snackbar,
  Autocomplete,
  TextField,
  Tooltip,
  Box,
  Card,
  Modal,
  CardContent,
  PaginationItem,
  Divider,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Pagination from "@mui/material/Pagination";
import FadeLoader from "react-spinners/FadeLoader";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
/*----------------------------------- External Components ---------------------*/
import {
  getDataAnalyst,
  WizardDeleteCall,
  postDashboardName,
  fetchParameterComparisonEira,
  fetchDailyGenerationEira,
  fetchSpecificYieldEira,
  fetchStringCurrentEira,
  fetchStringAverage,
  fetchEnergyPerformanceEira,
  fetchPvDgGridEira,
  ActualVsExpected,
  DeemedGenrator,
  GetSetPoint,
} from "../../../Api/DataAnalystApi";
import ApexChart from "./ApexexChart";
import BarChart from "../../Charts/BarChart";
import AreaChart from "../../Charts/AreaChart";
import CombinedChart from "../../Charts/CombainedChart";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { SiteListDetail } from "../../../Api/SiteListApi";
import {
  fetchEquipDropdownBySite,
  fetchParamsDropdownByLogger,
  fetchSiteDropdownByUser,
} from "../../../Api/CommonApi";
import moment from "moment";
import {
  decryptPaddedQuery,
  paddedEncryptQuery,
} from "../../util/security/Cipher";
import {
  CheckForLight,
  removeQuestionMark,
  shouldHighlightRed,
  transformDataForSpecificYield,
} from "../../util/common/CommanFunction";
import { HeatmapChart } from "../../Charts/HeatmapChart";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CachedIcon from "@mui/icons-material/Cached";
/*-------------------- Dummy data---------------------------------*/

const buttonStyle = {
  textTransform: "capitalize",
  boxShadow: "4px 4px 17px rgba(0, 0, 0.50, 0.50)",
  fontWeight: "bold",
  // backgroundColor:'#09001A'
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "20vh",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const timer = [
  { label: "5", value: "5" },
  { label: "10", value: "10" },
  { label: "15", value: "15" },
  { label: "30", value: "30" },
  { label: "45", value: "45" },
  { label: "60", value: "60" },
];

const optionTime = [
  { label: "Today", value: "today" },
  { label: "Last 7 days", value: "last 7 days" },
  { label: "This Month", value: "this month" },
  { label: "Monthly", value: "monthly" },
  { label: "Yearly", value: "yearly" },
];

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Analytics = () => {
  const alertStyle = {
    color: "white", // Text color
    "& .MuiSvgIcon-root": {
      color: "white", // Icon color
    },
  };
  const navigate = useNavigate();
  const location = useLocation();
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  function navigateEncryptedURL(URL) {
    let passSite = { name: site };
    let passParam = new URLSearchParams(passSite).toString();
    let encryptedParams = paddedEncryptQuery(passParam);
    let url = `${URL}?${encryptedParams}`;
    navigate(url);
  }

  const queryParams = new URLSearchParams(`?${queryParam}`);
  const params = {};
  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }
  console.log(params.name);
  const searchParams = new URLSearchParams(location.search);
  const successParam = searchParams.get("success");
  const [openDashboard, setOpenDashboard] = useState(false);
  const [analystData, setAnalystData] = React.useState([]);
  const [deleteId, setDeleteId] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [variantText, setVarient] = useState("");
  const [dashboardName, setDashboardName] = useState("");
  const [textValue, setTextValue] = useState("");
  const [analystWizardData, setAnalystWizard] = React.useState([]);
  const [Total, setTotal] = React.useState(0);
  const [siteListAPI, setSiteList] = React.useState([]);
  const [equipmentListAPI, setEquipmentList] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [siteLoading, setSiteLoading] = useState(true);
  const [reLoading, setReLoading] = useState(true);
  const [siteIdName, setSiteId] = useState([]);
  const [site, setSite] = React.useState("");
  const [equpParamMap, setEqupParamMapValue] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [pageValue, setPageValue] = useState("");
  const [page, setPage] = useState(0);
  const [sitedetail, setsiteDetail] = useState([]);
  const [unitChange, SetUnitChange] = useState(false);
  const [axisDatas, setAxisDatas] = useState([]);
  const [ranges, setRanges] = useState([]);
  const [tableData, setTableData] = useState([]);
  console.log(tableData);
  const [Chart, setChart] = useState();
  console.log(Chart);

  const [chartRanges, setChartRanges] = useState([]);
  const [timePeriods, setTimePeriods] = useState([]);
  const [isWidgetLoading, setIsWidgetLoading] = useState([]);
  const [selectedElement, setSelectedElement] = useState();
  const [anchorTimerEl, setAnchorTimerEl] = useState(null);
  const [menuOpen, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [timerValue, setTimerValue] = useState(5);
  const rowsPerPageOptions = [4, 8];
  const [autoRefresh, setAutoRefresh] = useState(
    sessionStorage.getItem("refresh") === "true"
  );

  const intervalRef = useRef();
  const [openToast, setOpenToast] = React.useState(successParam === "false");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleCloseTimer = () => {
    setAnchorTimerEl(null);
    setSelectedElement();
  };

  const handleClickMenuItem = (event, data) => {
    setAnchorTimerEl(event.currentTarget);
    setSelectedElement(data);
  };

  const handleClickMenu = (event, data) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
    setSelectedElement(data);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
    setAnchorEl(null);
    setSelectedElement();
  };
  useEffect(() => {
    if (autoRefresh) {
      getAnalystCall();
      intervalRef.current = setInterval(() => {
        getAnalystCall();
      }, 300000); // 300,000 ms = 5 minutes
    } else {
      clearInterval(intervalRef.current);
    }
    return () => {
      clearInterval(intervalRef.current); // Clean up on unmount or autoRefresh change
    };
  }, [autoRefresh]);

  const handleAutoRefresh = () => {
    setAutoRefresh(!autoRefresh);
    sessionStorage.setItem("refresh", !autoRefresh);
  };

  const handleSite = async (data, val) => {
    if (val === null) {
      setSite("");
    } else {
      const params = new URLSearchParams();

      let siteIdFilterr = siteIdName?.filter((data) => {
        return data.siteName === val;
      });

      fetchDatasite(siteIdFilterr[0]?.siteId);

      params.set("siteName", val);
      const encryptedParams = paddedEncryptQuery(params.toString());
      navigate(`?${encryptedParams}`);
      setSite(val);
      setPage(0);
      setRowsPerPage(4);
    }
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  useEffect(() => {
    getSiteCall();
  }, []);

  useEffect(() => {
    setAnalystData([]);
    setAnalystWizard([]);
    getAnalystCall();
    // fetchDatasite();
  }, [site]);

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };

  const fetchParams = async (id) => {
    try {
      let data = await fetchParamsDropdownByLogger(id);
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const getAxisData = async (id, params) => {
    let data = await fetchEquipDropdownBySite(id);
    const LoggerIds = data.map((data) => {
      return data.dataLoggerId;
    });
    const equipParamwithUnits = await fetchParams({
      dataloggerId: [...new Set(LoggerIds)],
    });
    console.log(equipParamwithUnits);
    return equipParamwithUnits;
  };

  const getSiteCall = async () => {
    setPageValue(1);
    setSiteLoading(true);
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      if (data.length > 0) {
        setSiteId([...new Set(data)]);
        const siteNameFromSession = queryParam
          ?.split("=")[1]
          ?.replaceAll("+", " ");

        const name = siteNameFromSession
          ? siteNameFromSession
          : data[0].siteName;
        const siteid = siteNameFromSession
          ? data.find((site) => siteNameFromSession === site.siteName)?.siteId
          : data[0].siteId;
        fetchDatasite(siteid);
        setSite(name);
      }
      setSiteLoading(false);
    } catch (e) {
      console.error(e);
    }
  };
  const specificYiled = (data) => {
    return !isNaN(data) ? data.toFixed(1) : "0";
  };
  const utcDate = new Date();
  const istOffset = 5.5 * 60 * 60 * 1000;

  const istDate = new Date(utcDate.getTime() + istOffset);

  // Formatting the date
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZone: "Asia/Kolkata",
  };
  async function createEquipmentMap(siteId) {
    // Fetch the equipment dropdown data for the site
    try {
      const equip = await fetchEquipDropdownBySite(siteId);

      // Create a map to store equipment based on dataLoggerId
      const dataLoggerMap = equip.reduce((map, equipment) => {
        const { dataLoggerId } = equipment;
        if (dataLoggerId === null || dataLoggerId === undefined) {
          return map;
        }
        if (!map[dataLoggerId]) {
          map[dataLoggerId] = [];
        }
        map[dataLoggerId].push(equipment);
        return map;
      }, {});

      const resultMap = {};
      for (const [dataLoggerId, equipments] of Object.entries(dataLoggerMap)) {
        const params = await fetchParamsDropdownByLogger({
          dataloggerId: [Number(dataLoggerId)],
        });
        equipments.forEach((equipment) => {
          resultMap[equipment.displayName] = params.map(
            (param) => param.stdParameterName
          );
        });
      }

      // Update the state with the resulting map
      setEqupParamMapValue(resultMap);
      return resultMap;
    } catch (e) {
      return [];
    }
  }

  const getKey = (chartType) => {
    switch (chartType) {
      case "Daily Generation":
        return "dailygenerationChartData";
      case "Energy Performance":
        return "energyPerformanceChartData";
      case "Parameter Comparision":
        return "parameterList";
      case "Specific Yield":
        return "equSpeciYield";
      case "Specific Yield - Heatmap":
        return "specificYieldInv";

      case "String Current - Average":
        return "stringCureent";
      case "String Current - Heatmap":
        return "stringCureent";
      case "Actual vs Expected":
        return "actualVsExcepted";
      case "DG PV Grid Management":
        return "pvDgGridSyncvalue";
      case "Deemed Generation":
        return "deemedGeneration";
      case "Set Point":
        return "setPoint";
      default:
        return "barChart";
    }
  };

  const getEndpoints = (chartType) => {
    console.log(chartType);

    switch (chartType) {
      case "Daily Generation":
        return fetchDailyGenerationEira;
      case "Energy Performance":
        return fetchEnergyPerformanceEira;
      case "Parameter Comparision":
        return fetchParameterComparisonEira;
      case "String Current - Heatmap":
        return fetchStringCurrentEira;
      case "String Current - Average":
        return fetchStringAverage;
      case "Specific Yield - Heatmap":
        return fetchSpecificYieldEira;
      case "Actual vs Expected":
        return ActualVsExpected;
      case "DG PV Grid Management":
        return fetchPvDgGridEira;
      case "Deemed Generation":
        return DeemedGenrator;
      case "Set Point":
        return GetSetPoint;
      default:
        return () => {};
    }
  };

  const getAnalystCall = async () => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });

    setLoading(true);
    setPage(1);
    setPageValue(1);
    if (siteIdFilter && siteIdFilter.length > 0) {
      let siteId = siteIdFilter[0]?.siteId;
      let id = sessionStorage.getItem("id");
      let obj = {
        userId: Number(id),
        siteId: Number(siteId),
        fromCount: fromValue,
        toCount: toValue,
      };
      const parmasList = await getAxisData(siteId);
      let getanalyst = await getDataAnalyst(obj);
      let widgetList = getanalyst.widgetList;
      await createEquipmentMap(siteIdFilter[0]?.siteId);
      setPage(0);
      setPageValue(1);
      setAnalystData(getanalyst);
      widgetList = widgetList?.map((element) => {
        setRanges((prevRanges) => ({
          ...prevRanges,
          [element.widgetId]: {
            fromDate: element.fromDate,
            toDate: element.toDate,
          },
        }));

        const dataKeyType = getKey(element.chartType);

        setTableData((prevTableData) => ({
          ...prevTableData,
          [element.widgetId]: element[dataKeyType],
        }));
        setChartRanges((prevRanges) => ({
          ...prevRanges,
          [element.widgetId]: element.range,
        }));
        setTimePeriods((prevTimePeriods) => ({
          ...prevTimePeriods,
          [element.widgetId]: element.timePeriod,
        }));
        setIsWidgetLoading((prevIsWidgetLoading) => ({
          ...prevIsWidgetLoading,
          [element.widgetId]: false,
        }));
        if (element.chartType === "Specific Yield - Heatmap") {
          element.specificYieldInv = element.specificYieldInv?.map(
            (obj, index) => {
              return {
                ...obj,
                equipmentName:
                  getanalyst?.equipmentList?.filter(
                    (data) => data.equipmentid === obj.equipmentId
                  )[0]?.customernaming || `unknown-${index}`,
              };
            }
          );
        }
        if (element.chartType === "String Current - Heatmap") {
          // Create an array of dynamic field names
          const allInputCurrentFields = Array.from(
            { length: 30 },
            (_, i) => `inputCurrent_${String(i + 1).padStart(2, "0")}`
          );

          element.stringCureent = element.stringCureent?.map((obj, index) => {
            return {
              ...obj,
              equipmentName:
                getanalyst?.equipmentList?.filter(
                  (data) => data.equipmentid === obj.equipmentId
                )[0]?.customernaming || `unknown-${index}`,
              datas: {
                ...allInputCurrentFields.reduce((acc, field) => {
                  acc[field] = obj[field] !== null ? obj[field] : null; // Assign the value of each field
                  return acc;
                }, {}),
                equipmentName:
                  getanalyst?.equipmentList?.filter(
                    (data) => data.equipmentid === obj.equipmentId
                  )[0]?.customernaming || `unknown-${index}`,
              },
            };
          });
        }
        if (element.chartType === "String Current - Average") {
          // Create an array of dynamic field names
          const allInputCurrentFields = Array.from(
            { length: 30 },
            (_, i) => `inputCurrent_${String(i + 1).padStart(2, "0")}`
          );

          element.stringCureent = element.stringCureent?.map((obj, index) => {
            return {
              ...obj,
              equipmentName:
                getanalyst?.equipmentList?.filter(
                  (data) => data.equipmentid === obj.equipmentId
                )[0]?.customernaming || `unknown-${index}`,
              datas: {
                ...allInputCurrentFields.reduce((acc, field) => {
                  acc[field] = obj[field] !== null ? obj[field] : null; // Assign the value of each field
                  return acc;
                }, {}),
                equipmentName:
                  getanalyst?.equipmentList?.filter(
                    (data) => data.equipmentid === obj.equipmentId
                  )[0]?.customernaming || `unknown-${index}`,
              },
            };
          });
        } else if (element.chartType === "Parameter Comparision") {
          let equipmentParams = parmasList.filter((data) => {
            return element.measure_Parameter?.includes(
              `${data.stdParameterName}`
            );
          });
          setAxisDatas((prevAxisDatas) => ({
            ...prevAxisDatas,
            [element.widgetId]: equipmentParams,
          }));
        }
        return element;
      });

      setAnalystWizard(widgetList);

      setSiteList(getanalyst.siteList);
      function convertKey(data) {
        return data.map((obj) => {
          const { equipmentid, ...rest } = obj;
          return {
            equipmentId: equipmentid,
            ...rest,
          };
        });
      }
      setEquipmentList(convertKey(getanalyst.equipmentList));
      setTotal(getanalyst.totalCount);
      setLoading(false);
    }
  };

  const handleNavigate = () => {
    navigateEncryptedURL("/menu/add_analyst");
  };
  const handleDelete = async (id) => {
    navigate(location.pathname);
    await WizardDeleteCall(id);
    setDeleteId("");
    setOpen(false);
    setOpenToast(true);
    setVarient("success");
    setTextValue("Your Wizard Deleted Successfully");
    getAnalystCall();
  };
  /*---------------------------------------- deleet wizard-------------*/
  const handlePopup = (id) => {
    setDeleteId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function refreshPageAfterTime(timeInSeconds) {
    var timeInMilliseconds = timeInSeconds * 10000;
    setLoading(true);
    setOpen(true);
    // //console.log("opened");
    setTimeout(function () {
      setOpen(false);
      setReLoading(false);
      setLoading(false);
      // //console.log("closed");
    }, timeInMilliseconds);
  }
  useEffect(() => {
    if (reLoading) {
      refreshPageAfterTime(0);
    }
  }, [setLoading, reLoading]);

  const handleCloseDashboard = () => {
    setOpenDashboard(false);
  };

  const handleSubmit = async () => {
    let obj = {
      name: dashboardName,
      UserId: sessionStorage.getItem("id"),
    };
    let getanalyst = await postDashboardName(obj);
    if (getanalyst.data.status === 200) {
      setOpenToast(true);
      setVarient("success");
      setTextValue("Your Wizard Dashboard Created Successfully");
      getAnalystCall();
    } else {
      setOpenToast(true);
      setVarient("Warning");
      setTextValue("Facing Error in Create Dashboard");
    }
  };
  const fetchDatasite = async (id) => {
    try {
      const data = await SiteListDetail(id);

      const updatedSites = data.map((site) => ({
        ...site,
        specificYield: specificYiled(site.specificYield),
      }));
      setsiteDetail(updatedSites);
    } catch (e) {
      console.error(e);
    }
  };
  const handleChangePage = async (event, newPage) => {
    setPage(0);
    // console.log(rowsPerPage);
    // console.log(newPage);

    const updatedPage = newPage - 1;
    setPage(updatedPage);

    const fromValue = Math.max(updatedPage * rowsPerPage + 1, 1);
    const toValue = Math.max((updatedPage + 1) * rowsPerPage, rowsPerPage);
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });
    let obj = {
      userId: sessionStorage.getItem("id"),
      siteId: siteIdFilter[0]?.siteId,
      fromCount: fromValue,
      toCount: toValue,
    };

    try {
      setLoading(true);
      const getAnalyst = await getDataAnalyst(obj);
      const parmasList = await getAxisData(siteIdFilter[0]?.siteId);
      let widgetList = getAnalyst.widgetList;
      setAnalystData(getAnalyst);
      widgetList = widgetList?.map((element) => {
        setRanges((prevRanges) => ({
          ...prevRanges,
          [element.widgetId]: {
            fromDate: element.fromDate,
            toDate: element.toDate,
          },
        }));
        const dataKeyType = getKey(element.chartType);
        setTableData((prevTableData) => ({
          ...prevTableData,
          [element.widgetId]: element[dataKeyType],
        }));
        setChartRanges((prevRanges) => ({
          ...prevRanges,
          [element.widgetId]: element.range,
        }));
        setTimePeriods((prevTimePeriods) => ({
          ...prevTimePeriods,
          [element.widgetId]: element.timePeriod,
        }));
        setIsWidgetLoading((prevIsWidgetLoading) => ({
          ...prevIsWidgetLoading,
          [element.widgetId]: false,
        }));
        if (element.chartType === "Specific Yield - Heatmap") {
          element.specificYieldInv = element.specificYieldInv?.map(
            (obj, index) => {
              return {
                ...obj,
                equipmentName:
                  getAnalyst?.equipmentList?.filter(
                    (data) => data.equipmentid === obj.equipmentId
                  )[0]?.customernaming || `unknown-${index}`,
              };
            }
          );
        }
        if (element.chartType === "String Current - Heatmap") {
          element.stringCureent = element.stringCureent?.map((obj, index) => {
            return {
              ...obj,
              equipmentName:
                getAnalyst?.equipmentList?.filter(
                  (data) => data.equipmentid === obj.equipmentId
                )[0]?.customernaming || `unknown-${index}`,
              datas: {
                ...obj,
                equipmentName: getAnalyst?.equipmentList?.filter(
                  (data) => data.equipmentid === obj.equipmentId
                )[0]?.customernaming,
              },
            };
          });
        }
        if (element.chartType === "String Current - Average") {
          element.stringCureent = element.stringCureent?.map((obj, index) => {
            return {
              ...obj,
              equipmentName:
                getAnalyst?.equipmentList?.filter(
                  (data) => data.equipmentid === obj.equipmentId
                )[0]?.customernaming || `unknown-${index}`,
              datas: {
                ...obj,
                equipmentName: getAnalyst?.equipmentList?.filter(
                  (data) => data.equipmentid === obj.equipmentId
                )[0]?.customernaming,
              },
            };
          });
        }
        if (element.chartType === "Specific Yield - Heatmap") {
          element.specificYieldInv = element.specificYieldInv?.map(
            (obj, index) => {
              return {
                ...obj,
                equipmentName:
                  getAnalyst?.equipmentList?.filter(
                    (data) => data.equipmentid === obj.equipmentId
                  )[0]?.customernaming || `unknown-${index}`,
              };
            }
          );
        } else if (element.chartType === "Parameter Comparision") {
          let equipmentParams = parmasList.filter((data) => {
            return element.measure_Parameter?.includes(
              `${data.stdParameterName}`
            );
          });
          setAxisDatas((prevAxisDatas) => ({
            ...prevAxisDatas,
            [element.widgetId]: equipmentParams,
          }));
        }
        return element;
      });
      setAnalystWizard(widgetList);
      setTotal(getAnalyst.totalCount);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChangeRowsPerPage = async (event, newValue) => {
    setRowsPerPage(parseInt(newValue));
    setPage(0);
    setPageValue(0);
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });
    const pagee = 0;
    const fromValue = Math.max(pagee * newValue + 1, 1);
    const toValue = Math.max((pagee + 1) * newValue, newValue);

    let obj = {
      userId: sessionStorage.getItem("id"),
      siteId: siteIdFilter[0]?.siteId,
      fromCount: fromValue,
      toCount: toValue,
    };

    try {
      setLoading(true);
      const getAnalyst = await getDataAnalyst(obj);
      const parmasList = await getAxisData(siteIdFilter[0]?.siteId);
      let widgetList = getAnalyst.widgetList;
      setAnalystData(getAnalyst);
      widgetList = widgetList?.map((element) => {
        setRanges((prevRanges) => ({
          ...prevRanges,
          [element.widgetId]: {
            fromDate: element.fromDate,
            toDate: element.toDate,
          },
        }));
        const dataKeyType = getKey(element.chartType, element.equSpeciYield);
        setTableData((prevTableData) => ({
          ...prevTableData,
          [element.widgetId]: element[dataKeyType],
        }));
        setChartRanges((prevRanges) => ({
          ...prevRanges,
          [element.widgetId]: element.range,
        }));
        setTimePeriods((prevTimePeriods) => ({
          ...prevTimePeriods,
          [element.widgetId]: element.timePeriod,
        }));
        setIsWidgetLoading((prevIsWidgetLoading) => ({
          ...prevIsWidgetLoading,
          [element.widgetId]: false,
        }));
        if (element.chartType === "Specific Yield - Heatmap") {
          element.specificYieldInv = element.specificYieldInv?.map(
            (obj, index) => {
              return {
                ...obj,
                equipmentName:
                  getAnalyst?.equipmentList?.filter(
                    (data) => data.equipmentid === obj.equipmentId
                  )[0]?.customernaming || `unknown-${index}`,
              };
            }
          );
        }
        if (element.chartType === "String Current - Average") {
          element.stringCureent = element.stringCureent?.map((obj, index) => {
            return {
              ...obj,
              equipmentName:
                getAnalyst?.equipmentList?.filter(
                  (data) => data.equipmentid === obj.equipmentId
                )[0]?.customernaming || `unknown-${index}`,
              datas: {
                ...obj,
                equipmentName: getAnalyst?.equipmentList?.filter(
                  (data) => data.equipmentid === obj.equipmentId
                )[0]?.customernaming,
              },
            };
          });
        }
        if (element.chartType === "String Current - Heatmap") {
          element.stringCureent = element.stringCureent?.map((obj, index) => {
            return {
              ...obj,
              equipmentName:
                getAnalyst?.equipmentList?.filter(
                  (data) => data.equipmentid === obj.equipmentId
                )[0]?.customernaming || `unknown-${index}`,
              datas: {
                ...obj,
                equipmentName: getAnalyst?.equipmentList?.filter(
                  (data) => data.equipmentid === obj.equipmentId
                )[0]?.customernaming,
              },
            };
          });
        } else if (element.chartType === "Parameter Comparision") {
          let equipmentParams = parmasList.filter((data) => {
            return element.measure_Parameter?.includes(
              `${data.stdParameterName}`
            );
          });
          setAxisDatas((prevAxisDatas) => ({
            ...prevAxisDatas,
            [element.widgetId]: equipmentParams,
          }));
        }
        return element;
      });

      setAnalystWizard(widgetList);
      setTotal(getAnalyst.totalCount);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const PageChange = (value) => {
    setPageValue(value);
  };

  const handleClickGo = async () => {
    const pageNumber = parseInt(pageValue, 10);
    if (pageNumber > 0 && pageNumber <= Math.ceil(Total / rowsPerPage)) {
      setPage(pageNumber - 1);
      setPageValue("");
    }
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });

    const fromValue = Math.max((pageNumber - 1) * rowsPerPage + 1, 1);
    const toValue = Math.max((pageNumber - 1 + 1) * rowsPerPage, rowsPerPage);

    let obj = {
      userId: sessionStorage.getItem("id"),
      siteId: siteIdFilter[0]?.siteId,
      fromCount: fromValue,
      toCount: toValue,
    };
    try {
      setLoading(true);
      const getAnalyst = await getDataAnalyst(obj);
      const parmasList = await getAxisData(siteIdFilter[0]?.siteId);
      let widgetList = getAnalyst.widgetList;
      setAnalystData(getAnalyst);
      widgetList = widgetList?.map((element) => {
        setRanges((prevRanges) => ({
          ...prevRanges,
          [element.widgetId]: {
            fromDate: element.fromDate,
            toDate: element.toDate,
          },
        }));

        const dataKeyType = getKey(element.chartType);
        setTableData((prevTableData) => ({
          ...prevTableData,
          [element.widgetId]: element[dataKeyType],
        }));
        setChartRanges((prevRanges) => ({
          ...prevRanges,
          [element.widgetId]: element.range,
        }));
        setTimePeriods((prevTimePeriods) => ({
          ...prevTimePeriods,
          [element.widgetId]: element.timePeriod,
        }));
        setIsWidgetLoading((prevIsWidgetLoading) => ({
          ...prevIsWidgetLoading,
          [element.widgetId]: false,
        }));
        if (element.chartType === "Specific Yield - Heatmap") {
          element.specificYieldInv = element.specificYieldInv?.map(
            (obj, index) => {
              return {
                ...obj,
                equipmentName:
                  getAnalyst?.equipmentList?.filter(
                    (data) => data.equipmentid === obj.equipmentId
                  )[0]?.customernaming || `unknown-${index}`,
              };
            }
          );
        }
        if (element.chartType === "String Current - Heatmap") {
          element.stringCureent = element.stringCureent?.map((obj, index) => {
            return {
              ...obj,
              equipmentName:
                getAnalyst?.equipmentList?.filter(
                  (data) => data.equipmentid === obj.equipmentId
                )[0]?.customernaming || `unknown-${index}`,
              datas: {
                ...obj,
                equipmentName: getAnalyst?.equipmentList?.filter(
                  (data) => data.equipmentid === obj.equipmentId
                )[0]?.customernaming,
              },
            };
          });
        }
        if (element.chartType === "String Current - Average") {
          element.stringCureent = element.stringCureent?.map((obj, index) => {
            return {
              ...obj,
              equipmentName:
                getAnalyst?.equipmentList?.filter(
                  (data) => data.equipmentid === obj.equipmentId
                )[0]?.customernaming || `unknown-${index}`,
              datas: {
                ...obj,
                equipmentName: getAnalyst?.equipmentList?.filter(
                  (data) => data.equipmentid === obj.equipmentId
                )[0]?.customernaming,
              },
            };
          });
        } else if (element.chartType === "Parameter Comparision") {
          let equipmentParams = parmasList.filter((data) => {
            return element.measure_Parameter?.includes(
              `${data.stdParameterName}`
            );
          });
          setAxisDatas((prevAxisDatas) => ({
            ...prevAxisDatas,
            [element.widgetId]: equipmentParams,
          }));
        }
        return element;
      });

      setAnalystWizard(widgetList);
      setTotal(getAnalyst.totalCount);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  function convertMWhToKWh(mwh) {
    return mwh * 1000;
  }
  const changeUnit = () => {
    SetUnitChange(!unitChange);
  };
  const renderCustomPrevious = (props) => (
    <CustomIconSlot
      name="PREVIOUS"
      icon={<ArrowBackIcon style={{ fontSize: "16px" }} />}
      {...props}
    />
  );
  const CustomIconSlot = ({ name, icon, ...props }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: name === "NEXT" ? "row-reverse" : "row",
      }}
    >
      {React.cloneElement(icon, props)}
      <Typography
        style={{
          margin: "14px",

          fontSize: "12px",
        }}
      >
        {name}
      </Typography>
    </div>
  );
  const renderCustomNext = (props) => (
    <CustomIconSlot
      name="NEXT"
      icon={<ArrowForwardIcon style={{ fontSize: "16px" }} />}
      {...props}
    />
  );

  const fromValue = Math.max((page - 1) * rowsPerPage + 1, 1);
  const toValue = Math.max(page * rowsPerPage, rowsPerPage);

  const changeDateByRange = (value) => {
    console.log(value, "value");

    let date = new Date();
    let fromDate = new Date();
    let responseDate = "";
    let toDate = "";
    let range = "";
    const formatDate = (inputDate) => {
      const day = String(inputDate.getDate()).padStart(2, "0");
      const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const year = inputDate.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    };
    switch (value) {
      case "today":
        responseDate = moment(date).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        range = "daily";
        break;
      case "yesterday":
        date.setDate(date.getDate() - 1);
        responseDate = moment(date).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD  ");
        range = "daily";
        break;
      case "last 7 days":
        fromDate.setDate(date.getDate() - 7);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD  ");
        range = "custom";
        break;
      case "this month":
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const endDate = new Date(date);
        responseDate = formatDate(startDate);
        toDate = formatDate(endDate);
        range = "custom";
        break;
      case "last month":
        const firstDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          0
        );
        responseDate = formatDate(firstDayOfLastMonth);
        toDate = formatDate(lastDayOfLastMonth);
        range = "custom";
        break;
      case "monthly":
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1); // First day of the current year
        toDate = moment(date).format("YYYY-MM-DD"); // Today's date
        responseDate = formatDate(firstDayOfYear); // From the first day of the current year
        range = "yearly";
        break;
      case "yearly":
        const firstDayOfLastYear = new Date(date.getFullYear() - 1, 0, 1); // First day of the previous year
        toDate = moment(date).format("YYYY-MM-DD"); // Today's date
        responseDate = formatDate(firstDayOfLastYear); // From the first day of the previous year
        range = "yearlyCum";
        break;
      default:
        break;
    }
    return { fromDate: responseDate, toDate, range };
  };

  // console.log(rangeObject.fromValue);
  // console.log(rangeObject.toValue);

  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );
  if (siteLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20%",
        }}
      >
        <FadeLoader color="#000440" loading={true} />
      </div>
    );
  }
  const performanceRatio = sitedetail[0]?.performanceRatio;
  return (
    <div className="">
      <Card elevation={0}>
        <CardContent>
          <Grid
            container
            spacing={2}
            columns={16}
            direction="row"
            alignItems="center"
          >
            <Grid item xs={4}>
              <div>
                <Typography
                  style={{
                    fontSize: "1.7rem",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    lineHeight: "1.2",
                    color: "rgb(113, 118, 111)",
                  }}
                >
                  Analytics Dashboard
                </Typography>
                <Tooltip title={site}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    value={site}
                    options={siteIdName.map((option) => option.siteName)}
                    onChange={(event, newValue) => handleSite(event, newValue)}
                    sx={{ width: "20vw" }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Site ..." />
                    )}
                    classes={{ option: "autocomplete" }}
                  />
                </Tooltip>
              </div>
            </Grid>

            <Grid item xs={7}>
              <Typography
                style={{
                  fontSize: "1.7rem",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  lineHeight: "1.2",
                  color: "rgb(113, 118, 111)",
                  visibility: "hidden",
                }}
              >
                Analytics Dashboard
              </Typography>
              <Stack
                spacing={2}
                sx={{
                  visibility:
                    analystWizardData.length > 0 ? "visible" : "hidden",
                }}
              >
                <Grid container direction="row" alignItems="center">
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "60%",
                      visibility:
                        analystWizardData.length === 0 ? "hidden" : "visible",
                    }}
                  >
                    <Stack direction="row">
                      <Pagination
                        count={Total === 0 ? 5 : Math.ceil(Total / rowsPerPage)}
                        shape="rounded"
                        color="primary"
                        page={page + 1}
                        siblingCount={0}
                        boundaryCount={2}
                        size="small"
                        onChange={handleChangePage}
                        disabled={analystWizardData.length === 0}
                        renderItem={(item) => (
                          <PaginationItem
                            slots={{
                              previous: renderCustomPrevious,
                              next: renderCustomNext,
                            }}
                            {...item}
                          />
                        )}
                      />
                      <Divider
                        orientation="vertical"
                        flexItem
                        style={{
                          color: "#E6E7E9",
                          height: "30px",
                          marginRight: "16px",
                          marginLeft: "16px",
                        }}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      style={{
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginLeft: "1%",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "12px",
                          lineHeight: "14.52px",
                          fontWeight: "400",
                          color:
                            analystWizardData.length == 0
                              ? "rgb(207 207 207)"
                              : "black",
                        }}
                        disabled={analystWizardData.length == 0}
                      >
                        Go to page
                      </Typography>
                      {/* <Button
                    style={{
                      padding: '0',
                      width: '15px',
                      fontSize: '12px',
                      fontWeight: '400',
                    }}
                    onClick={handleClickGo}
                    disabled={analystWizardData.length == 0}
                 
                  >
                   Go to page
                  </Button> */}
                      <TextField
                        variant="standard"
                        style={{
                          width: "24px",
                          marginLeft: "5px",
                        }}
                        disabled={analystWizardData.length == 0}
                        value={pageValue !== 0 ? pageValue : ""}
                        onChange={(e) => PageChange(e.target.value)}
                      />
                      <Button
                        style={{
                          padding: "0",
                          width: "12px",
                          fontSize: "14px",
                        }}
                        onClick={handleClickGo}
                        disabled={analystWizardData.length == 0}
                        endIcon={
                          <ArrowForwardIcon style={{ fontSize: "16px" }} />
                        }
                      >
                        GO
                      </Button>
                      <Autocomplete
                        options={rowsPerPageOptions}
                        getOptionLabel={(option) => option.toString()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            sx={{
                              fontFamily: "Inter, sans-serif",
                              width: 40,
                            }}
                          />
                        )}
                        disabled={analystWizardData.length == 0}
                        value={rowsPerPage}
                        onChange={handleChangeRowsPerPage}
                        disableClearable
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginTop: "25%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={autoRefresh}
                      onChange={(e) => handleAutoRefresh(e.target.checked)}
                    />
                  }
                  label="Auto Refresh (5 min)"
                />
              </div>
              {/* <div>
                <Tooltip title="Refresh">
                  <IconButton onClick={handleRefresh}>
                    <CachedIcon
                      sx={{ color: CheckForLight() ? "black" : "white" }}
                    />
                  </IconButton>
                </Tooltip>
              </div> */}
            </Grid>
            {/* Add New Widget Button in a new Grid Item */}
            <Grid
              item
              xs={2.5}
              style={{
                display: "flex",
                // justifyContent: "flex-end",
                marginTop: "2%",
              }}
            >
              <Tooltip title="Add New Widget">
                <Button
                  variant="contained"
                  style={{
                    borderRadius: "25px",
                    textTransform: "capitalize",
                    marginLeft: "12%",
                  }}
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  onClick={handleNavigate}
                >
                  New Widget
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {loading ? (
        <div style={{ visibility: "hidden" }}>
          {" "}
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginTop: "0.5%",
          }}
        >
          <Paper
            elevation={0}
            style={{
              display: "flex",
              width: "100%",
              borderRadius: "2px 2px 0px 0px",
              userSelect: "none",
              height: "20vh",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid
              container
              spacing={4}
              columns={48}
              style={{ padding: "20px 20px 20px 20px" }}
            >
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>
                  Specific Yield
                </Typography>
                <Typography>{sitedetail[0]?.specificYield}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>Today Energy</Typography>
                <Typography>{sitedetail[0]?.sumOfTodayEnergy}</Typography>
              </Grid>
              {/* <Grid item xs={8}>
              <Typography style={{ color: "gray" }}>Total Energy</Typography>
              <Typography style={{position:"center"}}>
  {unitChange
    ? `${sitedetail[0]?.sumOfTotalEnergy || ''}`
    : `${convertMWhToKWh(
        parseFloat(sitedetail[0]?.sumOfTotalEnergy?.split('MWh')[0] || 0)
      ).toFixed(2)} MWh`}
  {unitChange ? (
    <ArrowDropUpOutlinedIcon style={{alignItems:"center"}} onClick={changeUnit} />
  ) : (
    <ArrowDropDownOutlinedIcon onClick={changeUnit} />
  )}
</Typography>
            </Grid> */}
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>Total Energy</Typography>
                <Typography style={{ position: "center" }}>
                  {unitChange
                    ? `${(
                        parseFloat(sitedetail[0]?.sumOfTotalEnergy || 0) / 1000
                      ).toFixed(3)} MWh`
                    : `${parseFloat(
                        sitedetail[0]?.sumOfTotalEnergy || 0
                      ).toFixed(2)} kWh`}
                  {unitChange ? (
                    <ArrowDropUpOutlinedIcon
                      style={{ alignItems: "center" }}
                      onClick={changeUnit}
                    />
                  ) : (
                    <ArrowDropDownOutlinedIcon onClick={changeUnit} />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>Active Power</Typography>
                <Typography>{sitedetail[0]?.sumOfActivePower}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>Capacity</Typography>
                <Typography>{`${sitedetail[0]?.installationCapacity} kWp`}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>
                  Inverter Capacity
                </Typography>
                <Typography style={{ alignItems: "center" }}>
                  {sitedetail[0]?.invertersCapacity}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>CUF</Typography>
                <Typography>{sitedetail[0]?.cuf}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>CO2 Avoided</Typography>
                <Typography>{sitedetail[0]?.co2}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>Today Events</Typography>
                <Typography style={{ alignItems: "center" }}>
                  {sitedetail[0]?.todayEvents}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>Total Events</Typography>
                <Typography>{sitedetail[0]?.totalEvents}</Typography>
              </Grid>

              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>PR</Typography>
                <Typography
                  style={{
                    color: shouldHighlightRed(
                      "performanceRatio",
                      performanceRatio
                    )
                      ? "red"
                      : "inherit",
                  }}
                >
                  {performanceRatio}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>Status</Typography>
                <Typography>{sitedetail[0]?.siteStatus}</Typography>
              </Grid>
              {/* <Grid item xs={8}>
                            <Typography style={{ color: 'gray' }}>
                   Status
                            </Typography>
                            <Typography>{sitedetail?.displayName}</Typography>
                          </Grid> */}
            </Grid>
          </Paper>
        </Box>
      )}
      <div className="card-analyst">
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15%",
                marginLeft: "50%",
              }}
            >
              <FadeLoader color="#000440" loading={true} />
            </div>
          ) : analystWizardData.length > 0 ? (
            analystWizardData.map((elem) => {
              const { fromDate, toDate } = ranges[elem.widgetId];
              const range = chartRanges[elem.widgetId];
              const timePeriod = timePeriods[elem.widgetId];
              const handleChangeDate = async (direction) => {
                console.log(timePeriod);
                let newToDate, newFromDate;
                const currentToDate = moment(toDate);
                const currentFromDate = moment(fromDate);
                if (
                  currentFromDate.isSame(moment().startOf("day")) &&
                  direction === "right"
                ) {
                  return;
                }
                const dateDifference =
                  timePeriod === "today" || timePeriod === "yesterday" ? 1 : 7;
                // Calculate the date range difference
                if (timePeriod === "this month") {
                  if (direction === "right") {
                    newFromDate = currentFromDate
                      .clone()
                      .add(1, "month")
                      .startOf("month");
                    newToDate = currentToDate
                      .clone()
                      .add(1, "month")
                      .endOf("month");
                  } else if (direction === "left") {
                    newFromDate = currentFromDate
                      .clone()
                      .subtract(1, "month")
                      .startOf("month");
                    newToDate = currentToDate
                      .clone()
                      .subtract(1, "month")
                      .endOf("month");
                  } else {
                    return; // Do nothing if direction is not 'left' or 'right'
                  }
                } else if (
                  timePeriod.includes("year") ||
                  timePeriod.toLowerCase() === "monthly"
                ) {
                  if (direction === "right") {
                    newFromDate = currentFromDate
                      .clone()
                      .add(1, "year")
                      .startOf("year");
                    newToDate = currentToDate
                      .clone()
                      .add(1, "year")
                      .endOf("year");
                  } else if (direction === "left") {
                    newFromDate = currentFromDate
                      .clone()
                      .subtract(1, "year")
                      .startOf("year");
                    newToDate = currentToDate
                      .clone()
                      .subtract(1, "year")
                      .endOf("year");
                  } else {
                    return; // Do nothing if direction is not 'left' or 'right'
                  }
                } else {
                  const dateDiff = dateDifference;

                  if (direction === "left") {
                    newToDate = currentToDate.subtract(dateDiff, "days");
                    newFromDate = currentFromDate.subtract(dateDiff, "days");
                  } else if (direction === "right") {
                    newToDate = currentToDate.add(dateDiff, "days");
                    newFromDate = currentFromDate.add(dateDiff, "days");
                  } else {
                    return; // Do nothing if direction is not 'left' or 'right'
                  }
                }

                const today = moment().startOf("day");

                if (newToDate.isAfter(today)) {
                  // If the new "to" date is in the future, reset it to today
                  newToDate = today;
                }

                const formattedToDate = newToDate.format("YYYY-MM-DD");
                const formattedFromDate = newFromDate.format("YYYY-MM-DD");
                console.log(formattedFromDate, formattedToDate);

                let siteIdFilter = siteIdName?.filter((data) => {
                  return data.siteName === site;
                });
                setTimerValue(siteIdFilter[0]?.siteFrequency ?? 5);
                let EiraDataModel = {
                  fromDate: formattedFromDate,
                  equipmentIds: elem?.equipment_Ids,
                  parameters: elem?.measure_Parameter,
                  range: range,
                  timeperiod: elem?.timePeriod,
                  siteId: elem?.siteId,
                  toDate: formattedToDate,
                  capacity: siteIdFilter[0]?.installationCapacity,
                  intervalMins: siteIdFilter[0]?.siteFrequency ?? 5,
                  energyFlag: 0,
                  energyGenBasedOn: siteIdFilter[0].energyGenBasedOn,
                  GraphType: elem?.chartType,
                };
                const equipments = await fetchEquipDropdownBySite(
                  siteIdFilter[0]?.siteId
                );
                setIsWidgetLoading((prevIsWidgetLoading) => ({
                  ...prevIsWidgetLoading,
                  [elem.widgetId]: true,
                }));
                if (
                  elem?.chartType === "Specific Yield" ||
                  elem?.chartType === "Specific Yield - Heatmap" ||
                  elem?.chartType === "Specific Yield vs Irradiation"
                ) {
                  const EquipmentForSpecificYield =
                    transformDataForSpecificYield(equipments);
                  EiraDataModel.equipmentIdAndCapacity =
                    EquipmentForSpecificYield;
                  let filteredObjects = equipments?.filter((obj) =>
                    ["Weather Station", "Energy Meter"]?.includes(
                      obj.equipmentCategory
                    )
                  );
                  const equipmentIds = filteredObjects?.map((data) => {
                    return data.equipmentId;
                  });

                  EiraDataModel.equipmentIds = equipmentIds;
                }
                if (
                  elem?.chartType === "String Current - Heatmap" ||
                  elem?.chartType === "String Current - Average"
                ) {
                  const EquipmentForSpecificYield =
                    transformDataForSpecificYield(equipments);
                  EiraDataModel.equipmentIdAndCapacity =
                    EquipmentForSpecificYield;
                  console.log(EquipmentForSpecificYield);
                  const equipmentId = EquipmentForSpecificYield.map(
                    (item) => item.equipmentId
                  );
                  console.log(equipmentId);
                  EiraDataModel.equipmentIds = equipmentId;
                } else if (elem?.chartType === "Daily Generation") {
                  let filteredObjects = equipments?.filter((obj) =>
                    [
                      "Weather Station",
                      "Energy Meter",
                      "String Inverter",
                      "Central Inverter",
                    ]?.includes(obj.equipmentCategory)
                  );
                  const equipmentIds = filteredObjects?.map((data) => {
                    return data.equipmentId;
                  });

                  EiraDataModel.equipmentIds = equipmentIds;
                }
                if (elem.chartType === "Specific Yield - Heatmap") {
                  EiraDataModel.range = "daily";
                }
                if (elem.chartType === "String Current - Heatmap") {
                  EiraDataModel.range = "daily";
                }
                if (elem.chartType === "String Current - Average") {
                  EiraDataModel.range = "daily";
                } else if (elem.chartType === "Actual vs Expected") {
                  if (EiraDataModel.range !== "yearly") {
                    EiraDataModel.range = "custom";
                  }
                }
                const requestURL = getEndpoints(elem?.chartType);
                let responseData = await requestURL(
                  elem?.chartType === "String Current - Heatmap" ||
                    elem?.chartType === "String Current - Average"
                    ? EiraDataModel?.equipmentIds
                    : EiraDataModel
                );
                setRanges((prevRanges) => ({
                  ...prevRanges,
                  [elem.widgetId]: {
                    fromDate: formattedFromDate,
                    toDate: formattedToDate,
                  },
                }));
                setIsWidgetLoading((prevIsWidgetLoading) => ({
                  ...prevIsWidgetLoading,
                  [elem.widgetId]: false,
                }));
                if (elem.chartType === "Specific Yield - Heatmap") {
                  responseData = responseData?.map((obj, index) => {
                    return {
                      ...obj,
                      equipmentName:
                        equipments?.filter(
                          (data) => data.equipmentId === obj.equipmentId
                        )[0]?.displayName || `unknown-${index}`,
                    };
                  });
                }

                if (elem.chartType === "String Current - Average") {
                  responseData = responseData?.map((obj, index) => {
                    console.log(obj, index);

                    return {
                      equipmentName:
                        equipments?.filter(
                          (data) => data.equipmentId === obj.equipmentId
                        )[0]?.displayName || `unknown-${index}`,
                      datas: {
                        ...obj,
                        equipmentName:
                          equipments?.filter(
                            (data) => data.equipmentId === obj.equipmentId
                          )[0]?.displayName || `unknown-${index}`,
                      },
                    };
                  });
                }
                if (elem.chartType === "String Current - Heatmap") {
                  responseData = responseData?.map((obj, index) => {
                    console.log(obj, index);

                    return {
                      equipmentName:
                        equipments?.filter(
                          (data) => data.equipmentId === obj.equipmentId
                        )[0]?.displayName || `unknown-${index}`,
                      datas: {
                        ...obj,
                        equipmentName:
                          equipments?.filter(
                            (data) => data.equipmentId === obj.equipmentId
                          )[0]?.displayName || `unknown-${index}`,
                      },
                    };
                  });
                }
                setTableData((prevTableData) => ({
                  ...prevTableData,
                  [elem.widgetId]: responseData,
                }));
                setChartRanges((prevRanges) => ({
                  ...prevRanges,
                  [elem.widgetId]: range,
                }));
              };

              async function handleMenuItemClick(value) {
                const elemt = selectedElement;

                setTimerValue(value);
                const { fromDate, toDate } = ranges[elemt.widgetId];
                let siteIdFilter = siteIdName?.filter((data) => {
                  return data.siteName === site;
                });
                let EiraDataModel = {
                  fromDate: fromDate,
                  equipmentIds: elemt?.equipment_Ids,
                  parameters: elemt?.measure_Parameter,
                  range: elemt?.range,
                  timeperiod: elemt?.timePeriod,
                  siteId: elemt?.siteId,
                  toDate: toDate,
                  capacity: siteIdFilter[0]?.installationCapacity,
                  intervalMins: value,
                  energyFlag: 0,
                  energyGenBasedOn: siteIdFilter[0].energyGenBasedOn,
                  GraphType: elemt?.chartType,
                };
                const equipments = await fetchEquipDropdownBySite(
                  siteIdFilter[0]?.siteId
                );
                setIsWidgetLoading((prevIsWidgetLoading) => ({
                  ...prevIsWidgetLoading,
                  [elemt.widgetId]: true,
                }));
                if (
                  elemt?.chartType === "Specific Yield" ||
                  elemt?.chartType === "Specific Yield - Heatmap" ||
                  elemt?.chartType === "String Current - Heatmap" ||
                  elemt?.chartType === "String Current - Average" ||
                  elemt?.chartType === "Specific Yield vs Irradiation"
                ) {
                  const EquipmentForSpecificYield =
                    transformDataForSpecificYield(equipments);
                  EiraDataModel.equipmentIdAndCapacity =
                    EquipmentForSpecificYield;
                  let filteredObjects = equipments?.filter((obj) =>
                    ["Weather Station", "Energy Meter"]?.includes(
                      obj.equipmentCategory
                    )
                  );
                  const equipmentIds = filteredObjects?.map((data) => {
                    return data.equipmentId;
                  });

                  EiraDataModel.equipmentIds = equipmentIds;
                } else if (elem?.chartType === "Daily Generation") {
                  let filteredObjects = equipments?.filter((obj) =>
                    [
                      "Weather Station",
                      "Energy Meter",
                      "String Inverter",
                      "Central Inverter",
                    ]?.includes(obj.equipmentCategory)
                  );
                  const equipmentIds = filteredObjects?.map((data) => {
                    return data.equipmentId;
                  });

                  EiraDataModel.equipmentIds = equipmentIds;
                }
                if (
                  elemt.chartType === "Specific Yield - Heatmap" ||
                  elemt.chartType === "String Current - Heatmap" ||
                  elemt.chartType === "String Current - Average"
                ) {
                  EiraDataModel.range = "daily";
                } else if (elemt.chartType === "Actual vs Expected") {
                  if (EiraDataModel.range !== "yearly") {
                    EiraDataModel.range = "custom";
                  }
                }
                let responseData = await fetchDailyGenerationEira(
                  EiraDataModel
                );
                setIsWidgetLoading((prevIsWidgetLoading) => ({
                  ...prevIsWidgetLoading,
                  [elemt.widgetId]: false,
                }));
                setTableData((prevTableData) => ({
                  ...prevTableData,
                  [elemt.widgetId]: responseData,
                }));
                setChartRanges((prevRanges) => ({
                  ...prevRanges,
                  [elemt.widgetId]: elemt.range,
                }));
                setTimePeriods((prevTimePeriods) => ({
                  ...prevTimePeriods,
                  [elemt.widgetId]: elemt.timeperiod,
                }));
                handleCloseTimer();
              }

              async function handleMenuItem(value) {
                const elemt = selectedElement;
                const { fromDate, toDate, range } = changeDateByRange(value);
                let siteIdFilter = siteIdName?.filter((data) => {
                  return data.siteName === site;
                });
                let EiraDataModel = {
                  fromDate: fromDate,
                  equipmentIds: elemt?.equipment_Ids,

                  parameters: elemt?.measure_Parameter,
                  range: range,
                  timeperiod: elemt?.timePeriod,
                  siteId: elemt?.siteId,
                  toDate: toDate,
                  capacity: siteIdFilter[0]?.installationCapacity,
                  intervalMins: timerValue,
                  energyFlag: 0,
                  energyGenBasedOn: siteIdFilter[0].energyGenBasedOn,
                  GraphType: elemt?.chartType,
                };
                const equipments = await fetchEquipDropdownBySite(
                  siteIdFilter[0]?.siteId
                );
                console.log(EiraDataModel, range);

                setIsWidgetLoading((prevIsWidgetLoading) => ({
                  ...prevIsWidgetLoading,
                  [elemt.widgetId]: true,
                }));
                if (
                  elemt?.chartType === "Specific Yield" ||
                  elemt?.chartType === "Specific Yield - Heatmap" ||
                  elemt?.chartType === "String Current - Heatmap" ||
                  elemt?.chartType === "String Current - Average" ||
                  elemt?.chartType === "Specific Yield vs Irradiation"
                ) {
                  const EquipmentForSpecificYield =
                    transformDataForSpecificYield(equipments);
                  EiraDataModel.equipmentIdAndCapacity =
                    EquipmentForSpecificYield;
                  let filteredObjects = equipments?.filter((obj) =>
                    ["Weather Station", "Energy Meter"]?.includes(
                      obj.equipmentCategory
                    )
                  );
                  const equipmentIds = filteredObjects?.map((data) => {
                    return data.equipmentId;
                  });

                  EiraDataModel.equipmentIds = equipmentIds;
                } else if (elem?.chartType === "Daily Generation") {
                  let filteredObjects = equipments?.filter((obj) =>
                    [
                      "Weather Station",
                      "Energy Meter",
                      "String Inverter",
                      "Central Inverter",
                    ]?.includes(obj.equipmentCategory)
                  );
                  const equipmentIds = filteredObjects?.map((data) => {
                    return data.equipmentId;
                  });

                  EiraDataModel.equipmentIds = equipmentIds;
                }
                if (elemt.chartType === "Specific Yield - Heatmap") {
                  EiraDataModel.range = "daily";
                } else if (elemt.chartType === "Actual vs Expected") {
                  if (
                    EiraDataModel.range !== "yearly" &&
                    EiraDataModel.range !== "yearlyCum"
                  ) {
                    EiraDataModel.range = "custom";
                  }
                }
                const requestURL = getEndpoints(elemt.chartType);
                let responseData = await requestURL(EiraDataModel);
                setRanges((prevRanges) => ({
                  ...prevRanges,
                  [elemt.widgetId]: {
                    fromDate: fromDate,
                    toDate: toDate,
                  },
                }));
                setIsWidgetLoading((prevIsWidgetLoading) => ({
                  ...prevIsWidgetLoading,
                  [elemt.widgetId]: false,
                }));
                setTableData((prevTableData) => ({
                  ...prevTableData,
                  [elemt.widgetId]: responseData,
                }));
                setChartRanges((prevRanges) => ({
                  ...prevRanges,
                  [elemt.widgetId]: range,
                }));
                setTimePeriods((prevTimePeriods) => ({
                  ...prevTimePeriods,
                  [elemt.widgetId]: value,
                }));
                handleCloseMenu();
              }
              return (
                <Grid item xs={21} md={6} key={elem.widgetId}>
                  <Paper elevation={0} style={{ marginTop: "2%" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={20} md={1.8}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "45vw",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            borderRadius="4px"
                            width="16vw"
                            justifyContent="space-between"
                            p="0"
                            style={{
                              border: CheckForLight()
                                ? "1px solid #c4c4c4"
                                : "1px solid #494949",
                              marginLeft: "10px",
                            }}
                          >
                            <IconButton
                              disabled={
                                elem.chartType === "String Current - Average" ||
                                elem.chartType === "String Current - Heatmap"
                              }
                              onClick={() => handleChangeDate("left")}
                            >
                              <ChevronLeftIcon />
                            </IconButton>
                            <Divider orientation="vertical" flexItem />
                            <Stack
                              direction="row"
                              style={{
                                cursor: "pointer",
                                flex: "1",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                mt="1px"
                                ml="2.5px"
                                style={{
                                  color: CheckForLight() ? "black" : "white",
                                  fontSize: "0.95rem",
                                }}
                              >
                                {range === "daily" &&
                                moment(toDate).format("YYYY-MM-DD") ===
                                  moment(fromDate).format("YYYY-MM-DD")
                                  ? `${moment(toDate).format("DD/MM/YYYY")}`
                                  : range === "yearly"
                                  ? `${moment(fromDate).format("YYYY")}`
                                  : range === "yearlyCum"
                                  ? `${moment(fromDate).format(
                                      "YYYY"
                                    )} - ${moment(toDate).format("YYYY")}`
                                  : timePeriod === "this month"
                                  ? `${
                                      monthNames[new Date(fromDate).getMonth()]
                                    } ${new Date(fromDate).getFullYear()}`
                                  : `${moment(fromDate).format(
                                      "DD/MM"
                                    )} - ${moment(toDate).format("DD/MM")}`}
                              </Typography>
                            </Stack>
                            <Divider orientation="vertical" flexItem />
                            <IconButton
                              disabled={
                                moment(toDate).format("YYYY-MM-DD") ==
                                  moment().format("YYYY-MM-DD") ||
                                elem.chartType === "String Current - Average"
                              }
                              onClick={() => handleChangeDate("right")}
                            >
                              <ChevronRightIcon />
                            </IconButton>
                            <Divider orientation="vertical" flexItem />
                            {elem.chartType === "Daily Generation" &&
                              range === "daily" && (
                                <>
                                  <IconButton
                                    style={{
                                      color: CheckForLight()
                                        ? "1px solid #c4c4c4"
                                        : "1px solid #494949",
                                      marginLeft: "10px",
                                    }}
                                    disabled={
                                      elem.chartType ===
                                        "String Current - Average" ||
                                      elem.chartType ===
                                        "String Current - Heatmap"
                                    }
                                    onClick={(event) =>
                                      handleClickMenuItem(event, elem)
                                    }
                                    sx={{
                                      width: "40px",
                                    }}
                                  >
                                    <Badge
                                      badgeContent={timerValue}
                                      color="default"
                                      anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <TimerOutlinedIcon
                                        sx={{ color: "black" }}
                                      />
                                    </Badge>
                                  </IconButton>
                                  <Menu
                                    anchorEl={anchorTimerEl}
                                    open={Boolean(anchorTimerEl)}
                                    onClose={handleCloseTimer}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                  >
                                    {timer.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        onClick={() =>
                                          handleMenuItemClick(option.value)
                                        }
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                  <Divider orientation="vertical" flexItem />
                                </>
                              )}
                            <IconButton
                              disabled={
                                elem.chartType === "String Current - Average" ||
                                elem.chartType === "String Current - Heatmap"
                              }
                              onClick={(event) => handleClickMenu(event, elem)}
                            >
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: CheckForLight() ? "black" : "white",
                                }}
                              />
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl}
                              open={menuOpen}
                              onClose={handleCloseMenu}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              {optionTime.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  onClick={() => handleMenuItem(option.value)}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Menu>
                          </Box>
{elem.chartType === "String Current - Heatmap"||elem.chartType === "String Current - Average"||elem.chartType === "Specific Yield - Heatmap"?(<Typography>{elem.widgetName}</Typography>):(<Typography></Typography>)}
                         
                          <DeleteIcon
                            style={{ cursor: "pointer", marginRight: "10px" }}
                            onClick={() => {
                              handlePopup(elem?.widgetId);
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={20} md={10.8}>
                        <div
                          key={elem.widgetId}
                          id={`${elem.widgetId}`}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "400px",
                            width: "45vw",
                            // marginTop: "15%",
                          }}
                        >
                          {isWidgetLoading[elem.widgetId] ? (
                            <FadeLoader
                              color="#000440"
                              loading={isWidgetLoading[elem.widgetId]}
                            />
                          ) : elem.chartType === "Daily Generation" &&
                            (range === "custom" ||
                              range === "yearly" ||
                              range === "yearlyCum") ? (
                            <BarChart
                              tableData={tableData[elem.widgetId]}
                              chartName={elem.chartType}
                              range={range}
                              widgetName={`${elem.widgetName.split(" - ")[0]}`}
                              customView={elem}
                              fallBack={fallBack}
                              from={"analytics"}
                            />
                          ) : elem.chartType === "Energy Performance" &&
                            (range === "custom" ||
                              range === "yearly" ||
                              range === "yearlyCum") ? (
                            <BarChart
                              chartName={elem.chartType}
                              widgetName={`${elem.widgetName}`}
                              tableData={tableData[elem.widgetId]}
                              chartValue={[elem]}
                              range={range}
                              siteId={elem.siteId}
                              fallBack={fallBack}
                              siteListAPI={siteListAPI}
                              equipmentListAPI={equipmentListAPI}
                              from={"analytics"}
                            />
                          ) : elem.chartType === "Daily Generation" &&
                            range === "daily" ? (
                            <ApexChart
                              chartName={elem.chartType}
                              widgetName={`${elem.widgetName}`}
                              tableData={tableData[elem.widgetId]}
                              chartValue={[elem]}
                              range={range}
                              siteId={elem.siteId}
                              fallBack={fallBack}
                              siteListAPI={siteListAPI}
                              equipmentListAPI={equipmentListAPI}
                              from={"analytics"}
                            />
                          ) : elem.chartType === "Energy Performance" &&
                            range === "daily" ? (
                            <ApexChart
                              chartName={elem.chartType}
                              widgetName={`${elem.widgetName}`}
                              tableData={tableData[elem.widgetId]}
                              chartValue={[elem]}
                              range={range}
                              siteId={elem.siteId}
                              siteListAPI={siteListAPI}
                              fallBack={fallBack}
                              equipmentListAPI={equipmentListAPI}
                              loggerMap={equpParamMap}
                              from={"analytics"}
                            />
                          ) : elem.chartType === "Parameter Comparision" ? (
                            <ApexChart
                              chartName={elem.chartType}
                              widgetName={`${elem.widgetName}`}
                              tableData={tableData[elem.widgetId]}
                              chartValue={[elem]}
                              range={range}
                              siteId={elem.siteId}
                              siteListAPI={siteListAPI}
                              axisData={axisDatas[elem.widgetId]}
                              fallBack={fallBack}
                              equipmentListAPI={equipmentListAPI}
                              loggerMap={equpParamMap}
                              from={"analytics"}
                            />
                          ) : elem.chartType === "Specific Yield" &&
                            elem.equSpeciYield !== null ? (
                            <CombinedChart
                              tableData={tableData[elem.widgetId]}
                              widgetName={`${elem.widgetName}`}
                              range={range}
                              equipmentListAPI={equipmentListAPI}
                              chartName={elem.chartType}
                              customView={elem}
                              fallBack={fallBack}
                              from={"analytics"}
                            />
                          ) : elem.chartType === "Specific Yield" &&
                            elem.specificYieldInv !== null ? (
                            <CombinedChart
                              tableData={tableData[elem.widgetId]}
                              widgetName={`${elem.widgetName}`}
                              range={range}
                              equipmentListAPI={equipmentListAPI}
                              chartName={elem.chartType}
                              customView={elem}
                              fallBack={fallBack}
                              from={"analytics"}
                            />
                          ) : elem.chartType === "String Current - Heatmap" ? (
                            <HeatmapChart
                              tableData={tableData[elem.widgetId]}
                              widgetName={`${elem.widgetName}`}
                              equipmentListAPI={equipmentListAPI}
                              chartName={elem.chartType}
                              customView={elem}
                              fallBack={fallBack}
                              from={"analytics"}
                            />
                          ) : elem.chartType === "String Current - Average" ? (
                            <HeatmapChart
                              tableData={tableData[elem.widgetId]}
                              widgetName={`${elem.widgetName}`}
                              equipmentListAPI={equipmentListAPI}
                              chartName={elem.chartType}
                              customView={elem}
                              fallBack={fallBack}
                              from={"analytics"}
                            />
                          ) : elem.chartType === "Specific Yield - Heatmap" ? (
                            <HeatmapChart
                              tableData={tableData[elem.widgetId]}
                              widgetName={`${elem.widgetName}`}
                              equipmentListAPI={equipmentListAPI}
                              chartName={elem.chartType}
                              customView={elem}
                              fallBack={fallBack}
                              from={"analytics"}
                            />
                          ) : elem.chartType === "Actual vs Expected" ? (
                            <CombinedChart
                              tableData={tableData[elem.widgetId]}
                              widgetName={`${elem.widgetName}`}
                              chartName={elem.chartType}
                              range={range}
                              fallBack={fallBack}
                              customView={elem}
                              from={"analytics"}
                            />
                          ) : elem.chartType === "DG PV Grid Management" &&
                            (range === "custom" ||
                              range === "yearly" ||
                              range === "yearlyCum") ? (
                            <BarChart
                              tableData={tableData[elem.widgetId]}
                              widgetName={`${elem.widgetName}`}
                              range={range}
                              chartName={elem.chartType}
                              fallBack={fallBack}
                              customView={elem}
                              from={"analytics"}
                            />
                          ) : elem.chartType === "DG PV Grid Management" &&
                            range === "daily" ? (
                            <AreaChart
                              tableData={tableData[elem.widgetId]}
                              WidgetName={`${elem.widgetName}`}
                              chartName={elem.chartType}
                              range={range}
                              fallBack={fallBack}
                              customView={elem}
                              from={"analytics"}
                            />
                          ) : elem.chartType === "Deemed Generation" &&
                            range === "daily" ? (
                            <ApexChart
                              chartName={elem.chartType}
                              widgetName={`${elem.widgetName}`}
                              tableData={tableData[elem.widgetId]}
                              chartValue={[elem]}
                              range={range}
                              siteId={elem.siteId}
                              siteListAPI={siteListAPI}
                              fallBack={fallBack}
                              equipmentListAPI={equipmentListAPI}
                              loggerMap={equpParamMap}
                              from={"analytics"}
                            />
                          ) : elem.chartType === "Set Point" ? (
                            <ApexChart
                              chartName={elem.chartType}
                              widgetName={`${elem.widgetName}`}
                              tableData={tableData[elem.widgetId]}
                              chartValue={[elem]}
                              range={range}
                              siteId={elem.siteId}
                              siteListAPI={siteListAPI}
                              fallBack={fallBack}
                              equipmentListAPI={equipmentListAPI}
                              loggerMap={equpParamMap}
                              from={"analytics"}
                            />
                          ) : elem.chartType === "Deemed Generation" ? (
                            <BarChart
                              tableData={tableData[elem.widgetId]}
                              chartName={elem.chartType}
                              customView={elem}
                              range={range}
                              widgetName={`${elem.widgetName}`}
                              fallBack={fallBack}
                              from={"analytics"}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })
          ) : (
            <div style={{ marginLeft: "42%", marginTop: "8%" }}>
              <Typography
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "500",
                  textTransform: "capitalize",
                  lineHeight: "14.52px !important",
                  color: " rgb(113, 118, 111)",
                }}
              >
                No Widgets Configure for this Site..
              </Typography>
            </div>
          )}
        </Grid>
      </div>
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Delete Confirmation ?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Would you like to remove this widget form the Analytics Dashboard
              ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" spacing={4}>
              <Button
                color="error"
                variant="contained"
                onClick={() => handleDelete(deleteId)}
                style={buttonStyle}
              >
                Yes, Remove it !
              </Button>
              <Button
                autoFocus
                variant="contained"
                onClick={handleClose}
                style={buttonStyle}
              >
                No Thanks
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>

        <div>
          <Snackbar
            open={openToast}
            autoHideDuration={3000}
            onClose={handleCloseToast}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={handleCloseToast}
              severity={variantText}
              sx={{ width: "100%", ...alertStyle }}
            >
              {textValue}
            </Alert>
          </Snackbar>
        </div>
      </div>

      {/* ---------------------------------Dashboard Open Popup------------------------- */}
      <div>
        <Modal
          open={openDashboard}
          onClose={handleCloseDashboard}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: "400",
                      textTransform: "capitalize",
                      lineHeight: "14.52px !important",
                      color: " rgb(113, 118, 111)",
                    }}
                  >
                    Dashboard Name :
                  </Typography>
                  <TextField
                    id="standard-basic"
                    placeholder=" Enter your Dashboard Name"
                    variant="standard"
                    onChange={(e) => {
                      setDashboardName(e.target.value);
                    }}
                    required
                    style={{ textTransform: "capitalize" }}
                  />
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                marginTop: "3%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                onClick={handleSubmit}
                style={{ textTransform: "capitalize" }}
              >
                Save
              </Button>
            </div>
          </Box>
        </Modal>
      </div>

      {/*  <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {'Delete Confirmation ?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Would you like to remove this Dashboard form the Analytics
              Dashboard ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" spacing={4}>
              <Button
                color="error"
                variant="contained"
                onClick={() => DeleteDashboard()}
                style={buttonStyle}
              >
                Yes, Remove it !
              </Button>
              <Button
                autoFocus
                variant="contained"
                onClick={handleClose}
                style={buttonStyle}
              >
                No Thanks
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>

        <div>
          <Snackbar
            open={openToast}
            autoHideDuration={3000}
            onClose={handleCloseToast}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert
              onClose={handleCloseToast}
              severity={variantText}
              sx={{ width: '100%', ...alertStyle }}
            >
              {textValue}
            </Alert>
          </Snackbar>
        </div>
      </div> */}
      {/* -----------------------------------Dashboard Delete ------------------------------ */}
    </div>
  );
};
export default Analytics;
