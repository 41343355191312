import React, { useState, useEffect } from "react";
import "./Alerts.css";
import dayjs, { Dayjs } from "dayjs";
/*---------------------------Mui Components ----------------------------------*/
import {
  Button,
  Stack,
  Box,
  Paper,
  TextField,
  Autocomplete,
  Typography,
  CardContent,
  Card,
  FormControl,
  Modal,
  Grid,
} from "@mui/material";
/*-------------------------------API----------------------------*/
import {
  fetchAlertsTable,
  updateEditCall,
  postAlertsSave,
} from "../../../Api/AlertsComponentApi";
import { fetchEquipDropdownBySite } from "../../../Api/CommonApi";
// import { Equipmentdropdown } from "../../../Api/EquipmentApi";
import { fetchParameterListDetails } from "../../../Api/ExcelUploadAPi";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import "../../../Common.css";
import { FadeLoader } from "react-spinners";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
/*------------------------- External compoenents ---------------------------*/
import Download from "../../../Template/Excel/Download";
import TableTemplate from "../../../Template/TableTemplate";
import CustomSnackbar from "../../util/components/CustomSnackbar";
import { customizeAlerts } from "../../util/TextField";
import errorMsg from "../../util/errorMessage.json";
import { fetchSiteDetails } from "../../../Api/ReportApi";
const stylemodal = {
  margin: "20px 0px 0px 0px",
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  borderRadius: "15px",
  left: "47%",
  height: "79vh",
  bgcolor: "background.paper",
  p: 4,
  marginLeft: "5%",
};
const Status = [
  { label: "Active", value: "1" },
  { label: "Inactive", value: "0" },
];
const typoStyle = {
  textTransform: "capitalize",
  opacity: "0.8",
  fontSize: "15px",
};
const Logic = [
  { label: "Less", value: "Less", id: 1 },
  { label: "Greater", value: "Greater", id: 2 },
  { label: "Equal", value: "Equal", id: 3 },
];

const ParamCondition = [
  { label: "AND (&&)", value: "AND", id: 1 },
  { label: "OR (||)", value: "OR", id: 2 },
  { label: "NONE", value: "NONE", id: 3 },
];

const CustomizeAlerts = ({ CustomizedAlert }) => {
  const [siteTemp, setSiteTemp] = useState("");
  const [loading, setloading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [tableValue, setTableValue] = useState([]);
  const [editValue, setEditValue] = useState();
  console.log(editValue, "editValue before sending");
  const [siteIdName, setSiteId] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [equipmentParameter, setParameterSite] = useState([]);
  const [open, setOpen] = useState(false);
  const [enableSms, setEnableSms] = useState("No");
  const [editState, setEditState] = useState(false);
  const [disabledSiteName, setDisabledSiteName] = useState(false);
  const [disabledEquipment, setDisabledEquipment] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [siteTab, setSiteTab] = React.useState(1);
  const initialSnack = { open: false, severity: "", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const [email, setEmail] = useState(sessionStorage.getItem("email"));
  const [equipmentParamter, setEquipmentParameter] = useState("");
  const [activeFlag, setactiveFlag] = useState();
  const [logic, setLogic] = useState("");
  const [equipmentName, setEquipment] = useState("");
  const [fieldValue, setFieldValue] = useState([]);
  const [validEmail, setValidEmail] = useState(true);
  console.log(fieldValue, "equipmentName");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const earliestFromTime = dayjs().startOf("day").hour(5); // 5 AM
  const latestToTime = dayjs().startOf("day").hour(19); // 7 PM
  // const [selectedConditions, setSelectedConditions] = useState("NONE");
  // const [parameterSets, setParameterSets] = useState([
  //   { parameter: "", logic: "", value: "" },
  //   { parameter: "", logic: "", value: "" },
  // ]);
  const [parameterSets, setParameterSets] = useState([
    {
      parameter: "",
      logic: "",
      value: "",
      condition: "NONE",
      isNextRowVisible: false,
    },
    {
      parameter: "",
      logic: "",
      value: "",
      condition: "NONE",
      isVisible: false,
    },
    {
      parameter: "",
      logic: "",
      value: "",
      condition: "NONE",
      isVisible: false,
    },
  ]);
  const [selectedConditions, setSelectedConditions] = useState(
    parameterSets.map(() => "NONE") // Initialize with "NONE" for each row
  );

  const ITEM_HEIGHT = 2;
  const ITEM_PADDING_TOP = 2;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 1.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };

  const fetchData = async () => {
    setloading(true);
    try {
      const [alertsData, sitesData] = await Promise.all([
        fetchAlertsTable(),
        fetchSiteDetails(),
      ]);

      const siteMap = new Map(
        sitesData.map((site) => [site.siteId, site.siteName])
      );

      const equipmentPromises = sitesData.map((site) =>
        fetchEquipDropdownBySite(site.siteId)
      );
      const allEquipmentData = await Promise.all(equipmentPromises);

      const equipmentMap = new Map();
      allEquipmentData.flat().forEach((equip) => {
        equipmentMap.set(equip.equipmentId, equip.displayName);
      });

      const updatedData = alertsData.map((alert) => {
        const parameterDetails = alert.parameters
          .filter((param) => param.parameter) // Filter out any empty parameters
          .map((param) => `${param.parameter} ${param.logic} ${param.value}`)
          .join(", ");

        return {
          ...alert,
          siteName: siteMap.get(alert.siteId) || "Unknown Site",
          equipmentName:
            equipmentMap.get(alert.equipmentId) || "Unknown Equipment",
          parameterDetails: parameterDetails,
          logic: alert.parameters
            .filter((param) => param.logic)
            .map((param) => param.logic)
            .join(", "),
          parameter: alert.parameters
            .filter((param) => param.parameter)
            .map((param) => param.parameter)
            .join(", "),
        };
      });

      console.log(updatedData, "Updated Fetched Data");
      setTableValue(updatedData);
      setEquipments(
        Array.from(equipmentMap.entries()).map(([id, name]) => ({
          equipmentId: id,
          displayName: name,
        }))
      );
    } catch (e) {
      console.error("Failed to fetch data:", e);
    } finally {
      setloading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        const siteData = await fetchSiteDetails();
        setSiteId(siteData);
        const equipmentData = await fetchEquipDropdownBySite();
        setEquipments(equipmentData);
      } catch (e) {
        console.error("Failed to fetch site data:", e);
      }
    };

    fetchSiteData().then(() => fetchData());
  }, []);

  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setValidEmail(isValidEmail(newEmail));
  };
  const handleEmailChangevalue = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setValidEmail(isValidEmail(newEmail));
  };
  const handleEquipmentParameter = (data, value) => {
    if (value === null) {
      setEquipmentParameter("");
    } else setEquipmentParameter(value);
  };
  const handleEquipment = (data, value) => {
    if (value == null) {
      setEquipment("");
    } else {
      setEquipment(value);
      // setEquipment(data)
      if (editMode) {
        setEditValue((prev) => ({ ...prev, equipmentName: value }));
      }
    }
  };
  const handleStatus = (event, newValue) => {
    if (newValue) {
      setEditValue((prev) => ({
        ...prev,
        activeFlag: newValue.value === "active" ? 1 : 0,
      }));
    }
  };
  const handleChange = (e) => {
    setFieldValue({ ...fieldValue, [e.target.name]: e.target.value });
  };
  const handleLogic = (data, event) => {
    setLogic(event.value);
  };
  // const handleConditionChange = (newValue) => {
  //   setSelectedConditions(newValue.value);
  //   if (newValue.value !== "NONE") {
  //     addParameterSet();
  //   }
  // };
  const handleConditionChange = (index, newValue) => {
    const updatedSets = [...parameterSets];
    updatedSets[index].condition = newValue ? newValue.value : "NONE";

    // If condition is AND or OR, make the next row visible
    if (newValue && (newValue.value === "AND" || newValue.value === "OR")) {
      if (index + 1 < updatedSets.length) {
        updatedSets[index + 1].isVisible = true;
      }
    } else {
      // If condition is NONE, hide the next row
      if (index + 1 < updatedSets.length) {
        updatedSets[index + 1].isVisible = false;
      }
    }

    setParameterSets(updatedSets);
  };
  const addParameterSet = () => {
    setParameterSets((prevSets) => [
      ...prevSets,
      { parameter: "", logic: "", value: "" },
    ]);
  };
  const handleParameterChange = (index, field, value) => {
    const newParameterSets = [...parameterSets];
    newParameterSets[index][field] = value;
    setParameterSets(newParameterSets);
    validateForm(); // Re-validate the form whenever parameters change
  };
  /*--------------------- Accept Numeric Only ---------------------*/
  const handleInput = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9]/g, "");
    if (input.length > 15) {
      input = input.slice(0, 15);
    }
    event.target.value = input;
  };
  const handleChangeFromTime = (event) => {
    console.log(event, "event");
    const selectedFromTime = dayjs(event);
    console.log(selectedFromTime, "selectedFromTime");
    console.log(earliestFromTime, "earliestFromTime");
    if (selectedFromTime.isBefore(earliestFromTime)) {
      console.log(earliestFromTime, "early");
      setFromTime(earliestFromTime);
    } else {
      setFromTime(event);
      console.log(event, "elseevent");
    }
  };
  const handleChangeToTime = (event) => {
    console.log(event, "event");
    const selectedToTime = dayjs(event);
    console.log(selectedToTime, "selectedToTime");
    console.log(latestToTime, "earliestFromTime");
    if (selectedToTime.isAfter(latestToTime)) {
      console.log(latestToTime, "early");
      setToTime(latestToTime);
    } else {
      setToTime(event);
      console.log(event, "elseevent");
    }
  };
  console.log(earliestFromTime, latestToTime, "test");
  /*----------------------Edit Call--------------------*/
  const handleEditClick = async (id) => {
    setAlertFields(id);
    fetchEquipmentDetails(id.siteName);
    fetchParams(id.siteid);
    setEditValue(id);
    setEditState(true);
    setmodalOpen(true);
    setDisabledSiteName(true);
    setDisabledEquipment(true);
    setEditMode(true);
    setEquipment(id.equipmentName);
    setSiteTemp(id.siteName);
    setEmail(id.emailId);
    setFieldValue({
      errorMessage: id.errorMessage,
    });
    setFromTime(dayjs(id.fromTime, "HH:mm:ss"));
    setToTime(dayjs(id.toTime, "HH:mm:ss"));

    // const savedParameters = id.parameters || [];
    // const newParameterSets = savedParameters.map((param) => ({
    //   parameter: param.parameter,
    //   logic: Logic.find((l) => l.value === param.logic) || null,
    //   value: param.value.toString(),
    // }));
    // setParameterSets(newParameterSets);
    // if (savedParameters.length > 1) {
    //   setSelectedConditions(id.conditions.includes("&&") ? "AND" : "OR");
    // } else {
    //   setSelectedConditions("NONE");
    // }
    //LATEST EDIT WORKING FINE
    const savedParameters = id.parameters || [];
    const newParameterSets = savedParameters.map((param, index) => ({
      parameter: param.parameter,
      logic: Logic.find((l) => l.value === param.logic) || null,
      value: param.value.toString(),
      // Pre-populate condition for multiple parameters
      condition:
        savedParameters.length > 1
          ? id.conditions.includes("&&")
            ? "AND"
            : "OR"
          : "NONE",
    }));

    setParameterSets(newParameterSets);

    // If there are multiple parameters, show all relevant rows
    if (savedParameters.length > 1) {
      // Ensure rows are visible based on saved conditions
      const updatedSets = newParameterSets.map((set, index) => ({
        ...set,
        isVisible:
          index === 0 ||
          newParameterSets[index - 1].condition === "AND" ||
          newParameterSets[index - 1].condition === "OR",
      }));
      setParameterSets(updatedSets);
    }

    // Set selected conditions (if needed)
    setSelectedConditions(
      savedParameters.length > 1
        ? id.conditions.includes("&&")
          ? "AND"
          : "OR"
        : "NONE"
    );
  };
  /*----------------------------- update call -------------------------------*/
  let initialState = {
    siteName: "",
    equipmentName: "",
    parameter: "",
    logic: "",
    value: "",
    emailId: "",
    errorMessage: "",
    parameterSets: "",
    status: "",
  };
  const [alertFields, setAlertFields] = useState([initialState]);
  const [textField, setTextField] = useState([]);
  useEffect(() => {
    fetchData();
    let data = customizeAlerts();
    setTextField(data);
  }, []);

  // Validation function
  const validateForm = () => {
    const hasAtLeastOneValidParameter = parameterSets.some(
      (set) => set.parameter && set.logic && set.value
    );

    const isValid =
      siteTemp && // Ensure site is selected
      equipmentName && // Ensure equipment is selected
      hasAtLeastOneValidParameter && // At least one valid parameter set
      fieldValue?.errorMessage && // Ensure error message is not empty
      email && // Ensure email is filled
      (fromTime || earliestFromTime) && // Ensure from time is set
      (toTime || latestToTime); // Ensure to time is set

    setIsSaveDisabled(!isValid);
  };

  // UseEffect to validate form whenever a relevant field changes
  useEffect(() => {
    validateForm();
  }, [
    siteTemp,
    equipmentName,
    parameterSets,
    fieldValue?.errorMessage,
    email,
    fromTime,
    toTime,
    earliestFromTime,
    latestToTime,
  ]);

  const handleSave = async () => {
    let equipmentIds = equipments?.filter((data) => {
      return data.displayName === equipmentName;
    });

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === siteTemp;
    });
    const validParameterSets = parameterSets.filter(
      (set) => set.parameter && set.logic && set.value
    );
  const parameters = validParameterSets.map((set) => ({
    parameter: set.parameter,
    logic: set.logic.value,
    value: Number(set.value),
  }));

  const conditions = validParameterSets
  .map((set, index) => {
    if (index === validParameterSets.length - 1) return set.parameter;
    return index === 0 ? `${set.parameter} && ` : `${set.parameter} || `;
  })
  .join("");

    let data = {
      siteId: siteIdFilter[0]?.siteId,
      equipmentId: equipmentIds[0]?.equipmentId,
      userId: Number(sessionStorage.getItem("id")),
      parameters: parameters,
      conditions: conditions,
      errorMessage: fieldValue?.errorMessage,
      status: 1,
      emailId: email,
      smsEnabled: enableSms,
      createdBy: Number(sessionStorage.getItem("id")),
      fromTime:
        fromTime !== ""
          ? dayjs(fromTime).format("HH:mm:ss")
          : dayjs(earliestFromTime).format("HH:mm:ss"),
      toTime:
        toTime !== ""
          ? dayjs(toTime).format("HH:mm:ss")
          : dayjs(latestToTime).format("HH:mm:ss"),
    };
    console.log(data, "data to be sent");
    try {
      let responseData = await postAlertsSave(data);
      console.log(responseData, "Save response");
      if (responseData && responseData.id) {
        // setFieldValue([]);
        setLogic("");
        setactiveFlag();
        await fetchData();
        setFromTime("");
        setToTime("");
        // resetFormState();
        setmodalOpen(false);
        setSnack({
          open: true,
          severity: "success",
          message: "Alert saved successfully!",
        });
      } else {
        setSnack({
          open: true,
          severity: "error",
          message: "Failed to save alert. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error saving data:", error);
      setSnack({
        open: true,
        severity: "error",
        message: "Error saving data. Please try again.",
      });
    }
  };
//   const handleUpdate = async () => {
//     // let customerNaming =
//     //   equipmentName === "" ? editValue.customerNaming : equipmentName;
//     let siteid = editValue.siteName;
//     let siteIdFilter = siteIdName?.filter((data) => data.siteName === siteid);
//     let Equipmentid = editValue.customerNaming;
//     let equipemntIds = equipments?.filter(
//       (data) => data.customernaming === Equipmentid
//     );

//     let equipemntid =
//       equipemntIds && equipemntIds.length > 0
//         ? equipemntIds[0].equipmentId
//         : null;
//     // if (!equipemntid) {
//     //   console.log("No matching equipment found");
//     //   return;
//     // }
 
//     // let parameters = parameterSets.map((set) => ({
//     //   parameter: set.parameter || "",
//     //   logic: set.logic?.value || "",
//     //   value: Number(set.value) || 0,
//     // }));
//     // let conditions = parameterSets
//     //   .map((set, index) => {
//     //     if (index === parameterSets.length - 1) return set.parameter;
//     //     return index === 0 ? `${set.parameter} && ` : `${set.parameter} || `;
//     //   })
//     //   .join("");

// /*--------------Valid Parameters (Seperate,multiple Param issue) 19-12-2024-------------*/
//  // Filter valid parameter rows
//  let validParameters = parameterSets.filter(
//   (set) => set.parameter && set.logic?.value && set.value
// );

// if (validParameters.length === 0) {
//   setSnack({
//     open: true,
//     severity: "error",
//     message: "No valid parameters to update. Please complete the fields.",
//   });
//   return;
// }

// // Prepare parameters and conditions
// let parameters = validParameters.map((set) => ({
//   parameter: set.parameter,
//   logic: set.logic.value,
//   value: Number(set.value),
// }));

// let conditions = validParameters
//   .map((set, index) => {
//     if (index === validParameters.length - 1) return set.parameter;
//     return index === 0 ? `${set.parameter} && ` : `${set.parameter} || `;
//   })
//   .join("");

//     let obj = {
//       userId: Number(sessionStorage.getItem("id")),
//       equipmentId: equipemntid,
//       // equipmentId: equipemntIds[0]?.equipmentId,
//       siteId: siteIdFilter[0].siteId,
//       parameters: parameters,
//       conditions: conditions,
//       emailId: email,
//       errorMessage:
//         fieldValue.errorMessage !== undefined
//           ? fieldValue.errorMessage
//           : editValue.errorMessage,
//       status: editValue.activeFlag !== undefined ? editValue.activeFlag : 1,
//       lastUpdatedBy: Number(sessionStorage.getItem("id")),
//       smsEnabled: enableSms,
//       createdBy: Number(sessionStorage.getItem("id")),
//       fromTime:
//         fromTime !== ""
//           ? dayjs(fromTime).format("HH:mm:ss")
//           : editValue.fromTime?.slice(0, 5),
//       toTime:
//         toTime !== ""
//           ? dayjs(toTime).format("HH:mm:ss")
//           : editValue.toTime?.slice(0, 5),
//     };

//     console.log(obj, "obj to be updated");

//     try {
//       const response = await updateEditCall(obj, editValue.id);
//       console.log("Full API response:", response);
//       const responseData = response.data;
//       console.log("Response data:", responseData);
//       if (responseData && responseData.userId) {
//         setFieldValue([]);
//         setLogic("");
//         setactiveFlag();
//         await fetchData();
//         setOpen(false);
//         resetFormState();
//         setmodalOpen(false);
//         setFromTime("");
//         setToTime("");

//         setSnack({
//           open: true,
//           severity: "success",
//           message: "Alert updated successfully!",
//         });
//       } else {
//         console.warn("Unexpected response format:", responseData);
//         setSnack({
//           open: true,
//           severity: "success",
//           message:
//             "Alert updated successfully, but response format was unexpected.",
//         });
//       }
//     } catch (error) {
//       console.error("Error updating data:", error);
//       if (error.response) {
//         console.error("Error response:", error.response);
//         console.error("Error response data:", error.response.data);
//       }

//       setSnack({
//         open: true,
//         severity: "error",
//         message: "Error updating data. Please try again.",
//       });
//     }
//   };
const handleUpdate = async () => {
  // Filter the siteId
  let siteid = editValue.siteName;
  let siteIdFilter = siteIdName?.filter((data) => data.siteName === siteid);

  // Find the correct equipmentId
  let selectedEquipmentName = editMode
    ? editValue?.equipmentName
    : equipmentName;
  let selectedEquipment = equipments?.find(
    (equipment) => equipment.displayName === selectedEquipmentName
  );
  let equipemntid = selectedEquipment?.equipmentId;

  // Ensure equipmentId is valid
  if (!equipemntid) {
    setSnack({
      open: true,
      severity: "error",
      message: "Invalid equipment selection. Please select a valid equipment.",
    });
    return;
  }

  // Filter valid parameter rows
  let validParameters = parameterSets.filter(
    (set) => set.parameter && set.logic?.value && set.value
  );

  if (validParameters.length === 0) {
    setSnack({
      open: true,
      severity: "error",
      message: "No valid parameters to update. Please complete the fields.",
    });
    return;
  }

  // Prepare parameters and conditions
  let parameters = validParameters.map((set) => ({
    parameter: set.parameter,
    logic: set.logic.value,
    value: Number(set.value),
  }));

  let conditions = validParameters
    .map((set, index) => {
      if (index === validParameters.length - 1) return set.parameter;
      return index === 0 ? `${set.parameter} && ` : `${set.parameter} || `;
    })
    .join("");

  let obj = {
    userId: Number(sessionStorage.getItem("id")),
    equipmentId: equipemntid,
    siteId: siteIdFilter[0]?.siteId || null,
    parameters: parameters,
    conditions: conditions,
    emailId: email,
    errorMessage:
      fieldValue.errorMessage !== undefined
        ? fieldValue.errorMessage
        : editValue.errorMessage,
    status: editValue.activeFlag !== undefined ? editValue.activeFlag : 1,
    lastUpdatedBy: Number(sessionStorage.getItem("id")),
    smsEnabled: enableSms,
    createdBy: Number(sessionStorage.getItem("id")),
    fromTime:
      fromTime !== ""
        ? dayjs(fromTime).format("HH:mm:ss")
        : editValue.fromTime?.slice(0, 5),
    toTime:
      toTime !== ""
        ? dayjs(toTime).format("HH:mm:ss")
        : editValue.toTime?.slice(0, 5),
  };

  console.log(obj, "obj to be updated");

  try {
    const response = await updateEditCall(obj, editValue.id);
    console.log("Full API response:", response);
    const responseData = response.data;
    if (responseData && responseData.userId) {
      setFieldValue([]);
      setLogic("");
      setactiveFlag();
      await fetchData();
      setOpen(false);
      resetFormState();
      setmodalOpen(false);
      setFromTime("");
      setToTime("");

      setSnack({
        open: true,
        severity: "success",
        message: "Alert updated successfully!",
      });
    } else {
      setSnack({
        open: true,
        severity: "success",
        message:
          "Alert updated successfully, but response format was unexpected.",
      });
    }
  } catch (error) {
    console.error("Error updating data:", error);
    setSnack({
      open: true,
      severity: "error",
      message: "Error updating data. Please try again.",
    });
  }
};

  /*--------------- Settings (Optional) Modal Components-----------------*/
  const defaultLoadingStates = {
    selectalert: true,
    selectsite: true,
    equipments: true,
    parameter: true,
    logic: true,
    value: true,
    errormessage: true,
    status: true,
    action: true,
  };
  useEffect(() => {
    getSiteCall();
  }, []);
  /*----------------------------- Paramater Get call ------------------------------*/
  const fetchParams = async (id) => {
    try {
      let data = await fetchParameterListDetails(id);
      setParameterSite(data);
    } catch (e) {
      console.error(e);
    }
  };
  /*----------------------------- Site Name API Call-----------------------*/
  const handleSite = (data, val) => {
    setSiteTemp(val);
    fetchEquipmentDetails(val);
  };
  const fetchEquipmentDetails = async (event) => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === event;
    });
    console.log(siteIdFilter[0]?.siteId);
    try {
      let data = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);
      console.log(data, "datasss");
      setEquipments(data);
      fetchParams(siteIdFilter[0]?.siteId);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    // getSiteCall();
  }, [equipments]);
  /*-------------------------- Site Get ---------------------*/
  const getSiteCall = async () => {
    try {
      // let id = sessionStorage.getItem('id');
      let id = 263;
      let data = await fetchSiteDetails();

      if (data.length > 0) {
        setSiteId([...new Set(data)]);
        // setSite(data[0]?.siteName);
      }
    } catch (e) {
      console.error(e);
    }
  };
  /*-------------------Modal Component--------------------*/
  const [modalopen, setmodalOpen] = React.useState(false);
  const handleCrate = () => {
    setDisabledEquipment(false);
    setmodalOpen(true);
    setEditState(false);
    // setUpdateIconState(false)
    setEditValue();
    setEditMode(false);
  };

  const resetFormState = () => {
    setEditValue(undefined);
    setEditState(false);
    setDisabledSiteName(false);
    setDisabledEquipment(false);
    setEditMode(false);
    setEquipment("");
    setSiteTemp("");
    setEmail("");
    setFieldValue({});
    setFromTime("");
    setToTime("");
    setParameterSets([
      { parameter: "", logic: "", value: "" },
      { parameter: "", logic: "", value: "" },
    ]);
    setSelectedConditions("NONE");
  };

  const handlemodalClose = () => {
    setmodalOpen(false);
    setAlertFields([initialState]);
    setSiteTemp("");
    setParameterSite([]);
    setEquipments([]);
    setDisabledEquipment(false);
    resetFormState();
  };
  /*---------------------Search Option ----------------*/
  const handleDownloadTable = async (val) => {
    const status = await Download(val, "CustomizeAlert");
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };
  const headCells = [
    {
      label: "Site Name",
      id: "siteName",
      view: true,
      default: true,
    },
    {
      label: "Equipment",
      id: "equipmentName",
      view: true,
      default: true,
    },
    {
      label: "Parameter",
      id: "parameter",
      view: true,
      default: true,
    },
    {
      label: "Logic",
      id: "logic",
      view: true,
    },
    {
      label: "Email",
      id: "emailId",
      view: true,
    },
    {
      label: "Error Message",
      id: "errorMessage",
      view: true,
      default: true,
    },
    {
      label: "From Time",
      id: "fromTime",
      view: true,
    },
    {
      label: "To Time",
      id: "toTime",
      view: true,
    },
    {
      label: "Status",
      id: "status",
      view: true,
    },
    {
      label: "Action",
      id: "action",
      view: true,
      default: true,
    },
  ];
  // const tableValues = tableValue.filter((row) => {
  //   if (siteTab !== "") {
  //     return Number(row.status) === Number(siteTab);
  //   } else {
  //     return row;
  //   }
  // });
  // Filter the table data based on the selected tab
  const filteredTableValue = tableValue?.filter((site) => {
    if (siteTab === 1) {
      return site?.status === 1;
    } else if (siteTab === 0) {
      return site?.status === 0;
    }
    return true; // By default, show all sites
  });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case "all":
        setSiteTab("");
        break;
      case "Active":
        setSiteTab(1);
        break;
      case "Inactive":
        setSiteTab(0);
        break;
      default:
        setSiteTab("");
    }
  };
  const Total = tableValue.length;
  const Active = tableValue?.filter((site) => site?.status == 1).length;
  const Inactive = tableValue?.filter((site) => site?.status == 0).length;

  const tabsData = [
    { name: "Total Alerts", value: "all", badgeCount: Total },

    { name: "Active Alerts", value: "Active", badgeCount: Active },
    {
      name: "Inactive Alerts",
      value: "Inactive",
      badgeCount: Inactive,
    },
  ];

  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Customise Alert", path: "customise-alerts" },
  ];
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div className="">
          <CustomSnackbar
            open={snack.open}
            onClose={handleSnackClose}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />
          <div>
            <div>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    mr: 0,
                    mb: 2,
                    mt: 2,
                    width: "100%",
                    minHeight: "7vh",
                    maxHeight: "max-content",
                    m: 0,
                    padding: "0",
                  },
                }}
              >
                <TableTemplate
                  PageName={"Custom Alerts"}
                  addButton={"Custom Alerts"}
                  SearchLabel={"Search Alerts Here... "}
                  header={headCells}
                  tabsData={tabsData}
                  rowsValue={filteredTableValue}
                  userRole={CustomizedAlert[0]}
                  rawData={tableValue}
                  handleChange={handleChangeTab}
                  handleAddPopupOpen={(val) => handleCrate(val)}
                  handleEditPopupOpen={(val) => handleEditClick(val)}
                  handleDownloadExcel={(val) => {
                    handleDownloadTable(val);
                  }}
                  paths={paths}
                  fallbackText={"No custom alerts configured yet"}
                />
              </Box>
            </div>
            <div>
              <Modal
                open={modalopen}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                sx={{ marginTop: "1.5%" }}
              >
                <div
                  className="monitoring-points"
                  style={{ marginLeft: "2%", marginTop: "1%" }}
                >
                  <Box sx={stylemodal}>
                    <Paper elevation={0}>
                      <Grid item xs={6} md={2}>
                        <div className="analytics">
                          <div style={{ margin: "0 0 0 15px" }}>
                            <Typography style={{ fontSize: "1.5rem" }}>
                              {" "}
                              Customize New Alert
                            </Typography>
                          </div>
                          <div style={{ marginRight: "0.5%" }}>
                            <Stack spacing={2} direction="row">
                              {editValue !== undefined ? (
                                <Button
                                  variant="contained"
                                  style={{
                                    borderRadius: "25px",
                                    textTransform: "capitalize",
                                  }}
                                  onClick={handleUpdate}
                                  startIcon={<SaveIcon />}
                                >
                                  Update
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  style={{
                                    borderRadius: "25px",
                                    textTransform: "capitalize",
                                  }}
                                  onClick={handleSave}
                                  disabled={isSaveDisabled}
                                  startIcon={<SaveIcon />}
                                >
                                  Save
                                </Button>
                              )}
                              <Button
                                variant="contained"
                                style={{
                                  borderRadius: "25px",
                                  textTransform: "capitalize",
                                }}
                                onClick={handlemodalClose}
                                startIcon={<CancelIcon />}
                              >
                                Cancel
                              </Button>
                            </Stack>
                          </div>
                        </div>
                        <div style={{ margin: "30px 0 0 15px" }}>
                          <Grid
                            container
                            spacing={2}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div style={{ minWidth: 200 }}>
                              <Typography style={typoStyle}>
                                {" "}
                                Site Name{" "}
                              </Typography>
                              <Autocomplete
                                size="small"
                                disablePortal
                                disableClearable
                                id="combo-box-demo"
                                defaultValue={
                                  editMode
                                    ? editValue
                                      ? editValue.siteName
                                      : null
                                    : null
                                }
                                disabled={editMode ? true : false}
                                options={siteIdName.map(
                                  (option) => option.siteName
                                )}
                                onChange={(data, event) =>
                                  handleSite(data, event)
                                }
                                sx={{ width: "11vw" }}
                                ListboxProps={{
                                  style: {
                                    maxHeight: "100px",
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="IFP"
                                    disabled={disabledSiteName}
                                  />
                                )}
                                classes={{ option: "autocomplete" }}
                              />
                            </div>
                            <div style={{ minWidth: 50, marginLeft: "40px" }}>
                              <Typography style={typoStyle}>Email</Typography>
                              {editValue ? (
                                <TextField
                                  id="outlined-basic"
                                  size="small"
                                  placeholder="Email"
                                  variant="outlined"
                                  error={!validEmail}
                                  helperText={
                                    !validEmail ? "Invalid email address" : ""
                                  }
                                  value={email}
                                  // defaultValue={
                                  //   editValue ? editValue.emailId : null
                                  // }
                                  onChange={handleEmailChange}
                                />
                              ) : (
                                <TextField
                                  id="outlined-basic"
                                  size="small"
                                  placeholder="Email"
                                  variant="outlined"
                                  sx={{ width: "11vw" }}
                                  error={!validEmail}
                                  helperText={
                                    !validEmail ? "Invalid email address" : ""
                                  }
                                  value={email}
                                  onChange={handleEmailChangevalue}
                                />
                              )}
                            </div>

                            <div style={{ minWidth: 200, marginLeft: "40px" }}>
                              <Typography style={typoStyle}>
                                Enable SMS
                              </Typography>
                              <Autocomplete
                                size="small"
                                disablePortal
                                disableClearable
                                id="enable-sms"
                                options={["Yes", "No"]}
                                defaultValue={enableSms}
                                onChange={(event, newValue) =>
                                  setEnableSms(newValue)
                                }
                                sx={{ width: "11vw" }}
                                ListboxProps={{
                                  style: {
                                    maxHeight: "100px",
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Enable SMS"
                                  />
                                )}
                              />
                            </div>
                          </Grid>
                          <div style={{ margin: "30px 0 0 0" }}>
                            <Stack spacing={0} direction="row"></Stack>
                          </div>
                        </div>
                        <Card
                          sx={{
                            width: "100%",
                            height: "48vh",
                            overflowY: "scroll",
                            marginTop: "-30px",
                          }}
                        >
                          <CardContent sx={{ width: "100%" }}>
                            <div>
                              <Grid container spacing={6} columns={27}>
                                <Grid
                                  item
                                  xs={4}
                                  sm={4}
                                  style={{ marginTop: "8.5px" }}
                                >
                                  <div style={{ flex: 1, minWidth: 150 }}>
                                    <Typography style={typoStyle}>
                                      Equipment
                                    </Typography>

                                    <Autocomplete
                                      disablePortal
                                      disableClearable
                                      id="combo-box-demo"
                                      size="small"
                                      value={
                                        editMode
                                          ? editValue?.equipmentName
                                          : equipmentName
                                      }
                                      disabled={!siteTemp || editMode}
                                      options={equipments.map(
                                        (option) => option.displayName
                                      )}
                                      onChange={(data, event) =>
                                        handleEquipment(data, event)
                                      }
                                      sx={{ width: "11vw" }}
                                      ListboxProps={{
                                        style: {
                                          maxHeight: "100px",
                                        },
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder=" INV-01"
                                          disabled={disabledEquipment}
                                        />
                                      )}
                                      classes={{ option: "autocomplete" }}
                                    />
                                  </div>
                                </Grid>

                                <Grid
                                  item
                                  xs={4.5}
                                  sm={4.5}
                                  style={{ marginTop: "8.5px" }}
                                >
                                  <div
                                    style={{
                                      flex: 1,
                                      minWidth: 150,
                                      marginLeft: "20px",
                                    }}
                                  >
                                    <Typography style={typoStyle}>
                                      Error Message
                                    </Typography>
                                    <TextField
                                      disablePortal
                                      id="combo-box-demo"
                                      size="small"
                                      name="errorMessage"
                                      placeholder="Grid Abnormal"
                                      defaultValue={
                                        editValue
                                          ? editValue.errorMessage
                                          : null
                                      }
                                      onChange={(event) => handleChange(event)}
                                      sx={{ width: "11vw" }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Select  Site ..."
                                        />
                                      )}
                                      classes={{ option: "autocomplete" }}
                                    />
                                  </div>
                                </Grid>
                                <Grid container item xs={4.5} sm={4.5}>
                                  <Grid item xs={12} md={6}>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        components={["TimePicker"]}
                                      >
                                        <div style={{ overflow: "hidden" }}>
                                          <Typography style={typoStyle}>
                                            From Time
                                          </Typography>
                                          <TimePicker
                                            ampm={false}
                                            defaultValue={
                                              editValue
                                                ? editValue.fromTime !== null
                                                  ? dayjs(
                                                      editValue.fromTime,
                                                      "HH:mm:ss"
                                                    )
                                                  : earliestFromTime
                                                : earliestFromTime
                                            }
                                            onChange={(event) => {
                                              handleChangeFromTime(event);
                                            }}
                                            minTime={dayjs()
                                              .startOf("day")
                                              .hour(5)}
                                            maxTime={dayjs()
                                              .startOf("day")
                                              .hour(19)}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                InputProps: {
                                                  style: {
                                                    overflow: "hidden",
                                                    width: "11vw",
                                                  },
                                                },
                                              },
                                            }}
                                          />
                                        </div>
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        components={["TimePicker"]}
                                      >
                                        <div style={{ overflow: "hidden" }}>
                                          <Typography style={typoStyle}>
                                            To Time
                                          </Typography>
                                          <TimePicker
                                            ampm={false}
                                            defaultValue={
                                              editValue
                                                ? editValue.toTime !== null
                                                  ? dayjs(
                                                      editValue.toTime,
                                                      "HH:mm:ss"
                                                    )
                                                  : latestToTime
                                                : latestToTime
                                            }
                                            onChange={(event) => {
                                              handleChangeToTime(event);
                                            }}
                                            minTime={fromTime}
                                            maxTime={latestToTime}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                InputProps: {
                                                  style: {
                                                    width: "11vw",
                                                  },
                                                },
                                              },
                                            }}
                                          />
                                        </div>
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </Grid>
                                </Grid>
                                {editState === true ? (
                                  // <Grid item xs={8}>
                                  //   <div style={{ flex: 1, minWidth: 150 }}>
                                  <Grid
                                    item
                                    xs={4.5}
                                    sm={4.5}
                                    style={{ marginTop: "8.5px" }}
                                  >
                                    <div style={{ flex: 1, minWidth: 150 }}>
                                      <FormControl sx={{ width: "78%" }}>
                                        <Typography style={typoStyle}>
                                          Status
                                        </Typography>
                                        <Autocomplete
                                          sx={{ width: "10vw" }}
                                          size="small"
                                          disablePortal
                                          id="user-status-combo-box"
                                          defaultValue={
                                            editValue &&
                                            editValue.activeFlag === 1
                                              ? Status[1] // Active
                                              : Status[0] // Inactive
                                          }
                                          options={Status}
                                          onChange={handleStatus}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              name="activeFlag"
                                            />
                                          )}
                                        />
                                      </FormControl>
                                    </div>
                                  </Grid>
                                ) : (
                                  ""
                                )}
                              </Grid>

                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  alignItems: "flex-start",
                                  marginTop: "20px",
                                }}
                              >
                                {parameterSets.slice(0, 3).map(
                                  (set, index) =>
                                    // (index === 0 || parameterSets[index - 1].condition === 'AND' || parameterSets[index - 1].condition === 'OR') && (
                                    (index === 0 ||
                                      (index > 0 &&
                                        (parameterSets[index - 1].condition ===
                                          "AND" ||
                                          parameterSets[index - 1].condition ===
                                            "OR")) ||
                                      set.isVisible) && (
                                      <React.Fragment key={index}>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "flex-end",
                                            marginRight: "20px",
                                            marginBottom: "20px",
                                            width:
                                              index === 0 ? "100%" : "auto",
                                            flexBasis:
                                              index === 2
                                                ? "100%"
                                                : "calc(50% - 20px)",
                                          }}
                                        >
                                          <div style={{ marginRight: "25px" }}>
                                            <Typography style={typoStyle}>
                                              Parameter
                                            </Typography>
                                            <Autocomplete
                                              disablePortal
                                              disableClearable
                                              id={`parameter-combo-box-${index}`}
                                              size="small"
                                              disabled={!siteTemp}
                                              value={set.parameter}
                                              options={equipmentParameter.map(
                                                (option) =>
                                                  option.stdParameterName
                                              )}
                                              onChange={(event, newValue) =>
                                                handleParameterChange(
                                                  index,
                                                  "parameter",
                                                  newValue
                                                )
                                              }
                                              sx={{ width: "11vw" }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  placeholder="Active Power"
                                                />
                                              )}
                                            />
                                          </div>
                                          <div style={{ marginRight: "25px" }}>
                                            <Typography style={typoStyle}>
                                              Logic
                                            </Typography>
                                            <Autocomplete
                                              disablePortal
                                              disableClearable
                                              id={`logic-combo-box-${index}`}
                                              size="small"
                                              value={set.logic}
                                              onChange={(event, newValue) =>
                                                handleParameterChange(
                                                  index,
                                                  "logic",
                                                  newValue
                                                )
                                              }
                                              options={Logic}
                                              sx={{ width: "11vw" }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  placeholder="Less"
                                                />
                                              )}
                                            />
                                          </div>
                                          <div style={{ marginRight: "10px" }}>
                                            <Typography style={typoStyle}>
                                              Value
                                            </Typography>
                                            <TextField
                                              size="small"
                                              name="value"
                                              placeholder="1"
                                              value={set.value}
                                              onChange={(event) => {
                                                handleParameterChange(
                                                  index,
                                                  "value",
                                                  event.target.value
                                                );
                                                handleInput(event);
                                              }}
                                              sx={{ width: "11vw" }}
                                            />
                                          </div>
                                        </div>
                                        {index < 2 && (
                                          <div
                                            style={{
                                              marginRight: "20px",
                                              marginBottom: "20px",
                                              width: "auto",
                                            }}
                                          >
                                            <Typography style={typoStyle}>
                                              Condition
                                            </Typography>
                                            <Autocomplete
                                              disablePortal
                                              disableClearable
                                              id={`condition-combo-box-${index}`}
                                              size="small"
                                              options={ParamCondition}
                                              sx={{ width: "11vw" }}
                                              value={
                                                ParamCondition.find(
                                                  (cond) =>
                                                    cond.value === set.condition
                                                ) || null
                                              }
                                              onChange={(event, newValue) =>
                                                handleConditionChange(
                                                  index,
                                                  newValue
                                                )
                                              }
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  placeholder="Select condition"
                                                />
                                              )}
                                            />
                                          </div>
                                        )}
                                      </React.Fragment>
                                    )
                                )}
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Paper>
                  </Box>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CustomizeAlerts;
