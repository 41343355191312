import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Stack,
  Autocomplete,
  Card,
  Tooltip,
  IconButton,
  MenuItem,
  Menu,
} from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FadeLoader from "react-spinners/FadeLoader";
import ExcelDataModal from "./ExcelDataModal";
import { downloadExcelTemplate } from "../../Template/ExcelTemplates/ExcelTemplate";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
// ========Modal================
import { styled } from "@mui/material/styles";
/*------------api call-----------------*/
import {
  fetchEquipmentTable,
} from "../../Api/EquipmenttypeApi";
import {
  Equipmentsitedropdowns,
  postDgrValueSave,
  getDgrValuesByDate,
} from "../../Api/DgrApi";
import { Getdropdownvalue } from "../../Api/MapsiteApi";
import CustomBreadcrumbs from "../util/components/CustomBread";
import { EquipmentType } from "../util/TextField";
import { Statusdropdown, fetchInstalltionCapacity } from "../../Api/CommonApi";
import CustomSnackbar from "../util/components/CustomSnackbar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
export default function DailyGeneration({ dailyGeneration, onFileSelect }) {
  const [SiteEquipment, setSiteEquipment] = useState([]);
  console.log(SiteEquipment, "SiteEquipment");
  const [equipmentvalue, setEquipmentValue] = useState([]);
  const [DownTime, setDownTime] = useState("");
  const [downn, setdownn] = useState("");
  console.log(downn, "downn");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedSite, setSelectedSite] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorIndex, setErrorIndex] = useState(null);
  const [selectedFileData, setSelectedFileData] = useState([]);
  const [previousExportData, setPreviousExportData] = useState([]);
const [previousImportData, setPreviousImportData] = useState([]);

  const [modalvalue, setModalvalue] = useState(true);
  const [excelOpen, setExcelOpen] = useState(false);
  const [equipment, setEquipment] = useState("");
  const [selectedMode, setSelectedMode] = useState("Manual");
  const [selectedParams, setSelectedParams] = useState("");

  const [toBackend, setToBackend] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [siteIdName, setSiteId] = useState([]);
  const [sitestatus, setSitestatus] = useState("");
  const [remarks, setRemarks] = useState("");
  console.log(remarks, "remarks");
  const [remarkvalue, setRemarkValue] = useState("");
  console.log(remarkvalue, ">>>>>>>");
  const remarkRefs = useRef([]);
  const [editcall, seteditcall] = useState();
  const [unitsIC, setUnitsIC] = useState([]);
  const [text, setTextField] = useState([]);
  const [generatedPayload, setGeneratedPayload] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const fileInputRef = useRef(null);
  const initalState = {};
  const [stdField, setStdField] = useState(initalState);
  console.log(stdField);
  console.log(stdField[0]?.downtime);
  const [siteMapping, setSiteMapping] = useState({});
  const [statusDrop, setStatusDrop] = useState([]);
  const initialSnack = { open: false, severity: "", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const [popAnchorEl, setPopAnchorEl] = useState(null);
  const [popOpen, setPopOpen] = useState(false);

  const isFieldDisabled = (fieldName) => {
    if (selectedMode === "Excel") {
      return fieldName === "date";
    }
    return false;
  };

  const requiredHeader = [
    "Site Name",
    "Date",
    "Equipment Name",
    "Today Energy (kWh)",
  ];
  const isOEM = sessionStorage.getItem("userTypeId") == 1;

  const ParamsToKey = {
    "Today Energy": "todayEnergy",
    "Active Power": "activePower",
    "Export and Import Energy": ["exportTotalEnergy", "importTotalEnergy"],
    "Irradiation": "irradiation"
  };
  const getInv = (data) => {
    return (
      data.equipmentCategory.toLowerCase().includes("central inverter") ||
      data.equipmentCategory.toLowerCase().includes("string inverter")
    );
  };
  const getMeter = (data) => {
    return [
      "energy meter",
      "primary energy meter",
      "secondary energy meter",
      "dg meter",
      "grid meter",
    ].some((keyword) => data.equipmentCategory.toLowerCase().includes(keyword));
  };
  const getWeatherStation = (data) => {
    return data.equipmentCategory.toLowerCase().includes("weather station");
  };
  const ParamsToCat = {
    "Today Energy": getInv,
    "Active Power": getInv,
    "Export Energy (MWh)": getMeter,
    "Import Energy (MWh)": getMeter,
    "Irradiation": getWeatherStation,
  };

 const parseExcelFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        const transformedData = jsonData.map((row) => ({
          siteId: parseInt(row.siteId),
          equipmentId: parseInt(row.equipmentId),
          timestamp: row.timestamp,
          ...(row.todayEnergy !== undefined && {
            todayEnergy: parseFloat(row.todayEnergy),
          }),
          ...(row.activePower !== undefined && {
            activePower: parseFloat(row.activePower),
          }),

          ...(row.importTotalEnergy !== undefined && {
            importTotalEnergy: parseFloat(row.importTotalEnergy),
          }),
          ...(row.exportTotalEnergy !== undefined && {
            exportTotalEnergy: parseFloat(row.exportTotalEnergy),
          }),
          ...(row.irradiation !== undefined && {
            irradiation: parseFloat(row.irradiation),
          }),
        }));

        resolve(transformedData);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };
  const handleUpload = async (event, setFieldSets, setSnack) => {
    const file = event.target.files[0];
    if (!file) {
      setSnack({
        open: true,
        severity: "error",
        message: "No file selected.",
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        const expectedHeaders = [
          "Site ID",
          "Equipment ID",
          "Date",
          "Today Energy",
        ];
        const headers = Object.keys(jsonData[0]);
        const isValidHeaders = expectedHeaders.every((header) =>
          headers.includes(header)
        );

        if (!isValidHeaders) {
          throw new Error("Invalid headers in the uploaded file.");
        }
        const transformedData = jsonData.map((row) => {
          const baseData = {
            siteId: parseInt(row["Site ID"]),
            equipmentId: parseInt(row["Equipment ID"]),
            timestamp: formatDate(row["Date"]),
          };

          if (row["Today Energy"] !== undefined && row["Today Energy"] !== "") {
            baseData.todayEnergy = parseFloat(row["Today Energy"]);
          }
          if (row["Active Power"] !== undefined && row["Active Power"] !== "") {
            baseData.activePower = parseFloat(row["Active Power"]);
          }
          if (row["Irradiation"] !== undefined && row["Irradiation"] !== "") {
            baseData.irradiation = parseFloat(row["Irradiation"]);
          }
          if (
            row["Import Energy"] !== undefined &&
            row["Import Energy"] !== ""
          ) {
            baseData.importTotalEnergy = parseFloat(row["Import Energy"]);
          }
          if (
            row["Export Energy"] !== undefined &&
            row["Export Energy"] !== ""
          ) {
            baseData.exportTotalEnergy = parseFloat(row["Export Energy"]);
          }

          return baseData;
        });
        const response = await postDgrValueSave(transformedData);
        if (response.success) {
          setSnack({
            open: true,
            severity: "success",
            message: "Excel file uploaded and data saved successfully.",
          });
          setFieldSets(transformedData);
        } else {
          throw new Error(response.statusDescription || "Failed to save data.");
        }
      } catch (error) {
        console.error("Error processing Excel file:", error);
        setSnack({
          open: true,
          severity: "error",
          message: error.message || "Error processing the Excel file.",
        });
      }
    };

    reader.readAsArrayBuffer(file);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; 
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };
  const excelDateToJSDate = (serial) => {
    const excelStartDate = new Date(1899, 11, 30); 
    const days = Math.floor(serial);
    const excelDate = new Date(
      excelStartDate.setDate(excelStartDate.getDate() + days)
    );
    return excelDate.toISOString().split("T")[0];
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      console.log(jsonData);
      const fixedData = jsonData.map((row) => {
        if (row.Date) {
          if (typeof row.Date === "number") {
            row.Date = excelDateToJSDate(row.Date).toISOString().split("T")[0];
          }
          row.timestamp = row.Date;
          delete row.Date;
        }
        return row;
      });
      postDgrValueSave(fixedData);
    };

    reader.readAsBinaryString(file);
  };

  const handleInput = (event) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
      console.log("File uploaded:", file);
    } else {
      console.error("No file selected");
    }
  };

  const hasDataToDownload = () => {
    return (
      selectedSite &&
      fieldSets.some(
        (fieldSet) =>
          fieldSet.equipmentName &&
          (fieldSet.todayEnergy ||
            fieldSet.activePower ||
            fieldSet.exportTotalEnergy ||
            fieldSet.importTotalEnergy ||
            fieldSet.irradiation)
      )
    );
  };

  /*----------------------------Fields-------------------------------*/
  const initialFieldSet = {
    equipmentName: "",
    equipmentId: "",
    timestamp: "",
    todayEnergy: "",
    activePower: "",
    exportTotalEnergy: "",
    importTotalEnergy: "",
    irradiation: "",
  };
  const [fieldSets, setFieldSets] = useState([initialFieldSet]);
  console.log(fieldSets);
  console.log("Field Sets after update:", fieldSets);
  useEffect(() => {
    if (siteIdName?.length > 0) {
      setSelectedSite(siteIdName[0].siteName);
    }
  }, [siteIdName]);
  const handleSite = async (event, value) => {
    setSitestatus(value);
    setSelectedSite(value);
    const site = siteIdName.find((item) => item.siteName === value);
    try {
      const [equipmentData, downtimeData] = await Promise.all([
        Equipmentsitedropdowns(site.siteId),
      ]);
      setEquipmentValue(equipmentData);
      const newFieldSets = equipmentData.map((equipment) => {
        return {
          equipmentName: equipment.displayName || "",
          equipmentId: equipment.equipmentId || "",
          equipmentCategory: equipment.equipmentCategory || "",
        };
      });
      setFieldSets(newFieldSets);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const toolStyle = {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  };
  /*--------------------------Download Excel ----------------------*/

  const handleDownload = () => {
    const data = fieldSets.map((fieldSet) => ({
      EquipmentName: fieldSet.equipmentName,
      TodayEnergy: fieldSet.todayEnergy || "",
      activePower: fieldSet.activePower || "",
      ExportTotalEnergy: fieldSet.exportTotalEnergy || "",
      ImportTotalEnergy: fieldSet.importTotalEnergy || "",
      Irradiation: fieldSet.irradiation || "",
    }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Equipment Data");
    XLSX.writeFile(workbook, "Equipment_Data.xlsx");
  };
  /*--------------------------Upload Excel ----------------------*/
  const findEquipmentId = (equipmentName) => {
    const equipment = equipmentvalue.filter(
      (item) => item.displayName === equipmentName
    );
    console.log(equipment, "equipment.equipmentId", equipment[0]?.equipmentId);
    return equipment[0]?.equipmentId;
  };
  const handleFile = (e) => {
    setPopOpen(true);
    setPopAnchorEl(e.currentTarget);
  };
  
const handleTemplateDownload = () => {
  const selectedCategory = ParamsToCat[selectedParams];

  let headers = ["Date"];
  if (selectedParams === "Export and Import Energy") {
    equipmentvalue.forEach((data) => {
      if (getMeter(data)) {
        headers.push(`${data.displayName} - Export Energy (MWh)`);
        headers.push(`${data.displayName} - Import Energy (MWh)`);
      }
    });
  } else {
    const returnVal = equipmentvalue
      .filter((data) => selectedCategory(data))
      .map((data) => data?.displayName);
    headers = ["Date", ...returnVal];
  }

  const ws = XLSX.utils.aoa_to_sheet([headers]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Template");
  XLSX.writeFile(wb, "DGRValue_Template.xlsx");
};

const handleFileUpload = async (e) => {
  setIsModalOpen(true);
  setPopAnchorEl(e.currentTarget);
  setPopOpen(false);

  const file = e.target.files[0];
  if (file) {
    if (!file.name.endsWith(".xlsx") && !file.name.endsWith(".xls")) {
      alert("Please upload a valid Excel file.");
      return;
    }

    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const headers = jsonData[0];

        const excelSerialToDate = (serial) => {
          const daysOffset = serial - 25569;
          const timestamp = new Date(daysOffset * 86400 * 1000);
          return timestamp.toISOString().split("T")[0];
        };

        const processedData = jsonData.slice(1).map((row) => {
          const rowData = {
            Date: excelSerialToDate(row[0]),
          };

          headers.slice(1).forEach((header, index) => {
            rowData[header] = row[index + 1];
          });

          return rowData;
        });

        console.log("Processed Data:", processedData);

        const id = sessionStorage.getItem("id");
        const dropdownData = await Getdropdownvalue(id);
        const siteData = dropdownData.find(
          (data) => data.siteName === selectedSite
        );

        const equipmentdrop = await Equipmentsitedropdowns(siteData?.siteId);

        const processedDataWithSiteAndEquipment = processedData.map((row) => {
          const newRow = { Date: row.Date }; 

          equipmentdrop.forEach((equipment) => {
            console.log("Equipment Category Check:", equipment.displayName, equipment.equipmentCategory);

            if (selectedParams === "Export and Import Energy" && getMeter(equipment)) {
              console.log("Equipment for Meter:", equipment.displayName);
              newRow[`${equipment.displayName} - Export Energy (MWh)`] =
                row[`${equipment.displayName} - Export Energy (MWh)`];
              newRow[`${equipment.displayName} - Import Energy (MWh)`] =
                row[`${equipment.displayName} - Import Energy (MWh)`];
            } else if (selectedParams !== "Export and Import Energy" && ParamsToCat[selectedParams] && ParamsToCat[selectedParams](equipment)) {
              console.log("Equipment for Category:", equipment.displayName);
              newRow[equipment.displayName] = row[equipment.displayName];
            } else {
              console.log("Equipment not matched:", equipment.displayName);
            }
          });

          return newRow;
        });

        console.log(
          "Processed Data with Site and Equipment:",
          processedDataWithSiteAndEquipment
        );

        const payload = processedDataWithSiteAndEquipment.map((row) => {
          const result = [];
          equipmentdrop.forEach((equipment) => {
            const equipmentName = equipment.displayName;
            if (selectedParams === "Export and Import Energy" && getMeter(equipment)) {
              const exportKey = `${equipmentName} - Export Energy (MWh)`;
              const importKey = `${equipmentName} - Import Energy (MWh)`;
              const exportValue = row[exportKey];
              const importValue = row[importKey];

              if (exportValue != null || importValue != null) {
                result.push({
                  timestamp: row.Date,
                  siteId: siteData.siteId,
                  equipmentId: equipment.equipmentId,
                  exportTotalEnergy: exportValue || null,
                  importTotalEnergy: importValue || null,
                });
              }
            } else if (ParamsToCat[selectedParams] && ParamsToCat[selectedParams](equipment)) {
              result.push({
                timestamp: row.Date,
                siteId: siteData.siteId,
                equipmentId: equipment.equipmentId,
                [ParamsToKey[selectedParams]]: row[equipmentName] || null,
              });
            } else {
              console.log("Equipment not processed:", equipment.displayName);
            }
          });
          return result;
        }).flat();

        console.log("Payload:", payload);
        setGeneratedPayload(payload);

        setSelectedFileData(processedDataWithSiteAndEquipment);
        setFieldSets(processedData);
        setIsModalOpen(true);

      } catch (error) {
        console.error("Error processing the file:", error);
        alert(
          "There was an error processing the file. Please check the file format and try again."
        );
      }
    };

    reader.readAsArrayBuffer(file);
  }
};

  console.log(fieldSets, "equipmentvalueequipmentvalue");

  const handleModeChange = (event, value) => {
    setSelectedMode(value);
    setSelectedSite(null);
    setSelectedDate(null);
    setSelectedParams(null);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const validateData = (data) => {
    const validData = [];
    const errorRows = [];

    data.forEach((row, index) => {
      const errors = [];
      if (!row["Field1"] || row["Field1"].length > 50)
        errors.push("Field1 error");
      if (!row["Description"] || row["Description"].length > 100)
        errors.push("Description error");
      if (errors.length > 0) {
        errorRows.push({ index, errors });
      } else {
        validData.push(row);
      }
    });

    return { validData, errorRows };
  };
  const processUploadedData = (data) => {
    try {
      const processedData = data.map((row) => ({
        equipmentName: row.equipmentName || "",
        todayEnergy: row.todayEnergy || "",
        exportEnergy: row.exportEnergy || "",
        importEnergy: row.importEnergy || "",
        irradiation: row.irradiation || "",
        ambient: row.ambient || "",
      }));

      setFieldSets(processedData);

      setSnack({
        open: true,
        severity: "success",
        message: "Data uploaded successfully",
      });
    } catch (error) {
      console.error("Error processing uploaded data:", error);
      setSnack({
        open: true,
        severity: "error",
        message: "Error processing the uploaded data",
      });
    }
  };

  /*------------------------- Api --------------------------------------------*/
  useEffect(() => {
    fetchData();
    fetchStatus();
    fetchUnits();
    fetchSiteList();
  }, []);
  useEffect(() => {
    let data = EquipmentType();
    setTextField(data);
  }, []);
  useEffect(() => {
    console.log("Field Sets updated:", fieldSets);
  }, [fieldSets]);
  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === "object" && data !== null) {
      setStatusDrop([data]);
    }
  };
  const fetchUnits = async () => {
    try {
      let data = await fetchInstalltionCapacity();
      console.log(data, "dataOutside");
      setUnitsIC(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (siteIdName?.length > 0) {
      setSelectedSite(siteIdName[0].siteName);
      fetchDataForDate();
    }
  }, [siteIdName]);

  const fetchSiteList = async () => {
    try {
      const id = sessionStorage.getItem("id");
      let data = await Getdropdownvalue(id);

      if (Array.isArray(data)) {
        const dynamicSiteMapping = data.reduce((acc, site) => {
          acc[site.siteName] = site.siteId;
          return acc;
        }, {});

        setSiteMapping(dynamicSiteMapping);
        setSiteId(data);
        setLoading(false);
      } else if (typeof data === "object" && data !== null) {
        setSiteId([data]);
      }
      setLoading(false);
      await fetchDataForDate(data[0]?.siteId, data[0]?.siteName);
    } catch (e) {
      console.error(e, "errrrrror");
    }
  };

  console.log(siteIdName, "siteIdName");
  const fetchDataForDate = async (date) => {
    setLoading(true);
    setFieldSets([]); 
    try {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      const site = siteIdName.find((site) => site.siteName === selectedSite);
      if (!site) {
        throw new Error("Selected site not found");
      }
      const data = await getDgrValuesByDate(site.siteId, formattedDate);
      updateFieldSetsWithData(data);
    } catch (error) {
      console.error("Error fetching data for date:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateFieldSetsWithData = (data) => {
    const updatedFieldSets = fieldSets.map((fieldSet) => {
      const matchingData = data.find(
        (item) => item.equipmentId === fieldSet.equipmentId
      );
      if (matchingData) {
        return {
          ...fieldSet,
          todayEnergy: matchingData.todayEnergy,
          activePower: matchingData.activePower,
          exportTotalEnergy: matchingData.exportTotalEnergy,
          importTotalEnergy: matchingData.importTotalEnergy,
          irradiation: matchingData.irradiation,
        };
      }
      return fieldSet;
    });
    setFieldSets(updatedFieldSets);
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date);
    setSelectedDate(formattedDate);
    setFieldSets([]); 
    console.log("Selected Date:", formattedDate.format("YYYY-MM-DD"));
    fetchDataForDate(formattedDate);
  };

  const handleInputChange = (event, fieldName, index) => {
    const value = event.target.value;
    setFieldSets((prevFieldSets) => {
      const updatedFieldSets = [...prevFieldSets];
      updatedFieldSets[index] = {
        ...updatedFieldSets[index],
        [fieldName]: value,
      };
      return updatedFieldSets;
    });
  };

  const excelSerialToDate = (serial) => {
    const daysOffset = serial - 25569; 
    const date = new Date(daysOffset * 86400 * 1000);
    return date.toISOString(); 
  };

  /*------------------------- handleSave ---------------------------*/
  const handleSave = async () => {
    const selectedSite = siteIdName.find(
      (site) => site.siteName === sitestatus
    );

    if (!selectedSite || !selectedDate) {
      setSnack({
        open: true,
        severity: "error",
        message: "Please select both a site and a date.",
      });
      return;
    }

    const formattedDate = selectedDate.format("YYYY-MM-DD");

    console.log("Current fieldSets:", fieldSets);

    let data = fieldSets.map((fieldSet) => {
      let payload = {
        siteId: selectedSite.siteId,
        equipmentId: fieldSet.equipmentId,
        timestamp: formattedDate,
      };

      const equipmentNameLower = fieldSet.equipmentName.toLowerCase();
      console.log("Processing equipment:", equipmentNameLower);
      const addNumericValue = (key, value) => {
        const numValue = parseFloat(value);
        if (!isNaN(numValue)) {
          payload[key] = numValue;
        } else if (value === "") {
          payload[key] = 0; 
        } else {
          console.log(`Invalid value for ${key}:`, value);
        }
      };
      addNumericValue("todayEnergy", fieldSet.todayEnergy);
      addNumericValue("activePower", fieldSet.activePower);
      addNumericValue("exportTotalEnergy", fieldSet.exportTotalEnergy);
      addNumericValue("importTotalEnergy", fieldSet.importTotalEnergy);
      addNumericValue("irradiation", fieldSet.irradiation);

      console.log("Payload for equipment:", fieldSet.equipmentName, payload);
      return payload;
    });

    console.log("Data to be sent:", data);

    if (data.length === 0) {
      setSnack({
        open: true,
        severity: "error",
        message: "No data to save. Please check your inputs.",
      });
      return;
    }

    try {
      const response = await postDgrValueSave(data);
      console.log("Data saved successfully:", response);
      setFieldSets([initialFieldSet]);
      setSelectedDate(null);
      setSitestatus("");
      setSiteId([]);
      setRemarks("");
      setSnack({
        open: true,
        severity: "success",
        message: response.statusDescription || "Data saved successfully",
      });
      window.location.reload();
    } catch (error) {
      console.error("Error saving data:", error);
      setSnack({
        open: true,
        severity: "error",
        message: "Error saving data. Please try again.",
      });
    }
  };
  const handleSubmit = async () => {
    setToBackend(true);
    console.log("handleSubmit called");
  
    try {
      console.log("Generated payload:", generatedPayload);
      console.log("Selected file data:", selectedFileData);
      console.log("Selected parameters:", selectedParams);
      const dataMap = new Map();
  
      selectedFileData.forEach((row) => {
        const rowKeys = Object.keys(row).filter(
          (key) => key !== "Date" && key !== "equipmentId" && key !== "SiteId"
        );
  
        rowKeys.forEach((key) => {
          const equipmentName = key.split(" - ")[0];
          const siteId =
            siteIdName.find((data) => data.siteName === selectedSite)?.siteId || "";
          const equipmentId =
            equipmentvalue.find((item) => item.displayName === equipmentName)
              ?.equipmentId || "";
  
          const id = `${siteId}-${equipmentId}-${row.Date}`;
  
          if (!dataMap.has(id)) {
            dataMap.set(id, {
              siteId,
              equipmentId,
              timestamp: row.Date || "",
              exportTotalEnergy: null,
              importTotalEnergy: null,
            });
          }
  
          const entry = dataMap.get(id);
  
          if (key.includes("Export")) {
            entry.exportTotalEnergy = row[key] || null;
          } else if (key.includes("Import")) {
            entry.importTotalEnergy = row[key] || null;
          } else {
            entry[ParamsToKey[selectedParams]] = row[key] || null;
          }
        });
      });
  
      const payload = Array.from(dataMap.values());
  
      console.log("Generated payload:", payload);
  
      const response = await postDgrValueSave(payload); 
      console.log("Data saved successfully:", response);
      if (response.status === 200) {
        setSnack({
          open: true,
          severity: "success",
          message: response.statusDescription || "Data saved successfully",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response?.status === 400) {
        setSnack({
          open: true,
          severity: "error",
          message: error.response.data.message || "An error occurred",
        });
      } else {
        setSnack({
          open: true,
          severity: "error",
          message: "Unexpected error occurred",
        });
        console.error("Unexpected error:", error);
      }
    }
  };

  /*-------------------------Table get call --------------------------------------------*/
  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchEquipmentTable();
      if (Array.isArray(data)) {
        setSiteEquipment(data);
        setLoading(false);
      } else if (typeof data === "object" && data !== null) {
        setSiteEquipment([data]);
      }
      setLoading(false);
      console.log(data);
    } catch (e) {
      console.error(e);
    }
  };
  /*---------------------------------- New Equipment Call -----------------------*/
  const handleClose = () => {
    setOpen(false);
    seteditcall([]);
    setEdit(false);
    setEquipment("");
    setPopOpen(false); 
    setPopAnchorEl(null); 
  };
  const handleUploadClick = () => {
    fileInputRef.current.click(); 
  };
  const Item = styled(Paper)(({ theme }) => ({}));
  const Total = SiteEquipment.length;
  const Active = SiteEquipment.filter((site) => site.status == 1).length;
  const Inactive = SiteEquipment.filter((site) => site.status == 0).length;
  console.log(Active);
  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Daily Generation Value", path: "dgr" },
  ];
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <CustomSnackbar
            open={snack.open}
            onClose={() => setSnack(initialSnack)}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />
          <div>
            <Box
              sx={{
                margin: "0 0.5% 0 0.5%",
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  mr: 0,
                  mb: 1,
                  borderRadius: 0,
                  width: "100%",
                  height: "7.5vh",
                },
              }}
            >
              <Card
                elevation={0}
                style={{
                  display: "flex",
                  width: "101%",
                  height: "6.5vh",
                  borderRadius: "2px 2px 0px 0px",
                  userSelect: "none",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CustomBreadcrumbs
                    paths={paths || []}
                    separatorSize="18px"
                    fontSize="14px"
                  />
                </div>
              </Card>
            </Box>
          </div>
          <div>
            <Box
              sx={{
                margin: "0 0.5% 0 0.5%",
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  mr: 0,
                  mb: 1,
                  borderRadius: 0,
                  width: "100%",
                  height: "7.5vh",
                },
              }}
            >
              <Card
                elevation={0}
                style={{
                  display: "flex",
                  width: "100%",
                  height: "13vh",
                  borderRadius: "2px 2px 0px 0px",
                  userSelect: "none",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0 0 0 30px",
                  }}
                >
                  <div style={{ margin: "10px" }}>
                    <Typography className="modal-typo">Mode</Typography>
                    <div>
                      <Autocomplete
                        id="mode-select"
                        options={["Excel", "Manual"]}
                        getOptionLabel={(option) => option}
                        value={selectedMode}
                        onChange={(event, value) =>
                          setSelectedMode(value || "Excel")
                        }
                        sx={{ width: "14vw" }}
                        ListboxProps={{
                          style: {
                            maxHeight: "200px",
                          },
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{}}
                            placeholder="Select Mode"
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div style={{ margin: "10px" }}>
                    <Typography className="modal-typo">Site Name *</Typography>
                    <div>
                      <Autocomplete
                        id="combo-box-demo"
                        options={siteIdName} 
                        getOptionLabel={(option) => option.siteName || ""} 
                        defaultValue={edit ? edit.siteName : null}
                        onChange={(event, value) =>
                          handleSite(event, value?.siteName)
                        }
                        value={
                          siteIdName.find(
                            (site) => site.siteName === selectedSite
                          ) || null
                        }
                        sx={{ width: "14vw" }}
                        ListboxProps={{
                          style: {
                            maxHeight: "200px",
                          },
                        }}
                        size="small"
                        disabled={false} 
                        renderInput={(params) => (
                          <TextField {...params} sx={{}} placeholder="IFP" />
                        )}
                      />
                    </div>
                  </div>
                  <div style={{ margin: "10px" }}>
                    <Typography className="modal-typo" sx={{ width: "14vw" }}>
                      Date
                    </Typography>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          size="small"
                          sx={{ width: "14vw" }}
                          value={dayjs(selectedDate)}
                          onChange={handleDateChange}
                          disabled={isFieldDisabled("date")}
                          maxDate={dayjs()} 
                          slotProps={{
                            textField: {
                              size: "small",
                              disabled: isFieldDisabled("date"),
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div style={{ margin: "10px" }}>
                    <Typography className="modal-typo">Parameters</Typography>
                    <div>
                      <Autocomplete
                        id="mode-select"
                        options={[
                          "Today Energy",
                          "Active Power",
                          "Export and Import Energy",
                          "Irradiation",
                        ]}
                        getOptionLabel={(option) => option}
                        value={selectedParams}
                        onChange={(event, value) => { 
                          console.log("Selected params:", value); 
                          setSelectedParams(value);
                           }}
                        size="small"
                        sx={{ width: "14vw" }}
                        disabled={false} 
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Select Mode" />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <Stack spacing={2} direction="row">
                    {/* <Box sx={{ width: "100%" }}> */}
                    <div style={{ margin: "0 30px 0 0" }}>
                      <Tooltip
                        title="Upload Excel"
                        userSelect="none"
                        followCursor
                        PopperProps={toolStyle}
                      >
                        <IconButton
                          variant="text"
                          onClick={handleFile}
                          disabled={
                            selectedMode === "Manual" ||
                            selectedParams === "" ||
                            equipmentvalue.length === 0
                          }
                          style={{
                            textTransform: "capitalize",
                            color:
                              selectedMode === "Manual" ||
                              selectedParams === "" ||
                              equipmentvalue.length === 0
                                ? "grey"
                                : "#004AFF",
                            padding: "0",
                            borderRadius: "20px 20px 20px 20px",
                          }}
                          sx={{
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                        >
                          <FileUploadOutlinedIcon
                            style={{
                              fontSize: "25px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="upload-menu"
                        open={popOpen}
                        anchorEl={popAnchorEl}
                        onClose={handleClose}
                        style={{ marginLeft: "-0.5%", marginTop: "0.5%" }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            handleTemplateDownload();
                          }}
                          style={{
                            textTransform: "capitalize",
                            padding: "15px",
                            color: "#1976D2",
                          }}
                        >
                          <SaveAltIcon fontSize="small" />
                          <Typography
                            style={{ marginLeft: "3%", fontSize: "14px" }}
                          >
                            Download Template
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={handleUploadClick}
                          style={{
                            textTransform: "capitalize",
                            padding: "15px",
                            color: "#d32f2f",
                          }}
                        >
                          <FileUploadOutlinedIcon fontSize="small" />
                          <Typography
                            style={{ marginLeft: "3%", fontSize: "14px" }}
                          >
                            Upload Excel
                          </Typography>
                        </MenuItem>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }} 
                          onChange={handleFileUpload}
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                      </Menu>

                      <Tooltip
                        title="Download Data"
                        userSelect="none"
                        followCursor
                        PopperProps={toolStyle}
                      >
                        <IconButton
                          disabled={!hasDataToDownload()}
                          variant="text"
                          onClick={handleDownload}
                          style={{
                            textTransform: "capitalize",
                            color: "#004AFF",
                            padding: "0",
                            borderRadius: "20px 20px 20px 20px",
                            marginLeft: "10px",
                          }}
                          sx={{
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                        >
                          <SaveAltIcon
                            style={{
                              fontSize: "25px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>

                    <Button
                      variant="contained"
                      style={{
                        borderRadius: "25px",
                        textTransform: "capitalize",
                        margin: "0 20px 0 0",
                      }}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      disabled={toBackend}
                      style={{
                        borderRadius: "25px",
                        textTransform: "capitalize",
                        margin: "0 20px 0 0",
                      }}
                    >
                      Cancel
                    </Button>
                    {/* </Box> */}
                  </Stack>
                </div>
              </Card>
            </Box>
          </div>

          <div>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 1,
                  width: "100%",
                  height: "57vh",
                },
              }}
            >
              <Paper elevation={0} sx={{ overflowY: "auto", height: "100%" }}>
                {sitestatus ? (
                  <div
                    style={{
                      padding: "25px",
                    }}
                  >
                    {selectedMode === "Manual" && (
                      <div>
                        {fieldSets.map((fieldSet, index) => (
                          <Grid
                            container
                            spacing={2}
                            style={{ marginBottom: "20px" }}
                            key={index}
                          >
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                              <Typography className="modal-typo" gutterBottom>
                                Equipment Name *
                              </Typography>
                              <Typography
                                sx={{
                                  width: "100%",
                                  padding: "8.5px 14px",
                                  fontSize: "1rem",
                                  backgroundColor: "white",
                                  borderRadius: "4px",
                                  minHeight: "1.4375em",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {fieldSet.equipmentName}
                              </Typography>
                            </Grid>

                            {/* Condition for Central Inverter or String Inverter */}
                            {fieldSet.equipmentCategory &&
                              (fieldSet.equipmentCategory
                                .toLowerCase()
                                .includes("central inverter") ||
                                fieldSet.equipmentCategory
                                  .toLowerCase()
                                  .includes("string inverter")) && (
                                <>
                                  <Grid item xs={12} sm={6} md={3} lg={2}>
                                    <Typography
                                      className="modal-typo"
                                      gutterBottom
                                    >
                                      Today Energy (kWh) *
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      size="small"
                                      name="todayEnergy"
                                      placeholder="283"
                                      value={fieldSet.todayEnergy || ""}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "todayEnergy",
                                          index
                                        )
                                      }
                                    />
                                  </Grid>
                                  {/* <Grid item xs={12} sm={6} md={3} lg={2}>
                                    <Typography
                                      className="modal-typo"
                                      gutterBottom
                                    >
                                      Active Power *
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      size="small"
                                      name="activePower"
                                      placeholder="283"
                                      value={fieldSet.activePower || ""}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "activePower",
                                          index
                                        )
                                      }
                                    />
                                  </Grid> */}
                                </>
                              )}

                            {/* Condition for Energy Meter */}
                            {fieldSet.equipmentCategory &&
                              [
                                "energy meter",
                                "primary energy meter",
                                "secondary energy meter",
                                "dg meter",
                                "grid meter",
                              ].some((keyword) =>
                                fieldSet.equipmentCategory
                                  .toLowerCase()
                                  .includes(keyword)
                              ) && (
                                <>
                                  <Grid item xs={12} sm={6} md={3} lg={2}>
                                    <Typography
                                      className="modal-typo"
                                      gutterBottom
                                    >
                                      Export Energy (MWh) *
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      size="small"
                                      name="exportTotalEnergy"
                                      placeholder="384.38"
                                      value={fieldSet.exportTotalEnergy || ""}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "exportTotalEnergy",
                                          index
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={3} lg={2}>
                                    <Typography
                                      className="modal-typo"
                                      gutterBottom
                                    >
                                      Import Energy (MWh) *
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      size="small"
                                      name="importTotalEnergy"
                                      placeholder="382.46"
                                      value={fieldSet.importTotalEnergy || ""}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "importTotalEnergy",
                                          index
                                        )
                                      }
                                    />
                                  </Grid>
                                </>
                              )}

                            {/* Condition for Weather Station */}
                            {fieldSet.equipmentCategory &&
                              fieldSet.equipmentCategory
                                .toLowerCase()
                                .includes("weather station") && (
                                <Grid item xs={12} sm={6} md={3} lg={2}>
                                  <Typography
                                    className="modal-typo"
                                    gutterBottom
                                  >
                                    Irradiation *
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    name="irradiation"
                                    placeholder="7.83"
                                    value={fieldSet.irradiation || ""}
                                    onChange={(e) =>
                                      handleInputChange(e, "irradiation", index)
                                    }
                                  />
                                </Grid>
                              )}
                          </Grid>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      position: "fixed",
                      textAlign: "center",
                      left: "40%",
                      top: "61%",
                    }}
                  >
                    {/* <Typography>
                      No equipment has been configured for this site yet
                    </Typography> */}
                    {selectedMode === "Excel" ? (
                      <Typography>
                        Please select a site, download the template, and upload
                        the data.
                      </Typography>
                    ) : (
                      <Typography>
                        No equipment has been configured for this site yet
                      </Typography>
                    )}
                  </div>
                )}
              </Paper>
            </Box>
          </div>
        </div>
      )}
      <div>
        <ExcelDataModal
          open={isModalOpen}
          handleClose={handleCloseModal}
          handleSave={handleSubmit}
          data={selectedFileData}
          siteName={selectedSite}
          Param={selectedParams}
          siteId={siteIdName.find((data) => data.siteName === selectedSite)}
        />
      </div>
    </div>
  );
}