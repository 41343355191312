import React, { useEffect, useState, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import {
  CheckForLight,
  formatNumber,
  getUnit,
} from "../util/common/CommanFunction";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Card, CardContent, IconButton, Typography } from "@mui/material";
import { min } from "date-fns";

// Initialize Highcharts modules
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

const AreaChart = ({
  tableData,
  WidgetName,
  chartName,
  customView,
  fallBack,
  isDashboard,
  customName,
  from,
}) => {
  const [seriesData, setSeriesData] = useState([]);
  const [showTable, setShowTable] = React.useState(false);
  const [tableContent, setTableContent] = React.useState("");
  console.log(seriesData, "seriesData");
  console.log(tableData, "tableData");
  const daily =
    customView?.range === "daily" || customView?.range === "today"
      ? true
      : false;
  // console.log(chartName);
  const isLight = CheckForLight();

  const DataPresent =
    tableData?.dgMeter?.length > 0 ||
    tableData?.gridMeter?.length > 0 ||
    tableData?.solarMeter?.length > 0;

  console.log(DataPresent, "DataPresent");

  useEffect(() => {
    let tabledata = tableData;
    if (DataPresent) {
      // for (let i = 1; i < tabledata.gridMeter?.length; i++) {
      //   const currentTimestamp = new Date(tabledata.gridMeter[i].timeStamp);
      //   const prevTimestamp = new Date(tabledata.gridMeter[i - 1].timeStamp);
      //   const timeDiffMinutes =
      //     (currentTimestamp - prevTimestamp) / (1000 * 60);

      //   // Check for timeStamp gaps greater than 5 minutes
      //   if (timeDiffMinutes > 5) {
      //     // Add missing timestamps every 5 minutes with todayEnergy set to 0
      //     for (let j = 1; j < Math.floor(timeDiffMinutes / 5); j++) {
      //       const missingTimestamp = new Date(
      //         prevTimestamp.getTime() + j * 5 * 60 * 1000
      //       );
      //       tabledata.gridMeter.splice(i + j - 1, 0, {
      //         todayEnergy: 0,
      //         timeStamp: missingTimestamp.toISOString(),
      //       });
      //     }
      //   }
      // }
      // Check if todayEnergy is null and set it to 0 for all meters
      ["dgMeter", "gridMeter", "solarMeter"].forEach((meter) => {
        if (tabledata[meter]) {
          tabledata[meter].forEach((entry) => {
            if (entry.todayEnergy === null) {
              entry.todayEnergy = null;
            }
          });
        }
      });
      const formattedData = Object?.keys(tabledata).map((meterName) => {
        return {
          name:
            meterName.charAt(0).toUpperCase() +
            meterName.slice(1).replace("meter", ""),
          data: tableData[meterName]?.map((entry) => ({
            x: new Date(entry.timeStamp).getTime(),
            y: parseFloat(entry.todayEnergy),
          })),
        };
      });

      setSeriesData(formattedData);
    }

    // console.log(formattedData, "formattedData");
  }, [tableData]);

  const minMaxTimeStamp = useMemo(() => {
    if (!tableData) return {};

    const timestamps = Object.values(tableData).flatMap((entries) => {
      if (!Array.isArray(entries)) {
        console.error(`Entries is not an array:`, entries);
        return [];
      }

      return entries
        .map((entry) => {
          const time = new Date(entry.timeStamp).getTime();
          if (isNaN(time)) {
            console.error("Invalid date:", entry.timeStamp);
          }
          return time;
        })
        .filter((time) => !isNaN(time));
    });

    if (timestamps.length === 0) {
      console.error("No valid timestamps found");
      return {};
    }

    const minTimestamp = Math.min(...timestamps) - 30 * 60 * 1000;
    const maxTimestamp = Math.max(...timestamps);

    return { minTimestamp, maxTimestamp };
  }, [tableData]);

  // console.log(minMaxTimeStamp, "minMaxTimeStamp");
  const nameForFile = isDashboard ? customName : WidgetName;
  const columnChartOptions = {
    chart: {
      type: "area",
      backgroundColor: isLight ? "white" : "#121212",
    },
    title: {
      text: WidgetName,
      style: {
        textTransform: "captalize",
        color: isLight ? "black" : "white",
        fontWeight: "normal",
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      filename: nameForFile + "-" + new Date().toLocaleDateString("en-GB"),
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadJPEG",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadCSV",
            "downloadXLS",
            from === "Vanalytics"
              ? {
                  text: "View Table",
                  onclick: function () {
                    const chart = this;
                    let dataHtml = chart.getTable(); // Generate HTML table from chart data

                    // // Replace empty cells with 0
                    // dataHtml = dataHtml.replace(
                    //   /<td class="highcharts-empty">\s*<\/td>/g,
                    //   '<td class="highcharts-number">0</td>'
                    // );
                    setTableContent(dataHtml); // Store the table content in state
                    setShowTable(true); // Show the table
                  },
                }
              : "",
          ],
        },
      },
      enabled: true,
      // showTable: true,
      csv: {
        columnHeaderFormatter: function (item, key) {
          if (item.isXAxis) {
            return item.options.title.text || item.name;
          }
          if (key === "y") {
            return `${item.name}${getUnit(item.name.replace(/\s+/g, ""))}`;
          }
        },
      },
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        hour: "%H:%M",
        day: "%d/%m/%Y",
      },
      min: minMaxTimeStamp.minTimestamp - 60 * 60 * 1000,
      max: minMaxTimeStamp.maxTimestamp + 60 * 60 * 1000,
      tickInterval: 60 * 60 * 1000,
      labels: {
        rotation: -45,
        style: {
          color: isLight ? "black" : "white",
        },
        formatter: function () {
          const localDate = new Date(this.value);
          const options = {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
            //   day: "numeric",
            // month: "short",
          };
          return new Intl.DateTimeFormat("en-US", options).format(localDate);
        },
      },
      title: {
        text: "Time (HH:MM)",
        style: {
          color: isLight ? "black" : "white",
          // fontSize: "0.8em",
          fontFamily: "Inter",
        },
      },
    },

    yAxis: [
      {
        title: {
          text: "Active Power (W)",
        },
        // min: 0,
        style: {
          color: isLight ? "black" : "white",
          fontSize: "0.8em",
          fontFamily: "Inter",
        },
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
    ],
    plotOptions: {
      area: {
        pointStart: minMaxTimeStamp.minTimestamp,
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        events: {
          legendItemClick: function () {
            const series = this.chart.series;
            const currentSeries = this;

            if (series.length === 2) {
              const [series1, series2] = series;

              if (currentSeries === series1) {
                // If series1 is clicked
                if (series1.visible && !series2.visible) {
                  // Both series should be visible if series1 is visible and series2 is not
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                } else if (!series1.visible && series2.visible) {
                  // series1 should be visible and series2 should be hidden if series1 is not visible and series2 is visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: false }, false);
                } else if (series1.visible && series2.visible) {
                  // If both are visible, make series2 invisible, and series1 remains visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: false }, false);
                } else if (!series1.visible && !series2.visible) {
                  // If both are not visible, make both visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                }
              } else if (currentSeries === series2) {
                // If series2 is clicked
                if (series2.visible && !series1.visible) {
                  // Both series should be visible if series2 is visible and series1 is not
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                } else if (!series2.visible && series1.visible) {
                  // series2 should be visible and series1 should be hidden if series2 is not visible and series1 is visible
                  series1.update({ visible: false }, false);
                  series2.update({ visible: true }, false);
                } else if (series1.visible && series2.visible) {
                  // If both are visible, make series1 invisible, and series2 remains visible
                  series1.update({ visible: false }, false);
                  series2.update({ visible: true }, false);
                } else if (!series2.visible && !series1.visible) {
                  // If both are not visible, make both visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                }
              }

              // Finally, redraw the chart
              this.chart.redraw();
            } else {
              // Handle charts with more than two series or other cases
              this.chart.update(
                {
                  series: series.map((s) => {
                    if (s === currentSeries) {
                      return {
                        visible: currentSeries.visible ? false : true,
                      };
                    } else {
                      const visbleSeries = series.filter(
                        (series) => series.visible
                      );
                      console.log(
                        visbleSeries.length > 1 &&
                          visbleSeries.length < series.length - 1,
                        visbleSeries
                      );

                      if (
                        visbleSeries.length > 1 &&
                        visbleSeries.length < series.length - 1
                      ) {
                        visbleSeries.forEach((series) => {
                          series.update({ visible: false }, false);
                        });
                      }
                      return {
                        visible: s.visible
                          ? visbleSeries.length > 1 &&
                            visbleSeries.length < series.length - 1
                            ? true
                            : false
                          : true,
                      };
                    }
                  }),
                },
                true
              );
            }

            return false; // Prevent the default action of hiding the clicked series
          },
        },
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: isLight ? "white" : "#121212",
      formatter: function () {
        const localDate = new Date(this.point.x);
        const options = {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
          day: "numeric",
          month: "short",
        };
        const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
          localDate
        );

        let tooltipContent = `<div class="custom-tooltip" style="color:${
          isLight ? "black" : "white"
        };">
                                <b>Active Power</b>
                                <br/>
                                <b>${formattedDate}</b>`;

        this.series.chart.series.forEach((series) => {
          const seriesName = series.name;
          const pointIndex = series.xData.indexOf(this.point.x);
          if (pointIndex !== -1) {
            const yValue = formatNumber(series.yData[pointIndex]);
            tooltipContent += `<p>${seriesName}: ${yValue} (kW)</p>`;
          }
        });

        tooltipContent += `</div>`;
        return tooltipContent;
      },
    },
    series: seriesData?.map((series) => {
      if (series?.name?.includes("Grid") || series?.name?.includes("grid")) {
        series.name = "Grid ";
        series.color = "rgba(128, 128, 128, 0.7)"; // grey color
      } else if (
        series?.name?.includes("Solar") ||
        series?.name?.includes("solar")
      ) {
        series.name = "Solar ";
        series.color = "rgba(255, 165, 0, 0.5)"; // orange color
        // if (!tableData.dgMeter || tableData.dgMeter.length === 0) {
        //   series.lineColor = "#FE5000";
        // }
      } else if (series.name.includes("dg") || series.name.includes("Dg")) {
        series.name = "DG ";
        series.color = "rgba(30, 144, 255, 0.7)"; // blue color
      }
      return series;
    }),
    legend: {
      maxHeight: 110,
      itemStyle: {
        color: isLight ? "black" : "white",
      },
      itemHoverStyle: {
        color: isLight ? "gray" : "lightgray",
      },
    },
  };
  console.log(columnChartOptions);

  return (
    <div
      style={{
        width: "100%",
        height: isDashboard
          ? "300px"
          : from === "Vanalytics"
          ? "500px"
          : "fit-content",
      }}
    >
      {DataPresent ? (
        showTable ? (
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                style={{
                  padding: "10px",
                  borderRadius: "10px",
                  height: "40px",
                  marginRight: "10px",
                }}
                variant="contained"
                size="small"
                onClick={() => setShowTable(false)}
              >
                <ArrowBackOutlinedIcon />
              </IconButton>
              <h2>{WidgetName.split("-")[0]} Data Table</h2>
            </div>
            <div
              className="highcharts-data-table"
              dangerouslySetInnerHTML={{ __html: tableContent }}
            />
          </div>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            containerProps={{
              style: {
                width: "100%",
                height: isDashboard
                  ? "300px"
                  : from === "Vanalytics"
                  ? "500px"
                  : "fit-content",
              },
            }}
            options={columnChartOptions}
          />
        )
      ) : (
        fallBack
      )}
    </div>
  );
};

export default AreaChart;
