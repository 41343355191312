import axios from "axios";
export const fetchTicketTable = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_TICKET_TABLE_BYUSER
      }/${sessionStorage.getItem("id")}`
    );

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
export const fetchSiteTicketTable = async (Id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TICKET_TABLE_SITE}/${Id}`
    );

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
export const fetchTicketCategory = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TICKET_CATEGORY_DROPDOWN}`
    );

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
export const fetchTicketState = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TICKET_STATE_DROPDOWN}`
    );

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const fetchTicketActivity = async (Id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TICKET_ACTIVITY}/${Id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const fetchViewticket = async (Id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TICKET_VIEWTICKET_TABLE}/${Id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
export const fetchTicketTableById = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_REPORT_TABLE_BYID
      }/${sessionStorage.getItem("id")}`
    );

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const postticketdata = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TICKET_SAVE_TABLE}`,
      obj
    );
    return response;
  } catch (error) {
    console.error("Error posting data:", error.message);
    throw error;
  }
};
export const postTicketFilterTable = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TICKET_FILTER_TABLE}`,
      obj
    );

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
export const postTicketpiechart = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TICKET_PIE_CHART}`,
      obj
    );

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
export const postTicketbarchart = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TICKET_BAR_CHART}`,
      obj
    );

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
export const postticketexceldata = async (obj) => {
  try {
    const resolvedData = await Promise.all(obj);

    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TICKET_EXCEL_TABLE}`,
      resolvedData
    );
    return response;
  } catch (error) {
    console.error("Error posting data:", error.message);
    throw error;
  }
};

// ============dropdown api========
export const EquipmentlistDropdown = async (Id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_LI_DROPDOWN}/${Id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const CategorylistDropdown = async (Id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_CATEGORY_LI_DROPDOWN}/${Id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
export const EquipmentTypelistDropdown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_TYPE_LI_DROPDOWN}`
    );
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
export const AssignlistDropdown = async (Id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TICKET_ASSIGN_DROPDOWN}/${Id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const putTicketupdateEdit = async (data, id) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TICKET_ASSIGN_PUT}/${id}`,
      data
    );

    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const putTicketupdate = async (data) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TICKET_ASSIGNED_PUT}`,
      data
    );

    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const putTicketexcelupdate = async (data) => {
  console.log(data);

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TICKET_EXCEL_ASSIGNED_PUT}`,
      data
    );

    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const putTicketReassignEdit = async (Id, data) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TICKET_REASSIGN_PUT}/${Id}`,
      data
    );

    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const putTicketholdEdit = async (Id, data) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TICKET_HOLD_PUT}/${Id}`,
      data
    );

    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const putTicketcloseEdit = async (Id, data) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TICKET_CLOSE_PUT}/${Id}`,
      data
    );

    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const putBulkTicketclose = async (data) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TICKET_BULK_CLOSE}`,
      data
    );

    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const TicketUpdate = async (Id, data) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TICKET_UPDATE}/${Id}`,
      data
    );

    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const fetchSiteDetails = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_SITELIST_BYUSER
      }/${sessionStorage.getItem("id")}`
    );
    console.log(response, "resssss");
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const fetchDownloadDetails = async (Id) => {
  try {
    window.open(
      `${process.env.REACT_APP_CHART_API_PATH_OLD}${process.env.REACT_APP_TICKET_FINISH_DOWNLOAD}/${Id}`
    );
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const fetchAssignDetail = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_TICKET_STATUS}`
    );
    console.log(response, "resssss");
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
