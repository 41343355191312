import { useTheme } from "@mui/material";
import { paddedEncryptQuery } from "../security/Cipher";

/**
 * removeQuestionMark function removes all question marks from a given string.
 * @param {string} inputString - The input string to process.
 * @returns {string} The processed string with all question marks removed.
 */
export function removeQuestionMark(inputString) {
  /**
   * Using the replace method, we replace all occurrences of the question mark
   * with nothing, effectively removing it from the string.
   */
  return inputString.replace(/\?/g, "");
}

/**
 * Checks if the current theme is in light mode.
 * @returns {boolean} True if the theme is in light mode, false if not.
 */
export function CheckForLight() {
  const theme = useTheme();
  const colorMode = theme.palette.mode;
  // Return true if the theme is in light mode, false if not
  return colorMode === "light";
}

export const roundedUp = (number) => Math.ceil(number * 100) / 100;

/**
 * Removes entries from the given data array that occur after the specified cutoff time.
 *
 * @param {Array} data - The array of data entries to filter.
 * @param {string} cutoff - The cutoff time in the format "HH:MM:SS".
 * @returns {Array} - The filtered array of data entries.
 *
 * @example
 * Given the following data array:
 * const data = [
 *   { timeStamp: '2022-01-01T08:00:00' },
 *   { timeStamp: '2022-01-01T09:00:00' },
 *   { timeStamp: '2022-01-01T10:00:00' },
 *   { timeStamp: '2022-01-01T11:00:00' },
 * ];
 *
 * The following call to removeAfterTime will return the first three entries:
 * const filteredData = removeAfterTime(data, '10:00:00');
 * console.log(filteredData);
 * Output:
 *  [
 *    { timeStamp: '2022-01-01T08:00:00' },
 *    { timeStamp: '2022-01-01T09:00:00' },
 *    { timeStamp: '2022-01-01T10:00:00' },
 *  ]
 */
export function removeAfterTime(data, cutoff) {
  // Split the cutoff time into hours, minutes, and seconds.
  const [cutoffHour, cutoffMinute, cutoffSecond] = cutoff
    .split(":")
    .map(Number);

  // Initialize an empty array to store the filtered data.
  const filteredData = [];

  // Iterate over each entry in the data array.
  for (let entry of data) {
    const stamp = entry.timeStamp ? entry.timeStamp : entry.TimeStamp;
    // Get the time of the current entry.
    const entryTime = new Date(stamp);

    // Extract the hour, minute, and second from the entry time.
    const entryHour = entryTime.getHours();
    const entryMinute = entryTime.getMinutes();
    const entrySecond = entryTime.getSeconds();

    // Check if the entry time is after the cutoff time.
    // If it is, break out of the loop and return the filtered data.
    if (
      entryHour > cutoffHour ||
      (entryHour === cutoffHour && entryMinute > cutoffMinute) ||
      (entryHour === cutoffHour &&
        entryMinute === cutoffMinute &&
        entrySecond > cutoffSecond)
    ) {
      break;
    }

    // If the entry time is not after the cutoff time, add it to the filtered data.
    filteredData.push(entry);
  }

  // Return the filtered data.
  return filteredData;
}

/**
 * Converts the given raw date string to the format 'DD-MM-YYYY HH:MM:SS' and
 * returns the formatted date string.
 *
 * @param {string} rawDate - The raw date string to convert.
 * @returns {string} - The formatted date string.
 */
export function getFormatDate(rawDate) {
  // Create a new UTC Date object from the raw date string.
  const utcDate = new Date(rawDate);

  // Define the options for the date formatter.
  const options = {
    // Format the year as 'numeric'.
    year: "numeric",
    // Format the month as '2-digit'.
    month: "2-digit",
    // Format the day as '2-digit'.
    day: "2-digit",
    // Format the hour as '2-digit'.
    hour: "2-digit",
    // Format the minute as '2-digit'.
    minute: "2-digit",
    // Format the hour in 24-hour format.
    hour12: false,
    // Set the time zone to 'Asia/Kolkata'.
    timeZone: "Asia/Kolkata",
  };

  // Create a new Intl.DateTimeFormat object with the options.
  const formatter = new Intl.DateTimeFormat("en-IN", options);

  // Format the UTC date using the formatter.
  const formattedDate = formatter
    .format(utcDate)
    .replace(/\//g, "-")
    .replace(", ", " ");

  // Log the formatted date to the console.
  // console.log(formattedDate, "lastUpdatedTimestamp");

  //if backend time zone logic added return below
  const [Y, M, D] = rawDate.split("T")[0].split("-");
  const [H, MI, _] = rawDate.split("T")[1].split(".")[0].split(":");

  const newDate = `${D}-${M}-${Y} ${H}:${MI}`;

  // Return the formatted date.
  return formattedDate;
}

/**
 * Transforms the input array to remove entries that have a null capacity
 * or a displayName that contains 'meter' or 'sensor', and maps the remaining
 * entries to an array of objects with `equipmentId` and `capacity` properties.
 *
 * @param {Array} inputArray - The array of input data.
 * @returns {Array} - The transformed array of objects.
 */
export function transformDataForSpecificYield(inputArray) {
  // Check if inputArray is an array; if not, return an empty array
  if (!Array.isArray(inputArray)) {
    console.error("Invalid input: inputArray is not an array.");
    return [];
  }

  // Filter the input array to remove entries that have a null capacity
  // or a displayName that contains 'meter' or 'sensor'.
  return inputArray
    .filter(
      (item) =>
        item.dcCapacity != null && // Check if capacity is not null
        !/meter|sensor/i.test(item.equipmentCategory) // Check if equipmentCategory does not contain 'meter' or 'sensor'
    )
    .filter((item) => /inverter/i.test(item.equipmentCategory)) // Check if equipmentCategory contains 'inverter'
    .map((item) => ({
      // Map the remaining entries to objects with equipmentId and capacity properties
      equipmentId: item.equipmentId, // Set the equipmentId property to the value of item.equipmentId
      capacity: item.dcCapacity ? item.dcCapacity : 1, // Set the capacity property to the value of item.dcCapacity, or 1 if it is null
    }));
}

/**
 * Filters the given array of roles by the given array of activity names.
 * Only roles where the activityName property is included in the given
 * activityNames array are returned.
 *
 * @param {Array} roles - The array of roles to filter.
 * @param {Array} activityNames - The array of activity names to filter by.
 * @returns {Array} - The filtered array of roles.
 */
export function filterRolesByActivityName(roles, activityNames) {
  const filteredRoles = [];

  // Iterate over each role in the given array of roles.
  for (const role of roles) {
    // Check if the activityName property of the current role is included in
    // the given array of activity names.
    if (activityNames.includes(role.activityName)) {
      // If it is, add the role to the filteredRoles array.
      filteredRoles.push(role);
    }
  }

  // Return the filtered array of roles.
  return filteredRoles;
}

/**
 * Returns an object containing the permissions for a given activity name.
 * The object has properties isPresent (boolean), isActive (boolean), view (boolean),
 * create (boolean), and edit (boolean), which indicate whether the activity is present
 * in the roles array, is active, and whether the user has view, create, and edit permissions.
 *
 * @param {Array} roles - The array of roles to search for the activity name.
 * @param {string} activityName - The name of the activity to search for.
 * @returns {Object} An object with properties isPresent, isActive, view, create, and edit.
 */
export function getActivityPermissions(roles, activityName) {
  // Initialize an object to store the result.
  const result = {
    isPresent: false, // Indicates whether the activity is present in the roles array.
    isActive: false, // Indicates whether the activity is active.
    view: false, // Indicates whether the user has view permission.
    create: false, // Indicates whether the user has create permission.
    edit: false, // Indicates whether the user has edit permission.
  };

  // Iterate over each role in the roles array.
  for (const role of roles) {
    // If the role's activityName matches the given activityName,
    // set the result object properties and break out of the loop.
    if (role.activityName === activityName) {
      result.isPresent = true;
      result.isActive = role.status === 1;
      result.view = Boolean(role.view);
      result.create = Boolean(role.create);
      result.edit = Boolean(role.edit);
      break;
    }
  }

  // Return the result object.
  return result;
}

/**
 * Returns an array of unique data logger IDs from the given data array.
 * Handles null data, empty data, and missing dataLoggerId property.
 *
 * @param {Array} data - The array of data to extract unique data logger IDs from.
 * @return {Array} An array of unique data logger IDs.
 */
export function getUniqueDataLoggerIds(data) {
  if (!data) {
    return []; // Return an empty array if data is null or undefined.
  }

  const uniqueIds = new Set();

  for (const item of data) {
    if (item && item.dataLoggerId !== undefined) {
      uniqueIds.add(item.dataLoggerId);
    }
  }

  return Array.from(uniqueIds);
}

const monthOrder = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
];

/**
 * Sorts an array of objects based on the 'timeStamp' property, in
 * the order of the months of the year (January, February, March, etc.).
 *
 * @param {Array} data - The array of objects to sort.
 * @returns {Array} The sorted array of objects.
 */
export const sortedDataByMonth = (data) =>
  data.sort((a, b) => {
    return (
      monthOrder.indexOf(a.timeStamp ? a.timeStamp : a.timestamp) -
      monthOrder.indexOf(b.timeStamp ? b.timeStamp : b.timestamp)
    );
  });

/**
 * Fills in missing intervals in the given data array, given as an array of objects
 * with 'timeStamp', 'todayEnergy', and 'irradiation' properties.
 *
 * The function will:
 * - Calculate the time step dynamically based on the first two entries of the data
 * - Insert missing intervals of the calculated time step size up to the next
 *   timestamp in the data
 * - Extend the data to end at 19:00:00 if it doesn't already
 *
 * @param {Array} data - The array of data points to fill in missing intervals for
 * @returns {Array} The filled in array of data points
 */
export function fillMissingIntervals(data) {
  if (data.length < 1) return data;

  const filledData = [];
  const dateGroups = {};

  // Determine the timestamp key dynamically
  const timestampKey = data[0].timeStamp
    ? "timeStamp"
    : data[0].timestamp
    ? "timestamp"
    : "TimeStamp";

  // Extract keys dynamically from the first data object (excluding timestamp key)
  const dataKeys = Object.keys(data[0]).filter((key) => key !== timestampKey);

  // Group data by date
  data.forEach((entry) => {
    const date = entry[timestampKey].slice(0, 10); // Get the date part
    if (!dateGroups[date]) dateGroups[date] = [];
    dateGroups[date].push(entry);
  });

  // Process each date group
  for (const date in dateGroups) {
    const dateData = dateGroups[date];
    const firstDate = new Date(dateData[0][timestampKey]);
    const secondDate =
      dateData.length > 1 ? new Date(dateData[1][timestampKey]) : null;
    const timeStepMinutes = secondDate
      ? Math.max((secondDate.getTime() - firstDate.getTime()) / 60000, 1)
      : 60;

    // Create target end time for the same day as the first date
    const targetEndDate = new Date(firstDate);
    targetEndDate.setHours(28, 85, 85, 85); // End of the day

    // Initialize timeline starting from the exact first timestamp
    let tempDate = new Date(firstDate);

    // Generate the timeline with null values for the current date
    const dateFilledData = [];
    while (tempDate <= targetEndDate) {
      const nullEntry = {
        [timestampKey]:
          tempDate.toISOString().replace("T", " ").slice(0, 19) + ".00",
      };
      dataKeys.forEach((key) => {
        if (key === "EquipmentId" || key === "equipmentId") {
          nullEntry[key] = dateData[0][key]; // Use the first entry's EquipmentId
        } else {
          nullEntry[key] = null;
        }
      });
      dateFilledData.push(nullEntry);
      tempDate.setMinutes(tempDate.getMinutes() + timeStepMinutes);
    }

    // Merge the original data by checking each generated entry against all data points
    dateFilledData.forEach((filledEntry) => {
      const generatedTimestamp = new Date(filledEntry[timestampKey]);
      for (const dataPoint of dateData) {
        const dataTimestamp = new Date(dataPoint[timestampKey]);
        if (generatedTimestamp.getTime() === dataTimestamp.getTime()) {
          dataKeys.forEach((key) => {
            filledEntry[key] = dataPoint[key];
          });
          break;
        }
      }
    });

    // Filter out entries before the first data point for the day
    const firstDataTimestamp = new Date(dateData[0][timestampKey]).getTime();
    const filteredDateData = dateFilledData.filter((entry) => {
      const entryTimestamp = new Date(entry[timestampKey]).getTime();
      return entryTimestamp >= firstDataTimestamp;
    });
    filledData.push(...filteredDateData);
  }

  // Sort filled data by timestamp in case dates were processed out of order
  filledData.sort(
    (a, b) => new Date(a[timestampKey]) - new Date(b[timestampKey])
  );

  return filledData;
}

/**
 * Groups an array of objects by a given key.
 * @param {array} arr The array of objects to group.
 * @param {string} key The key to group the objects by.
 * @returns {array} An array of objects with `key` and `values` properties.
 *   Each `key` is a unique value from the given key, and its corresponding
 *   `values` is an array of all objects in the original array that have
 *   that key.
 */
export function groupBy(arr, key) {
  const groupedResult = {};

  arr.forEach((item) => {
    const keyValue = item[key];

    // Initialize the group if it doesn't exist
    if (!groupedResult[keyValue]) {
      groupedResult[keyValue] = [];
    }

    // Add the current item to the corresponding group
    groupedResult[keyValue].push(item);
  });

  // Convert the grouped object into an array of objects with `key` and `values`
  return Object.keys(groupedResult).map((keyValue) => ({
    key: keyValue,
    values: groupedResult[keyValue],
  }));
}

/**
 * Ungroups an array of objects, given the output from `groupBy`.
 * @param {array} groupedArr The array of objects with `key` and `values` properties.
 * @returns {array} An array of objects, with all the objects from the input array
 *   flattened into a single array.
 */
export function ungroup(groupedArr) {
  const ungrouped = [];

  groupedArr.forEach((group) => {
    group.values.forEach((item) => {
      ungrouped.push(item);
    });
  });

  return ungrouped;
}

/**
 * Formats a number by adding commas as thousand separators.
 *
 * Converts the number to a string and splits it into integer and fractional parts.
 * Formats the integer part with commas for readability.
 * If there is a fractional part, it is appended to the formatted integer part.
 *
 * @param {number} num - The number to be formatted.
 * @returns {string} - The formatted number as a string with commas.
 */
export function formatNumber(num) {
  const [integerPart, fractionalPart] = num.toString().split(".");

  // Modify the integer part to handle the last 3 digits separately
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);

  if (otherDigits !== "") {
    lastThreeDigits = "," + lastThreeDigits;
  }

  // Apply the regex to the remaining digits
  const formattedOtherDigits = otherDigits.replace(
    /\B(?=(\d{2})+(?!\d))/g,
    ","
  );

  const formattedInteger = formattedOtherDigits + lastThreeDigits;

  return fractionalPart
    ? `${formattedInteger}.${fractionalPart}`
    : formattedInteger;
}

/**
 * Determines the unit of measurement for a given parameter name.
 *
 * This function evaluates the provided parameter name string and returns
 * the corresponding unit of measurement as a string. It checks for specific
 * substrings within the parameter name to determine the appropriate unit.
 *
 * @param {string} parameterName - The name of the parameter to check.
 * @returns {string} - The unit of measurement associated with the parameter.
 *                     Returns an empty string if no unit is applicable.
 */

export function getUnit(parameterName, flag) {
  const isPVcum =
    flag === "PVcum" &&
    (parameterName.includes("Solar") ||
      parameterName.includes("DG") ||
      parameterName.includes("Grid"));

  if (isPVcum) {
    return "(kWh)";
  }

  switch (true) {
    case parameterName.includes("InputCurrent"):
      return "(A)";
    case parameterName.includes("InputVoltage"):
      return "(V)";
    case parameterName.includes("InputPower"):
      return "(W)";
    case parameterName.includes("InputEnergy"):
      return "(kWh)";
    case parameterName.includes("PhaseCurrent"):
      return "(A)";
    case parameterName.includes("PhaseVoltage"):
      return "(V)";
    case parameterName.includes("ApparentPower"):
      return "(kVA)";
    case parameterName.includes("ActivePower"):
      return "(kW)";
    case parameterName.includes("ReactivePower"):
      return "(kVar)";
    case parameterName.includes("PowerFactor"):
      return "";
    case parameterName.includes("TodayEnergy"):
      return "(kWh)";
    case parameterName.includes("TotalEnergy"):
      return "(kWh)";
    case parameterName.includes("IsolationResistance"):
      return "(Ω)";
    case parameterName.includes("OutputFrequency"):
      return "(Hz)";
    case parameterName.includes("AmbientTemperature"):
    case parameterName.includes("ModuleTemperature"):
    case parameterName.includes("InverterTemperature"):
    case parameterName.includes("HeatSinkTemp"):
    case parameterName.includes("SMBTemparature"):
      return "(ºC or K)";
    case parameterName.includes("WindSpeed"):
      return "(m/s)";
    case parameterName.includes("Rainfall"):
      return "(mm)";
    case parameterName.includes("TotalHoursOn"):
    case parameterName.includes("TodayHoursOn"):
      return "(HH:MM:SS)";
    case parameterName.includes("PhasePowerBalancer"):
      return "";
    case parameterName.includes("DifferentialCurrent"):
      return "(mA)";
    case parameterName.includes("Status"):
    case parameterName.includes("ErrorCode"):
      return "";
    case parameterName.includes("PhaseToPhaseVoltage"):
      return "(V)";
    case parameterName.includes("SerialNumber"):
      return "";
    case parameterName.includes("ACBreakerCount"):
    case parameterName.includes("DCBreakerCount"):
      return "";
    case parameterName.includes("ExportTotalEnergy"):
    case parameterName.includes("ImportTotalEnergy"):
    case parameterName.includes("ExportActiveEvergy"):
    case parameterName.includes("ImportActiveEnergy"):
    case parameterName.includes("MonthlyEnergy"):
      return "(kWh)";
    case parameterName.includes("ReactiveEnergyLAG"):
    case parameterName.includes("ReactiveEnergyLEAD"):
      return "(kVAR)";
    case parameterName.includes("WindDirection"):
      return "(°)";
    case parameterName.includes("Irradiance"):
    case parameterName.includes("GlobalIrradiance"):
      return "(W/m²)";
    case parameterName.includes("GlobalHorizantalIrradiation"):
    case parameterName.includes("Irradiation"):
      return "(Wh/m²)";
    case parameterName.includes("Humidity"):
      return "(%)";
    case parameterName.includes("TrackerAngle"):
    case parameterName.includes("SunAngle"):
      return "(º)";
    case parameterName.includes("Batteryvoltage"):
      return "(V)";
    case parameterName.includes("StartRoboPosition"):
    case parameterName.includes("MidRoboPosition"):
    case parameterName.includes("EndRoboPosition"):
      return "";
    case parameterName.includes("PhaseFrequency"):
      return "Hz";
    case parameterName.includes("PhasePower"):
      return "(kW)";
    case parameterName.includes("PhaseEnergy"):
      return "(kWh)";
    case parameterName.includes("Total Specific Yeild"):
      return "(Rs)";
    case parameterName.includes("CO2Avioded"):
      return "(T)";
    case parameterName.includes("Import/Export Status"):
      return "(0 or 1)";
    case parameterName.includes("SunRiseTime"):
    case parameterName.includes("SunSetTime"):
      return "(HH:MM)";
    case parameterName.includes("TrackerMode"):
    case parameterName.includes("TrackerState"):
    case parameterName.includes("TrackerStatus"):
      return "";
    case parameterName.includes("Solar"):
      return "(W)";
    case parameterName.includes("DG"):
      return "(W)";
    case parameterName.includes("Grid"):
      return "(W)";
    case parameterName.includes("DeemedGeneration"):
      return "(kWh)";
    case parameterName.includes("SetPoint"):
      return "(%)";
    case parameterName.includes("PotentialGeneration"):
      return "(kWh)";
    case parameterName.includes("Load"):
      return "(kW)";
    default:
      return "";
  }
}

/**
 * Determines if a cell value should be highlighted in red based on specific conditions.
 *
 * This function checks if the provided `headCellId` is "performanceRatio"
 * and evaluates the `value` to see if it meets any of the following conditions:
 * - The value is "0.00" or "0.00 %".
 * - The value is "-".
 * - The value is negative.
 * - The value is greater than 50.
 *
 * @param {string} headCellId - The ID of the cell to check.
 * @param {any} value - The value of the cell to evaluate.
 * @returns {boolean} - Returns true if the cell should be highlighted in red, false otherwise.
 */

export const shouldHighlightRed = (headCellId, value) => {
  // Convert value to a string for consistent checks
  const stringValue = String(value).trim().split("%")[0];

  // Check the conditions
  return (
    headCellId === "performanceRatio" &&
    // (stringValue === "0.00" || // Check for "0.00"
    //   stringValue === "0.00 %" || // Check for "0.00%"
    //   stringValue === "-" || // Check for "-"
    //   +stringValue <= 0 || // Check for negative values
    +stringValue > 100 // ) // Check for values greater than 50
  );
};

export function generateDynamicUrl(data, link) {
  let passParam = new URLSearchParams(data).toString();
  let encryptedParams = paddedEncryptQuery(passParam);

  let url = new URL(window.location.href);
  if (url.pathname.startsWith("/saas/")) {
    return `/saas${link}?${encryptedParams}`;
  }

  return `${link}?${encryptedParams}`;
}
