import React, { useState, useEffect, useMemo } from "react";
import "./AnalyticStyle.css";
import moment from "moment";
import CombinedChart from "../../Charts/CombainedChart";
/*---------------------------Mui Components ----------------------------------*/
import {
  Button,
  Stack,
  Box,
  Paper,
  TextField,
  Autocomplete,
  OutlinedInput,
  Typography,
  Divider,
  CardContent,
  Card,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Modal,
  Grid,
  CircularProgress,
  Alert,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import dayjs from "dayjs";
/*---------------------------Mui Icons --------------------------*/
import Snackbar from "@mui/material/Snackbar";
/*------------------------- External compoenents ---------------------------*/
import Apexchart from "./ApexexChart";
import BarChart from "../../Charts/BarChart";
import AreaChart from "../../Charts/AreaChart";
import FadeLoader from "react-spinners/FadeLoader";
import {
  SubmitDataAnalyst,
  ExpectedActual,
  DieselGenrator,
  fetchDailyGenerationEira,
  fetchEnergyPerformanceEira,
  fetchParameterComparisonEira,
  fetchSpecificYieldEira,
  fetchPvDgGridEira,
  DeemedGenrator,
  ActualVsExpected,
  fetchStringCurrentEira,
  GetSetPoint,
} from "../../../Api/DataAnalystApi";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadDoneOutlinedIcon from "@mui/icons-material/DownloadDoneOutlined";
import {
  fetchEquipDropdownBySite,
  fetchParamsDropdownByLogger,
  fetchSiteDropdownByUser,
} from "../../../Api/CommonApi";
import {
  getUniqueDataLoggerIds,
  removeAfterTime,
  roundedUp,
  transformDataForSpecificYield,
} from "../../util/common/CommanFunction";
import { removeQuestionMark } from "../../util/common/CommanFunction";
import { decryptPaddedQuery } from "../../util/security/Cipher";
import { HeatmapChart } from "../../Charts/HeatmapChart";
import { paddedEncryptQuery } from "../../util/security/Cipher";
import WaterfallChart from "../../Charts/WaterfallChart";
const alertStyle = {
  color: "white", // Text color
  "& .MuiSvgIcon-root": {
    color: "white", // Icon color
  },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const optionTime = [
  { label: "Today", value: "today" },
  { label: "Last 7 days", value: "last 7 days" },
  { label: "This Month", value: "this month" },
  { label: "Monthly", value: "yearly" },
  { label: "Yearly", value: "yearlyCum" },
  { label: "Custom range", value: "custom" },
];
const chartType = [
  { label: "Daily Generation", value: "daily_generation" },
  { label: "Energy Performance", value: "energy_performance" },
  { label: "Parameter Comparision", value: "parameter_comparison" },
  { label: "Specific Yield", value: "specific yield" },
  { label: "Specific Yield - Heatmap", value: "specific yield" },
  { label: "Actual vs Expected", value: "ActualVsExcepted" },
  { label: "DG PV Grid Management", value: "dgpv" },
  { label: "Deemed Generation", value: "deemed_generation" },
  { label: "Set Point", value: "setpoint" },
  { label: "String Current - Heatmap", value: "string_current - heatmap" },
  { label: "String Current - Average", value: "string_current - heatmap" },
];
const buttonStyles = {
  textTransform: "capitalize",
  boxShadow: "4px 4px 17px rgba(0, 0, 0.50, 0.50)",
  fontSize: "1.1rem",
  border: "none",
  width: "7vw",
};
const typoStyle = {
  textTransform: "capitalize",
  opacity: "0.8",
  textTransform: "capitalize",
  lineHeight: "14.52px !important",
  color: " rgb(113, 118, 111)",
};
const monitor_head_text = {
  fontSize: "1.2rem",
  fontWeight: "bold",
  marginTop: "1%",
  marginLeft: "2%",
  textTransform: "capitalize",
  lineHeight: "14.52px !important",
  color: " rgb(113, 118, 111)",
};
const filterAlign = {
  marginTop: "5.5%",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "25vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddAnalyst = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`?${queryParam}`);
  const params = {};
  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }
  console.log(params.name);
  function navigateEncryptedURL(URL) {
    let passSite = { name: site };
    let passParam = new URLSearchParams(passSite).toString();
    let encryptedParams = paddedEncryptQuery(passParam);
    let url = `${URL}?${encryptedParams}`;
    navigate(url);
  }
  const [site, setSite] = React.useState(params.name);
  const [equipment, setEquipmentName] = React.useState([]);
  const [equipmentParam, setParamName] = React.useState([]);
  const [datalog, setDatalogValue] = React.useState([]);
  const [siteIdName, setSiteId] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [equipmentParameters, setParameterSite] = useState([]);
  const [equipmentParamwithUnits, setParameter] = useState([]);
  const [chartName, setChartName] = useState("");
  const [timevalue, setTimeValue] = useState("today");
  const [toBackend, setToBackend] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [range, setRange] = useState("");
  const [open, setOpen] = useState(false);
  const [widgetName, setWidgetName] = useState("");
  const [variantText, setVarient] = useState("");
  const [textValue, setTextValue] = useState("");
  const [barchartdata, setBarchartData] = useState([]);
  const [multiChartData, setMultichartData] = useState([]);
  const [tableData, setTableData] = useState();
  const [openToast, setOpenToast] = React.useState(false);
  const [selectAllEquipments, setSelectAllEquipments] = useState(false);
  const [selectAllParam, setSelectAllParam] = useState(false);
  const [axisData, setAxisData] = useState([]);
  const [chartFromDate, setChartFromDate] = useState("");
  const [chartToDate, setChartToDate] = useState("");
  const [apexChartType, setChartType] = useState("");
  const [customView, setCustomView] = useState();
  const [minToDate, setMinToDate] = useState([]);
  const [equpParamMap, setEqupParamMapValue] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSiteCall();
  }, []);
  const handleOpen = () => {
    if (chartName === "" && site === "") {
      setOpenToast(true);
      setVarient("error");
      setTextValue("Kindly Select Chart Type And Site Name");
    } else if (timevalue === "custom") {
      setOpenToast(true);
      setOpen(false);
      setVarient("warning");
      setTextValue(
        "Custom range Data's are Restricted to Store in Wizard Format"
      );
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => setOpen(false);
  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };
  /*----------------------------- Paramater Get call ------------------------------*/
  const fetchParams = async (id) => {
    try {
      let data = await fetchParamsDropdownByLogger(id);

      let ParamsName = data
        .filter((item) => item.stdParameterName !== "Dummy")
        .map((item) => item.stdParameterName);

      setParameterSite(ParamsName);
      return ParamsName;
    } catch (e) {
      console.error(e);
    }
  };
  const handleSite = (data, val) => {
    if (val === null) {
      setSite("");
    } else {
      setSite(val);
      setEquipmentName([]);
      fetchEquipmentDetails(val);
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    let equip = [];
    if (value.includes("Select All")) {
      if (selectAllEquipments) {
        setSelectAllEquipments(false);
        setEquipmentName([]);
      } else {
        setSelectAllEquipments(true);
        setEquipmentName([...equipmentData]);
      }
    } else {
      const updatedEquipmentName = value.filter(
        (item) => item !== "Select All"
      );
      setEquipmentName(updatedEquipmentName);
      setSelectAllEquipments(
        updatedEquipmentName.length === equipmentData.length
      );
    }
    if (value.includes("Select All")) {
      equip = [...equipments];
    } else {
      equip = equipments.filter((data) =>
        value.some((val) => data.displayName.includes(val))
      );
    }
    console.log(equip, value);

    if (value.length !== 0 && equip.length !== 0) {
      const ids = getUniqueDataLoggerIds(equip);
      fetchParams({ dataloggerId: ids });
    }
  };

  const handleChangeParams = (event) => {
    const { value } = event.target;
    if (value.includes("Select All")) {
      if (selectAllParam) {
        setSelectAllParam(false);
        setParamName([]);
      } else {
        setSelectAllParam(true);
        setParamName([...equipmentParameters]);
      }
    } else {
      const updatedEquipmentParam = value.filter(
        (item) => item !== "Select All"
      );
      setParamName(updatedEquipmentParam);
      setSelectAllParam(
        updatedEquipmentParam.length === equipmentParameters.length
      );
    }
  };
  /*--------------------------------Equipment Get  ----------------------------*/
  const fetchEquipmentDetails = async (event) => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === event;
    });
    try {
      let data = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);
      setEquipments(data);
      let equipmentName = data.map((datav) => {
        return datav.displayName;
      });
      setEquipmentData(equipmentName);
    } catch (e) {
      console.error(e);
    }
  };

  const handleCancel = () => {
    navigateEncryptedURL("/menu/analytics");
  };
  /*-------------------------- Site Get ---------------------*/
  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      setSiteId([...new Set(data)]);
    } catch (e) {
      console.error(e);
    }
  };
  const handleChart = (data, event) => {
    setChartName(event);
    if (params.name === site) {
      fetchEquipmentDetails(params.name);
    }
    setEquipmentName([]);
    setParamName([]);
    setDatalogValue([]);
  };
  const handleTimeChange = (data, event) => {
    if (event === null) {
      setTimeValue("");
    } else {
      setTimeValue(event.value);
    }
  };
  /*------------------------- Custom range Date -------------------------------*/
  const handleChangeFromDate = (data, value) => {
    if (data !== null) {
      const formattedDateTime = data.format("YYYY-MM-DD");
      setFromDate(formattedDateTime);
    }
  };

  const handleChangeToDate = (data, value) => {
    if (data !== null) {
      const formattedDateTime = data.format("YYYY-MM-DD");
      setToDate(formattedDateTime);
    }
  };

  /*--------------------- Time range ---------------------*/
  useEffect(() => {
    let date = new Date();
    let fromDate = new Date();
    let responseDate = "";
    let toDate = "";
    const formatDate = (inputDate) => {
      const day = String(inputDate.getDate()).padStart(2, "0");
      const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const year = inputDate.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    };
    switch (timevalue) {
      case "today":
        responseDate = moment(date).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        setFromDate(responseDate);
        setToDate(toDate);
        setRange("daily");
        break;
      case "yesterday":
        date.setDate(date.getDate() - 1);
        responseDate = moment(date).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD  ");
        setFromDate(responseDate);
        setToDate(responseDate);
        setRange("daily");
        break;
      case "last 7 days":
        fromDate.setDate(date.getDate() - 7);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD  ");
        setFromDate(responseDate);
        setToDate(toDate);
        setRange("custom");
        break;
      case "this month":
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const endDate = new Date(date);
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        setFromDate(formatDate(startDate));
        setToDate(formatDate(endDate));
        setRange("custom");
        break;
      case "last month":
        const firstDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          0
        );
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        setFromDate(formatDate(firstDayOfLastMonth));
        setToDate(formatDate(lastDayOfLastMonth));
        setRange("custom");
        break;
      case "yearly":
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1); // First day of the current year
        toDate = moment(date).format("YYYY-MM-DD"); // Today's date
        setFromDate(formatDate(firstDayOfYear)); // From the first day of the current year
        setToDate(toDate); // To today's date
        setRange("yearly");
        break;
      case "yearlyCum":
        const firstDayOfLastYear = new Date(date.getFullYear() - 1, 0, 1); // First day of the previous year
        toDate = moment(date).format("YYYY-MM-DD"); // Today's date
        setFromDate(formatDate(firstDayOfLastYear)); // From the first day of the previous year
        setToDate(toDate); // To today's date
        setRange("yearlyCum");
        break;
      case "custom":
        setRange("custom");
        break;
      default:
        break;
    }
  }, [timevalue]);

  function checkArray(arr) {
    // Function to check if all values in an object are null
    function allValuesNull(obj) {
      return Object.values(obj).every((value) => value === null);
    }

    // Check each object in the array
    for (let obj of arr) {
      if (!allValuesNull(obj)) {
        return arr; // Return the original array if any object has non-null values
      }
    }

    return []; // Return an empty array if all objects have all null values
  }

  // //console.log(equpParamMap, 'equpParamMap');
  /*------------------------------------------  Apply changes ----------------------*/
  const handleApplyChanges = async () => {
    setLoading(true);
    setDatalogValue([site, equipment, equipmentParam]);

    const equipLoggMap = await createEquipmentMap();

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });

    let filteredObjects = equipments?.filter((obj) =>
      equipment.includes(obj.displayName)
    );
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });
    let equipmentParameter = equipmentParam?.map((data) => {
      return data;
    });

    let equipmentParams = equipmentParamwithUnits.filter((data) => {
      return equipmentParam.includes(data.stdParameterName);
    });
    setAxisData(equipmentParams);
    let dataModel = {
      siteId: siteIdFilter[0]?.siteId,
      equipmentIds: equipmentIds,
      parameters: equipmentParameter,
      range:
        chartName === "Specific Yield" &&
        (timevalue === "today" || timevalue === "yesterday")
          ? "daily"
          : fromDate === toDate
          ? "daily"
          : range,
      timeperiod: timevalue,
      capacity: siteIdFilter[0]?.installationCapacity,
      intervalMins: 5,
      fromDate: fromDate,
      toDate: toDate,
      GraphType: chartName,
      energyFlag: 0,
      energyGenBasedOn: siteIdFilter[0]?.energyGenBasedOn,
    };

    dataModel.range =
      (range === "yearly" || range === "yearlyCum") &&
      ![
        "Daily Generation",
        "Actual vs Expected",
        "DG PV Grid Management",
        "Specific Yield",
        "Energy Performance",
        "String Current - Heatmap",
        "String Current - Average",
      ].includes(chartName)
        ? "custom"
        : dataModel.range;

    if (
      chartName === "Specific Yield" ||
      chartName === "Specific Yield - Heatmap" ||
      chartName === "String Current - Heatmap" ||
      chartName === "String Current - Average"
    ) {
      const equipList = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);
      console.log(equipList);

      const EquipmentForSpecificYield =
        transformDataForSpecificYield(equipList);
      dataModel.equipmentIdAndCapacity = EquipmentForSpecificYield;
      let filteredObjects = equipments?.filter((obj) =>
        ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
      );
      console.log(dataModel.equipmentIdAndCapacity);
      const equipment = dataModel.equipmentIdAndCapacity.map(
        (item) => item.equipmentId
      );
      console.log(equipment);

      const equipmentIds = filteredObjects?.map((data) => {
        return data.equipmentId;
      });
      console.log(equipmentIds);

      dataModel.equipmentIds = equipment;
    }
    if (
      chartName === "Daily Generation" ||
      chartName === "Actual vs Expected" ||
      chartName === "Deemed Generation"
    ) {
      const equipList = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);
      let filteredObjects = equipList?.filter((obj) =>
        [
          "Weather Station",
          "Energy Meter",
          "String Inverter",
          "Central Inverter",
        ].includes(obj.equipmentCategory)
      );
      const equipmentIds = filteredObjects?.map((data) => {
        return data.equipmentId;
      });

      dataModel.equipmentIds = equipmentIds;
    }
    setCustomView(dataModel);
    setChartFromDate(fromDate);
    setChartToDate(toDate);
    setChartType(chartName);
    if (chartName === "Daily Generation") {
      delete dataModel.parameters;
      let responseData = await fetchDailyGenerationEira(dataModel);
      setLoading(false);
      setTableData(responseData);

      let seriesData = [];
      const irradiationSeries = {
        name: "irradiation",
        data: [],
      };
      const todayEnergySeries = {
        name: "todayenergy",
        data: [],
      };
      responseData.forEach((dataPoint) => {
        const timeStamp = new Date(dataPoint.timeStamp).getTime();
        irradiationSeries.data.push([
          timeStamp,
          roundedUp(dataPoint.irradiation) || 0,
        ]);
        todayEnergySeries.data.push([
          timeStamp,
          roundedUp(dataPoint.todayEnergy) || 0,
        ]);
      });
      seriesData.push(irradiationSeries);
      seriesData.push(todayEnergySeries);
      console.log(seriesData);

      setMultichartData(seriesData);
    } else if (chartName === "Energy Performance") {
      delete dataModel.parameters;
      const isCumulative =
        dataModel.range === "yearlyCum" || dataModel.range === "yearly";
      try {
        let responseData = await fetchEnergyPerformanceEira(dataModel);
        setLoading(false);
        let barChartData = responseData;
        setTableData(responseData);
        if (fromDate === toDate) {
          const equipmentMap = {};
          equipments.forEach((equipment) => {
            equipmentMap[equipment.equipmentId] = equipment.displayName;
          });
          const seriesData = [];
          equipments.forEach((equipment) => {
            const series = {
              name: equipment.displayName,
              data: [],
            };
            const equipmentData = responseData.filter(
              (data) => data.equipmentId === equipment.equipmentId
            );
            equipmentData.forEach((data) => {
              const timeStamp = new Date(data.timeStamp).getTime();
              const value =
                data.todayEnergy !== null ? roundedUp(data.todayEnergy) : 0;
              series.data.push([timeStamp, value]);
            });
            seriesData.push(series);
          });
          let finalArray = seriesData?.filter((value) => {
            return value.data.length > 0;
          });
          setMultichartData(finalArray);
        } else {
          const convertedData = barChartData.reduce((result, item) => {
            const timeStamp = isCumulative
              ? item.timeStamp
              : new Date(item.timeStamp).getTime();
            const found = result.find(
              (x) => x.name === item.equipmentId.toString()
            );
            if (found) {
              found.data.push([timeStamp, roundedUp(item.todayEnergy)]);
            } else {
              result.push({
                name: item.equipmentId.toString(),
                data: [[timeStamp, roundedUp(item.todayEnergy)]],
              });
            }
            return result;
          }, []);
          let converteddata = convertedData;
          let dataMap = new Map(
            equipments.map((item) => [
              String(item.equipmentId),
              item.displayName,
            ])
          );
          converteddata.forEach((item) => {
            if (dataMap.has(item.name)) {
              item.name = dataMap.get(item.name);
            }
          });
          setBarchartData(convertedData);
          setTableData(responseData);
        }
      } catch (e) {
        console.error(e);
      }
    } else if (chartName === "Parameter Comparision") {
      try {
        let responseData = await fetchParameterComparisonEira(dataModel);
        setLoading(false);
        setTableData(responseData);
        function doesFieldExist(equipmentName, fieldName) {
          const params = equipLoggMap[equipmentName];

          if (params && params.includes(fieldName)) {
            return true;
          }
          return false;
        }
        const equipmentMap = {};
        const dataFields = equipmentParameter;
        const seriesData = [];
        dataFields.forEach((field) => {
          responseData.forEach((data) => {
            let equipmentName = equipments?.filter((dataValue) => {
              return (
                dataValue.equipmentId ===
                Number(data.equipmentId ? data.equipmentId : data.EquipmentId)
              );
            });
            const timeStampData = data.timeStamp
              ? data.timeStamp
              : data.TimeStamp;
            const timeStamp = new Date(timeStampData).getTime();
            const value = data[field];
            const seriesName = `${equipmentName[0]?.displayName} - ${field}`;
            const existingSeries = seriesData.find(
              (series) => series.name === seriesName
            );
            if (existingSeries) {
              existingSeries.data.push([timeStamp, value]);
            } else {
              const newSeries = {
                name: seriesName,
                data: [[timeStamp, value]],
              };

              if (doesFieldExist(equipmentName[0]?.displayName, field)) {
                seriesData.push(newSeries);
              }

              let filteredArray = seriesData.map((obj) => {
                obj.data = obj.data.map(([timeStamp, value]) => [
                  timeStamp,
                  isNaN(value) ? 0 : value,
                ]);
                return obj;
              });
              console.log(filteredArray);

              setMultichartData(filteredArray);
            }
          });
        });
      } catch (e) {
        console.error(e);
      }
    } else if (
      chartName === "Specific Yield" &&
      (timevalue === "today" ||
        timevalue === "yesterday" ||
        fromDate === toDate)
    ) {
      delete dataModel.parameters;
      dataModel.range = "daily";
      let responseData = await fetchSpecificYieldEira(dataModel);

      function transformToChartData(initialData) {
        const equipmentMap = new Map();
        equipments.forEach((item) => {
          equipmentMap.set(item.equipmentId, item);
        });

        // Transform the initialData array
        const transformedData = initialData.map((item) => {
          const equipment = equipmentMap.get(item.equipmentId);
          return {
            todayEnergy: roundedUp(item.todayEnergy),
            timeStamp: item.timestamp || item.timeStamp,
            specificYield: roundedUp(item.specificYield),
            equipmentid: item.equipmentId,
            equipmentName: equipment ? equipment.displayName : "Unknown",
          };
        });

        return transformedData;
      }

      setLoading(false);
      setTableData(transformToChartData(responseData));
      setChartName(dataModel?.GraphType);
    } else if (chartName === "Specific Yield") {
      delete dataModel.parameters;
      let responseData = await fetchSpecificYieldEira(dataModel);

      const transformedData = responseData.map((item) => {
        return {
          todayEnergy: roundedUp(item.todayEnergy),
          timeStamp: item.timestamp || item.timeStamp,
          specificYield: roundedUp(item.specificYield),
          equipmentid: item.equipmentId,
        };
      });

      setLoading(false);
      setTableData(transformedData);
      setChartName(dataModel?.GraphType);
    } else if (chartName === "Specific Yield - Heatmap") {
      delete dataModel.parameters;
      delete dataModel.equipmentIds;
      dataModel.range = "daily";

      let responseData = await fetchSpecificYieldEira(dataModel);

      function transformToChartData(initialData) {
        const equipmentMap = new Map();
        equipments.forEach((item) => {
          equipmentMap.set(item.equipmentId, item);
        });

        // Transform the initialData array
        const transformedData = initialData.map((item) => {
          const equipment = equipmentMap.get(item.equipmentId);
          return {
            todayEnergy: roundedUp(item.todayEnergy),
            timeStamp: item.timestamp || item.timeStamp,
            specificYield: roundedUp(item.specificYield),
            equipmentid: item.equipmentId,
            equipmentName: equipment ? equipment.displayName : "Unknown",
          };
        });

        return transformedData;
      }
      const result = transformToChartData(responseData);
      setLoading(false);
      setTableData(result);
      setChartName(dataModel?.GraphType);
    } else if (chartName === "String Current - Heatmap") {
      console.log(dataModel);

      dataModel.range = "daily";

      let responseData = await fetchStringCurrentEira(dataModel.equipmentIds);

      console.log(responseData); // Check the order of responseData

      setChartName(dataModel?.GraphType);

      function transformToChartData(initialData) {
        const equipmentMap = new Map();
        equipments.forEach((item) => {
          equipmentMap.set(item.equipmentId, item);
        });

        // List of all possible inputCurrent fields
        const allInputCurrentFields = Array.from(
          { length: 30 },
          (_, i) => `inputCurrent_${String(i + 1).padStart(2, "0")}`
        );

        // Ensure the order is preserved by directly mapping over the initialData
        return initialData.map((item) => {
          const equipment = equipmentMap.get(item.equipmentId);
          const transformedItem = {
            equipmentId: item.equipmentId,
            equipmentName: equipment ? equipment.displayName : "Unknown",  // Equipment name
            datas: {
              equipmentName: equipment ? equipment.displayName : "Unknown",  // Add the equipment name inside 'datas'
              // Dynamically add all 'inputCurrent_*' fields into 'datas'
              ...allInputCurrentFields.reduce((acc, field) => {
                acc[field] = item[field] !== null ? item[field] : null;  // Assign the value of each field
                return acc;
              }, {})
            }
          };

          // Include all inputCurrent_* fields, replacing null with 0 and preserving their order
          allInputCurrentFields.forEach((field) => {
            transformedItem[field] = item[field] !== null ? item[field] : null;
          });

          console.log(transformedItem); // The log value should remain in the same format, no change in the order of fields

          return transformedItem;
        });
      }

      const result = transformToChartData(responseData);
      console.log(result);
      setTableData(result);
      setLoading(false); // End loading state
      // setChartName(EiraDataModel?.GraphType);
      setTableData(result);
      setLoading(false); // End loading state
    } else if (chartName === "String Current - Average") {
      console.log(dataModel);

      dataModel.range = "daily";

      let responseData = await fetchStringCurrentEira(dataModel.equipmentIds);

      console.log(responseData); // Check the order of responseData

      setChartName("String Current - Average");

      function transformToChartData(initialData) {
        const equipmentMap = new Map();
        equipments.forEach((item) => {
          equipmentMap.set(item.equipmentId, item);
        });

        // List of all possible inputCurrent fields
        const allInputCurrentFields = Array.from(
          { length: 30 },
          (_, i) => `inputCurrent_${String(i + 1).padStart(2, "0")}`
        );

        // Ensure the order is preserved by directly mapping over the initialData
        return initialData.map((item) => {
          const equipment = equipmentMap.get(item.equipmentId);
          const transformedItem = {
            equipmentId: item.equipmentId,
            equipmentName: equipment ? equipment.displayName : "Unknown",  // Equipment name
            datas: {
              equipmentName: equipment ? equipment.displayName : "Unknown",  // Add the equipment name inside 'datas'
              // Dynamically add all 'inputCurrent_*' fields into 'datas'
              ...allInputCurrentFields.reduce((acc, field) => {
                acc[field] = item[field] !== null ? item[field] : null;  // Assign the value of each field
                return acc;
              }, {})
            }
          };

          // Include all inputCurrent_* fields, replacing null with 0 and preserving their order
          allInputCurrentFields.forEach((field) => {
            transformedItem[field] = item[field] !== null ? item[field] : null;
          });

          console.log(transformedItem); // The log value should remain in the same format, no change in the order of fields

          return transformedItem;
        });
      }

      const result = transformToChartData(responseData);
      console.log(result);
      setTableData(result);
      setLoading(false); // End loading state
      // setChartName(EiraDataModel?.GraphType);
      setTableData(result);
      setLoading(false); // End loading state
    } else if (chartName === "DG PV Grid Management") {
      delete dataModel.parameters;
      delete dataModel.equipmentIds;
      // dataModel.range="daily"

      let responseData = await fetchPvDgGridEira(dataModel);
      let result;
      result = {
        dgMeter: responseData.dgMeter,
        gridMeter: responseData.gridMeter,
        solarMeter: responseData.solarMeter,
      };
      // //console.log(result, 'responseData');
      setLoading(false);
      // //console.log(responseData, "responseData");
      setTableData(result);
      setChartName(dataModel?.GraphType);
    } else if (chartName === "Actual vs Expected") {
      let responseData = await ActualVsExpected(dataModel);

      setLoading(false);
      setTableData(responseData);
      setChartName(dataModel?.GraphType);
    } else if (chartName === "Deemed Generation") {
      function convertDailyDeemedData(data) {
        const formattedData = [
          { name: "Today Energy", data: [] },
          { name: "Irradiation", data: [] },
          { name: "Deemed Generation", data: [] },
          { name: "Set Point", data: [] },
          { name: "Potential Generation", data: [] },
          { name: "Estimated Load", data: [] },
          { name: "Min Load", data: [] },
        ];

        data.forEach((point) => {
          const timestamp = new Date(point.timeStamp).getTime();

          formattedData[0].data.push([timestamp, point.todayEnergy]);
          formattedData[1].data.push([timestamp, point.irradiation]);
          formattedData[2].data.push([timestamp, point.deemedGeneration]);
          formattedData[3].data.push([timestamp, point.setPoint]);
          formattedData[4].data.push([timestamp, point.potentialGeneration]);

          // Only push max load if it is present
          if (
            point.hasOwnProperty("estimatedLoad") &&
            point.estimatedLoad !== undefined &&
            point.estimatedLoad !== null
          ) {
            formattedData[5].data.push([timestamp, point.estimatedLoad]);
          }
          // Only push min load if it is present
          if (
            point.hasOwnProperty("minLoad") &&
            point.minLoad !== undefined &&
            point.minLoad !== null
          ) {
            formattedData[6].data.push([timestamp, point.minLoad]);
          }
        });

        // Filter out estimated load and min load if their data arrays are empty
        const filteredData = formattedData.filter((item) => {
          if (item.name === "Estimated Load" || item.name === "Min Load") {
            return item.data.length > 0;
          }
          return true;
        });

        return filteredData;
      }
      if (
        timevalue.toLowerCase() === "today" ||
        timevalue.toLocaleLowerCase() === "yesterday" ||
        fromDate === toDate
      ) {
        let responseData = await DeemedGenrator(dataModel);
        console.log(responseData, "responseData");
        const result = convertDailyDeemedData(responseData);
        setTableData(responseData);
        setMultichartData(result);
        setChartName(dataModel?.GraphType);
        setLoading(false);
      } else {
        // delete dataModel.parameters;
        // delete dataModel.equipmentIds;
        // EiraDataModel.range="daily"

        let responseData = await DeemedGenrator(dataModel);
        responseData = checkArray(responseData);
        setLoading(false);
        console.log(responseData, "responseData");
        setTableData(responseData);
        setChartName(dataModel?.GraphType);
      }
    } else if (chartName === "Set Point") {
      try {
        let responseData = await GetSetPoint(dataModel);
        setLoading(false);
        //check if all data is null
        const isNull = responseData.every((item) => {
          return item.avgSetpoint == null;
        });
        setTableData(isNull ? [] : responseData);
        setMultichartData([]);
        setChartName(dataModel?.GraphType);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    }
  };
  /*----------------------------- Submit Call ------------------------------*/
  const handleSubmit = async () => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });
    let filteredObjects = equipments?.filter((obj) =>
      equipment.includes(obj.displayName)
    );
    const stringInverterIds = equipments
    .filter(equipment => equipment.equipmentCategory === "String Inverter")
    .map(equipment => equipment.equipmentId);
  
  
    
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });
    let equipmentParameter = equipmentParam?.map((data) => {
      return data;
    });

    let obj = {
      siteId: siteIdFilter[0]?.siteId,
      chartType: chartName,
      userId: sessionStorage.getItem("id"),
      equipment_Ids: [],
      capacity: siteIdFilter[0]?.installationCapacity,
      measure_Parameter: [],
      range:
        chartName === "Parameter Comparision"
          ? "daily"
          : chartName === "Specific Yield" &&
            (timevalue === "today" || timevalue === "yesterday")
          ? "daily"
          : range,
      widgetName: `${widgetName}`,
      timePeriod: timevalue,
      status: 1,
    };
    try {
      if (
        chartName === "Daily Generation" ||
        chartName === "Deemed Generation" ||
        chartName === "DG PV Grid Management" ||
        chartName === "Actual vs Expected"
      ) {
        let filteredObjects = equipments?.filter((obj) =>
          ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
        );
        console.log(equipments);
        
        const equipmentIds = filteredObjects?.map((data) => {
          return data.equipmentId;
        });

        obj.equipment_Ids = equipmentIds;
        console.log(equipmentIds);
        
      } else if (chartName === "Energy Performance") {
        obj.equipment_Ids = equipmentIds;
      }
      else if (chartName === "String Current - Heatmap"||chartName === "String Current - Average") {
        obj.equipment_Ids = stringInverterIds;
      } else {
        obj.equipment_Ids = equipmentIds;
        obj.measure_Parameter = equipmentParameter;
      }
      setToBackend(true);
      console.log(obj);
      
      let responsedata = await SubmitDataAnalyst(obj);

      //console.log(responsedata);
      // if (responsedata.status == 201) {
      setToBackend(false);
      setOpenToast(true);
      setOpen(false);
      setWidgetName("");
      setChartName("");
      setVarient("success");
      setDatalogValue([]);
      navigateEncryptedURL("/menu/analytics");
      setTextValue("Your Wizard Saved Successfully");
      // }
    } catch (e) {
      setToBackend(false);
      console.error(e);
    }
  };

  async function createEquipmentMap() {
    let equipmentList = equipment.map((data) => {
      return equipments.find((obj) => obj.displayName === data);
    });
    if (!equipmentList) {
      return {};
    }

    // Create a map based on dataLoggerId
    const dataLoggerMap = equipmentList.reduce((map, item) => {
      if (!map[item.dataLoggerId]) {
        map[item.dataLoggerId] = [];
      }
      map[item.dataLoggerId].push(item);
      return map;
    }, {});

    const resultMap = {};

    // Iterate through the dataLoggerMap and fetch params for each dataLoggerId
    for (const [dataLoggerId, equipments] of Object.entries(dataLoggerMap)) {
      try {
        const params = await fetchParamsDropdownByLogger({
          dataloggerId: [Number(dataLoggerId)],
        });

        // Map each equipment's displayName to its corresponding params
        for (const equipment of equipments) {
          resultMap[equipment.displayName] = params.map((param) => {
            return param.stdParameterName;
          });
        }
      } catch (error) {
        console.error(
          `Error fetching params for dataLoggerId ${dataLoggerId}:`,
          error
        );
      }
    }

    // Update the state with the resulting map
    setEqupParamMapValue(resultMap);
    return resultMap;
  }

  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "40vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );

  return (
    <div className="">
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Card elevation={0} style={{ marginLeft: "2.5%" }}>
          <CardContent
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "16px",
            }}
          >
            <div>
              <Typography
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  lineHeight: "14.52px !important",
                  color: " rgb(113, 118, 111)",
                }}
              >
                Add Widget
              </Typography>
            </div>
            <div style={{ marginRight: "0.5%" }}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  // color="error"
                  startIcon={<CancelIcon />}
                  style={{
                    borderRadius: "25px",
                    textTransform: "capitalize",
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  // color="error"
                  style={{
                    borderRadius: "25px",
                    textTransform: "capitalize",
                  }}
                  startIcon={
                    toBackend ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <SaveIcon />
                    )
                  }
                  onClick={handleOpen}
                  disabled={timevalue === "custom" ? true : false || toBackend}
                >
                  Save
                </Button>
              </Stack>
            </div>
          </CardContent>
        </Card>
      </Box>
      <div
        className="monitoring-points"
        style={{ marginLeft: "2%", marginTop: "1%" }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
              width: "100%",
              height: "20vh",
            },
          }}
        >
          <Paper elevation={0}>
            <div className="">
              <div className="monitor-head">
                <Typography style={monitor_head_text}>
                  Monitoring Points
                </Typography>
              </div>
              <div className="monitor-text" style={{ marginLeft: "2%" }}>
                <Stack spacing={3} direction="row">
                  <div>
                    <Typography>Chart Type </Typography>
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={chartType.map((option) => option.label)}
                      onChange={(data, event) => handleChart(data, event)}
                      sx={{ width: "13vw" }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Chart Type ..." />
                      )}
                      classes={{ option: "autocomplete" }}
                    />
                  </div>
                  <div>
                    <Typography style={typoStyle}>Select Site</Typography>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      value={site}
                      disabled={chartName !== "" ? false : true}
                      options={siteIdName.map((option) => option.siteName)}
                      onChange={(data, event) => handleSite(data, event)}
                      sx={{ width: "12vw" }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select  Site ..." />
                      )}
                      classes={{ option: "autocomplete" }}
                    />
                  </div>
                  <div>
                    {/* <FormControl sx={{ width: 250 }}>
                      <Typography style={typoStyle}>Equipments</Typography>
                      <Select
                        id="demo-multiple-checkbox"
                        multiple
                        disabled={
                          chartName === "Daily Generation" ? true : false
                        }
                        size="small"
                        value={equipment}
                        onChange={handleChange}
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                        renderValue={(selected) => selected.join(",")}
                        placeholder="Select Equipments ..."
                      >
                        <MenuItem value="Select All">
                          <Checkbox
                            checked={equipment.length === equipmentData.length}
                          />
                          <ListItemText primary="Select All" />
                        </MenuItem>
                        {equipmentData.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            className="autocomplete"
                          >
                            <Checkbox checked={equipment.includes(name)} />
                            <ListItemText
                              primary={name}
                              className="autocomplete"
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                    <FormControl sx={{ width: "250px" }}>
                      <Typography style={typoStyle}>Equipment</Typography>
                      <Select
                        id="demo-multiple-checkbox"
                        multiple
                        disabled={
                          chartName === "Daily Generation" ||
                          chartName === "Specific Yield" ||
                          chartName === "Actual vs Expected" ||
                          chartName === "DG PV Grid Management" ||
                          chartName === "Specific Yield - Heatmap" ||
                          chartName === "String Current - Average" ||
                          chartName === "String Current - Heatmap" ||
                          chartName === "Deemed Generation" ||
                          chartName === "Set Point" ||
                          chartName === ""
                        }
                        size="small"
                        value={equipment}
                        onChange={handleChange}
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                        renderValue={(selected) => selected.join(", ")}
                        placeholder="Select Equipment ..."
                        className="custom-select" // Apply the custom CSS class
                      >
                        <MenuItem value="Select All" className="custom-option">
                          <Checkbox
                            checked={equipment.length === equipmentData.length}
                          />
                          <ListItemText primary="Select All" />
                        </MenuItem>
                        {equipmentData.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            className="autocomplete custom-option"
                          >
                            <Checkbox checked={equipment.includes(name)} />
                            <ListItemText
                              primary={name}
                              className="autocomplete custom-option"
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl sx={{ width: 300 }}>
                      <Typography style={typoStyle}>
                        Equipment Measurement Parameter
                      </Typography>
                      <Select
                        id="demo-multiple-checkbox"
                        multiple
                        size="small"
                        disabled={
                          chartName === "Parameter Comparision" ? false : true
                        }
                        value={equipmentParam}
                        onChange={handleChangeParams}
                        input={<OutlinedInput />}
                        renderValue={(selected) => selected.join(",")}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="Select All">
                          <Checkbox
                            checked={equipment.length === equipmentData.length}
                          />
                          <ListItemText primary="Select All" />
                        </MenuItem>
                        {equipmentParameters.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            className="autocomplete"
                          >
                            <Checkbox
                              checked={equipmentParam.indexOf(name) > -1}
                            />
                            <ListItemText
                              primary={name}
                              className="autocomplete"
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div
                    className="apply-changes"
                    style={{
                      marginTop: "1%",
                      marginRight: "0.5%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        textTransform: "capitalize",
                        borderRadius: "25px",
                        marginTop: "4%",
                        fontSize: "1.1rem",
                      }}
                      startIcon={<DownloadDoneOutlinedIcon />}
                      onClick={() => {
                        handleApplyChanges();
                      }}
                      disabled={
                        (chartName === "Energy Performance" &&
                          site !== "" &&
                          equipment.length > 0) ||
                        (chartName === "Parameter Comparision" &&
                          site !== "" &&
                          equipment.length > 0 &&
                          equipmentParam.length > 0) ||
                        (chartName === "Parameter Comparision" &&
                          site !== "" &&
                          equipment.length > 0 &&
                          equipmentParam.length > 0) ||
                        ((chartName === "Daily Generation" ||
                          chartName === "Specific Yield" ||
                          chartName === "Specific Yield - Heatmap" ||
                          chartName === "Actual vs Expected" ||
                          chartName === "DG PV Grid Management" ||
                          chartName === "Deemed Generation" ||
                          chartName === "String Current - Average" ||
                          chartName === "String Current - Heatmap" ||
                          chartName === "Set Point") &&
                          site !== "")
                          ? false
                          : true
                      }
                    >
                      Apply changes
                    </Button>
                  </div>
                </Stack>
              </div>
            </div>
          </Paper>
        </Box>
      </div>
      <div className="monitoring-graph" style={{ flexDirection: "row" }}>
        <div style={{ width: "80%" }}>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: "100%",
                height: "55vh",
              },
            }}
          >
            <Paper elevation={0}>
              <div style={{ width: "97%" }}>
                <div>
                  <Typography
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "600",
                      marginTop: "1.5%",
                      marginLeft: "5%",
                    }}
                  >
                    Preview
                  </Typography>
                </div>
                <div>
                  <div className="graph">
                    <Paper elevation={0} style={{ backgroundColor: "white" }}>
                      <div className="allignText">
                        <Card
                          sx={{
                            width: "100%",
                            height: "45vh",
                            overflowY: "auto",
                          }}
                        >
                          {loading ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "15%",
                              }}
                            >
                              <FadeLoader color="#000440" loading={true} />
                            </div>
                          ) : datalog?.length > 0 ? (
                            <CardContent sx={{ width: "100%", height: "5%" }}>
                              {chartName === "Parameter Comparision" ||
                              chartName === "Set Point" ? (
                                <Apexchart
                                  chartName={chartName}
                                  tableData={tableData}
                                  multiChartData={multiChartData}
                                  axisData={axisData}
                                  name={"paramter"}
                                  timeValue={timevalue}
                                  loggerMap={equpParamMap}
                                  fallBack={fallBack}
                                />
                              ) : chartName === "Specific Yield" ||
                                chartName === "Actual vs Expected" ? (
                                <CombinedChart
                                  tableData={tableData}
                                  customView={customView}
                                  chartName={`${chartName}`}
                                  widgetName={`${chartName}`}
                                  fallBack={fallBack}
                                />
                              ) : chartName === "Specific Yield - Heatmap" ? (
                                <HeatmapChart
                                  tableData={tableData}
                                  customView={customView}
                                  WidgetName={`${chartName}`}
                                  chartName={`${chartName}`}
                                  fallBack={fallBack}
                                />
                              ) : chartName === "String Current - Heatmap" ? (
                                <HeatmapChart
                                  tableData={tableData}
                                  customView={customView}
                                  WidgetName={`${site}-${chartName}`}
                                  chartName={`${chartName}`}
                                  fallBack={fallBack}
                                  from={"Vanalytics"}
                                />
                              ) : chartName === "String Current - Average" ? (
                                <HeatmapChart
                                  tableData={tableData}
                                  customView={customView}
                                  WidgetName={`${site}-${chartName}`}
                                  chartName={`${chartName}`}
                                  fallBack={fallBack}
                                  from={"Vanalytics"}
                                />
                              ) : chartName === "DG PV Grid Management" &&
                                chartFromDate === chartToDate ? (
                                <AreaChart
                                  tableData={tableData}
                                  WidgetName={`${chartName}`}
                                  chartName={`${chartName}`}
                                  fallBack={fallBack}
                                />
                              ) : chartName === "Deemed Generation" &&
                                chartFromDate !== chartToDate ? (
                                <BarChart
                                  tableData={tableData}
                                  chartName={chartName}
                                  customView={customView}
                                  barchartdata={barchartdata}
                                  widgetName={`${chartName}-${site}`}
                                  fallBack={fallBack}
                                />
                              ) : chartFromDate === chartToDate ||
                                chartName === "Deemed Generation" ? (
                                <Apexchart
                                  chartName={chartName}
                                  tableData={tableData}
                                  multiChartData={multiChartData}
                                  axisData={axisData}
                                  key="1"
                                  name="chartData"
                                  widgetName={`${chartName}-${site}`}
                                  fallBack={fallBack}
                                />
                              ) : chartName !== "Deemed Generation" ? (
                                <BarChart
                                  tableData={tableData}
                                  chartName={chartName}
                                  barchartdata={barchartdata}
                                  customView={customView}
                                  WidgetName={`${chartName}`}
                                  fallBack={fallBack}
                                />
                              ) : (
                                <CardContent
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "50vh",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "1.5rem",
                                      textTransform: "capitalize",
                                      lineHeight: "14.52px !important",
                                      color: " rgb(113, 118, 111)",
                                    }}
                                  >
                                    No data found in the database
                                  </Typography>
                                </CardContent>
                              )}
                            </CardContent>
                          ) : (
                            <CardContent
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              <Typography style={{ fontSize: "1.5rem" }}>
                                Graph will Preview here
                              </Typography>
                            </CardContent>
                          )}
                        </Card>
                      </div>
                    </Paper>
                  </div>
                </div>
              </div>
            </Paper>
          </Box>
        </div>
        <div style={{ width: "20%" }}>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: "100%",
                height: "55vh",
              },
            }}
          >
            <Paper elevation={0}>
              <div>
                <div style={{}}>
                  <Typography
                    style={{
                      textAlign: "center",
                      fontSize: "1.2rem",
                      fontWeight: "600",
                      marginTop: "5%",
                    }}
                  >
                    Control Panel
                  </Typography>
                </div>
                <Divider style={{ marginTop: "2%", fontWeight: "bold" }} />
                <div
                  style={{
                    marginLeft: "7%",
                    marginRight: "5%",
                    marginTop: "5%",
                  }}
                >
                  <div style={filterAlign}>
                    <Typography
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        lineHeight: "14.52px !important",
                        color: " rgb(113, 118, 111)",
                      }}
                    >
                      Custom range{" "}
                    </Typography>
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      defaultValue="Today"
                      options={optionTime}
                      onChange={(data, value) => handleTimeChange(data, value)}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select ..." />
                      )}
                      classes={{ option: "autocomplete" }}
                    />
                  </div>
                  <div style={filterAlign}>
                    <Typography
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        lineHeight: "14.52px !important",
                        color: " rgb(113, 118, 111)",
                      }}
                    >
                      Date range{" "}
                    </Typography>
                    <Stack direction="row" spacing={1.5}>
                      <div>
                        <Typography
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                            lineHeight: "14.52px !important",
                            color: " rgb(113, 118, 111)",
                          }}
                        >
                          {" "}
                          From
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            placeholder="From"
                            disabled={timevalue !== "custom" ? true : false}
                            onChange={(data, value) =>
                              handleChangeFromDate(data, value)
                            }
                          />
                        </LocalizationProvider>
                      </div>
                      <div>
                        <Typography
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                            lineHeight: "14.52px !important",
                            color: " rgb(113, 118, 111)",
                          }}
                        >
                          {" "}
                          To
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            placeholder="To"
                            disabled={timevalue !== "custom" ? true : false}
                            disableFuture
                            shouldDisableDate={(date) =>
                              dayjs(date).isBefore(dayjs(fromDate), "day")
                            }
                            onChange={(data, value) =>
                              handleChangeToDate(data, value)
                            }
                          />
                        </LocalizationProvider>
                      </div>
                    </Stack>
                  </div>
                </div>
              </div>
            </Paper>
          </Box>
        </div>
      </div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      lineHeight: "14.52px !important",
                      color: " rgb(113, 118, 111)",
                    }}
                  >
                    WidgetName :
                  </Typography>
                  <TextField
                    id="standard-basic"
                    placeholder=" Enter your widgetName"
                    variant="standard"
                    onChange={(e) => {
                      setWidgetName(e.target.value);
                    }}
                    required
                    style={{ textTransform: "capitalize" }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      lineHeight: "14.52px !important",
                      color: " rgb(113, 118, 111)",
                    }}
                  >
                    Chart Type :
                  </Typography>
                  <Typography>{chartName} </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      lineHeight: "14.52px !important",
                      color: " rgb(113, 118, 111)",
                    }}
                  >
                    Custom range :
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      lineHeight: "14.52px !important",
                      color: " rgb(113, 118, 111)",
                    }}
                  >
                    {timevalue}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                marginTop: "3%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                onClick={handleSubmit}
                style={{ textTransform: "capitalize" }}
                disabled={widgetName !== "" ? false : true}
              >
                Submit
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
      <div>
        <div>
          <Snackbar
            open={openToast}
            autoHideDuration={3000}
            onClose={handleCloseToast}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseToast}
              severity={variantText}
              sx={{ width: "100%" }}
            >
              {textValue}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </div>
  );
};
export default AddAnalyst;
