import { useMemo } from "react";

const MONTHS_MAP = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
};

const normalizeTimestampForSorting = (timestamp) => {
  return MONTHS_MAP[timestamp] || timestamp; // Use numeric value for sorting
};

const useGenerateSeries = (data, params, siteList = []) => {
  return useMemo(() => {
    if (!data || !Array.isArray(data) || !params || params.length === 0) {
      return [];
    }

    // Group data by siteId
    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.siteId]) {
        acc[item.siteId] = {};
        params.forEach(({ key }) => {
          acc[item.siteId][key] = [];
        });
      }

      params.forEach(({ key }) => {
        // Ensure the key exists in the data item
        if (key in item) {
          acc[item.siteId][key].push([item.timestamp, item[key]]);
        }
      });

      return acc;
    }, {});

    // Build series dynamically
    const series = [];
    for (const siteId in groupedData) {
      const siteName =
        siteList.find((site) => site.siteId === Number(siteId))?.siteName ||
        `Site ${siteId}`;

      params.forEach(({ key, displayName, type, axis }, index) => {
        // Only include the series if data exists for the parameter
        if (groupedData[siteId][key].length > 0) {
          // Sort the data by normalized timestamp for chronological order
          const sortedData = groupedData[siteId][key].sort(
            ([timestampA], [timestampB]) =>
              normalizeTimestampForSorting(timestampA) -
              normalizeTimestampForSorting(timestampB)
          );

          series.push({
            name: `${siteName} - ${displayName}`,
            type: type, // Alternate type for variety
            data: sortedData, // Keep original month names in the output
            yAxis: axis !== undefined ? axis : index, // Use user-defined yAxis or fallback to index
          });
        }
      });
    }

    return series;
  }, [data, params, siteList]);
};

export default useGenerateSeries;
